















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ItemPane, Pane } from '../part';
import type * as Index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    deleteButtonText: internal.localized(
        'Delete this item',
        'Удалить этот элемент (каз)', // TODO translate
        'Удалить этот элемент',
    ),
};


@Component({
    components: {
        ItemPane,
        Pane,
    },
})
export default class CustomValue extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.CustomValue;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    public translates = translates;

    public get head(): string {
        const selector = this.value.selector;
        if (selector === null) {
            return 'Special value selection (internal error - selector lost)';
        } else {
            return selector.title;
        }
    }

    public get slotContent(): Index.CustomValueSlot {
        const self = this;
        return {
            type: 'custom-value',
            key: this.value.selectorKey,
            readonly: this.readonly,
            value: this.value.value,
            setValue: (value) => {
                self.setValue(value);
            },
        }
    }

    public setValue(value: unknown) {
        this.value.value = value ?? null;
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
}



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Child, ChildrenArray, ComboBox, ItemPane } from '../part';
import type * as Index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    checkedValue: {
        label: internal.localized(
            'Value being checked',
            'Проверяемое значение (каз)', // TODO translate
            'Проверяемое значение',
        ),
        newItemLabel: internal.localized(
            'Click to set value being checked',
            'Кликните для добавления проверяемого значения (каз)', // TODO translate
            'Кликните для добавления проверяемого значения',
        ),
    },
    deleteButtonText: internal.localized(
        'Delete this check',
        'Удалить эту проверку (каз)', // TODO translate
        'Удалить эту проверку',
    ),
    head: {
        negative: internal.localized(
            'Checking if a value not in list of value options',
            'Проверка отсутствия значения в списке вариантов значений (каз)', // TODO translate
            'Проверка отсутствия значения в списке вариантов значений',
        ),
        positive: internal.localized(
            'Checking if a value is in list of value options',
            'Проверка наличия значения в списке вариантов значений (каз)', // TODO translate
            'Проверка наличия значения в списке вариантов значений',
        ),
    },
    typeOfCheck: {
        label: internal.localized(
            'Type of check',
            'Тип проверки (каз)', // TODO translate
            'Тип проверки',
        ),
        items: {
            negative: internal.localized(
                'Value NOT in list',
                'Значения НЕТ в списке (каз)', // TODO translate
                'Значения НЕТ в списке',
            ),
            positive: internal.localized(
                'Value IS in list',
                'Значение ЕСТЬ в списке (каз)', // TODO translate
                'Значение ЕСТЬ в списке',
            ),
        },
    },
    valueOptions: {
        label: internal.localized(
            'Value options',
            'Варианты значений (каз)', // TODO translate
            'Варианты значений',
        ),
        newItemLabel: internal.localized(
            'Click to add value option here',
            'Кликните для добавления варианта значения здесь (каз)', // TODO translate
            'Кликните для добавления варианта значения здесь',
        ),
    },
};


@Component({
    components: {
        Child,
        ChildrenArray,
        ComboBox,
        ItemPane,
    },
})
export default class In extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.In;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public translates = translates;

    public get head(): string {
        if (this.value.negative) {
            return translates.head.negative.toString();
        } else {
            return translates.head.positive.toString();
        }
    }

    public get typeOfCheckText(): string {
        if (this.value.negative) {
            return translates.typeOfCheck.items.negative.toString();
        } else {
            return translates.typeOfCheck.items.positive.toString();
        }
    }

    public get childrenDataTypes(): Array<string> {
        const targetDataType = this.value.target?.dataType ?? null;
        if (typeof targetDataType === 'string') {
            return [targetDataType];
        }

        const valueVariants = this.value.valueVariants;
        if (valueVariants.length > 0) {
            return [valueVariants[0].dataType];
        }

        return [];
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

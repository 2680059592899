




































import { Component, Model, Prop } from "vue-property-decorator";
import { ComponentBase } from '../components';
import { BudgetVariants, Org } from '../types';
import SingleBudgetVariantSelection from './SingleBudgetVariantSelection.vue';


const modelChangeEvent = 'change';


@Component({
    components: {
        SingleBudgetVariantSelection,
    },
})
export default class SingleBudgetVariantField extends ComponentBase {
    constructor() {
        super('modules.budget.staffing_table.budget_variants.*SingleBudgetVariantField*');
    }


    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: BudgetVariants | null;

    @Prop({
        type: Number,
        required: false,
        default: () => null,
    })
    public year!: number;

    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;

    @Prop({
        type: String,
        required: false,
        default: 'auto',
    })
    public fieldWidth!: string;
    // endregion


    // region Lifecycle
    protected created() {
        super.created();

        // region Модель, свойства
        this.$watch('value', (value: BudgetVariants | null) => {
            if (this.selectedBudgetVariant?.id !== value?.id) {
                this.selectedBudgetVariant = value;
            }
        });
        // endregion

        // region Выбранный вариант бюджета
        this.$watch('selectedBudgetVariant', (selectedBudgetVariant: BudgetVariants | null) => {
            if (this.value?.id !== selectedBudgetVariant?.id) {
                this.$emit(modelChangeEvent, selectedBudgetVariant);
            }
        });
        // endregion
    }

    protected mounted() {
        super.mounted();

        if (this.selectedBudgetVariant?.id !== this.value?.id) {
            this.selectedBudgetVariant = this.value;
        }
    }
    // endregion


    // region Модальное окно
    public modalVisible = false;

    public modalValue: BudgetVariants | null = null;

    public showModal() {
        this.modalValue = this.selectedBudgetVariant;
        this.modalVisible = true;
    }

    public onModalOk() {
        this.modalVisible = false;
        this.selectedBudgetVariant = this.modalValue;
    }

    public onModalCancel() {
        this.modalVisible = false;
    }
    // endregion


    // region Выбранный вариант бюджета
    public selectedBudgetVariant: BudgetVariants | null = null;

    public get selectedBudgetVariantText(): string | null {
        const selectedBudgetVariant = this.selectedBudgetVariant;
        if (selectedBudgetVariant === null) {
            return null;
        }

        const id = selectedBudgetVariant.id;
        const uuid = selectedBudgetVariant.variantUuid;
        const name = this.localize(
            () => (selectedBudgetVariant.nameEn || ''),
            () => (selectedBudgetVariant.nameKk || ''),
            () => (selectedBudgetVariant.nameRu),
        );
        return `${name} [#${id}, ${uuid}]`;
    }
    // endregion
}
















































































import {BaseTemplate} from "@/modules/budget/staffing-table/reports/templates/base-template";
import {Component} from "vue-property-decorator";
import {Overlay} from "@/modules/budget/staffing-table/components";
import {SingleBudgetProgramField} from "@/modules/budget/staffing-table/budget-programs";
import {SingleBudgetVariantField} from "@/modules/budget/staffing-table/budget-variants";
import {SingleOrgField} from "@/modules/budget/staffing-table/organizations";
import {SingleVersionField} from "@/modules/budget/staffing-table/versions";
import {BudgetVariants, Dict, Org, Version} from "@/modules/budget/staffing-table/types";
import {store} from "@/modules/budget/staffing-table/common";
import {Expression, prepareForBackend} from "@/components/custom/bool-ex";
import i18n from "@/services/i18n";
import MultiSpecificityFormField from "@/modules/budget/staffing-table/ebk-ek/MultiSpecificityFormField.vue";
import MultiBudgetProgramSelectionTemp from "@/modules/budget/staffing-table/budget-programs/MultiBudgetProgramSelectionTemp.vue";

const LocalTranslates = {
    templates: {
        specificityTemplate: {
            get title(): string {
                return i18n.choose(
                 'Данный шаблон предназначен для отображения отчетов по выбранным спецификам в рамках выбранных бюджетных программ и версии бюджета для учреждения',
                 'Бұл шаблон мекеме үшін таңдалған бюджет бағдарламалары мен бюджет нұсқасы шеңберіндегі таңдалған ерекшеліктер бойынша есептерді көрсетуге арналған',
                 'This template is designed to display reports on selected specifics within the framework of selected budget programs and the budget version for the institution'
                );
            },
        },
    },
};

// region Ключ шаблона
const templateKey  = 'specificity-reports'


@Component({
    components: {
        Overlay,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
        MultiBudgetProgramSelectionTemp,
        MultiSpecificityFormField
    },
})
export default class SpecificityTemplate extends BaseTemplate {

    // region Утилиты
    public translates = LocalTranslates;

    private created() {
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            this.resetStaffTabVersion()
            this.updateBudgetVariant(org)
            this.emitNullExpressionToParent()
        });

        this.$watch('reportYear', () => {
            store.reportYear = this.reportYear;
            this.updateBudgetVariant(this.org)
        });

        this.$watch('budgetVariant', () => {
            store.budgetVariant = this.budgetVariant
            this.emitNullExpressionToParent()
        })

        this.$watch('version', () => {
            store.version = this.version
        })

        this.$watch('multiSelectedBudgetProgram', () => {
            store.multiSelectedBudgetProgram = this.multiSelectedBudgetProgram
        })

        this.$watch('selectedSpecificOptions', () => {
            store.selectedSpecificOptions = this.selectedSpecificOptions
        })
    }

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = store.org;

    // region Версия Шр
    public version: Version | null = store.version;

    public reportYear = store.reportYear;

    public budgetVariant: BudgetVariants | null = store.budgetVariant;

    public get loading(): boolean {
        return this.variantLoading
    }

    public variantLoading = false;

    // region Выбранная бюджетная программа
    public multiSelectedBudgetProgram = store.multiSelectedBudgetProgram
    public selectedSpecificOptions = store.selectedSpecificOptions

    public handleUpdateSelectedSpecific(options: Dict.EbkEk[]) {
        this.selectedSpecificOptions = options
    }

    get expressionItem(): Expression<boolean> {
        return {
            type: 'boolean-group',
            dataType: 'boolean',
            operation: 'AND',
            items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.getBudgetVariantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                        title: 'Budget Variant UUID'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: this.getStaffTabVersionId
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'version-id',
                        title: 'staffTab version'
                    }
                },
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'budg-prog-code',
                        title: 'Budget Subprogram Code'
                    },
                    valueVariants: this.createBudgetProgVariants()
                } as Expression<boolean>,
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'specificity-code',
                        title: 'form code'
                    },
                    valueVariants: this.createSpecificityVariants()
                } as Expression<boolean>,
            ]
        };
    }


    private get getBudgetVariantUuid(): string | null {
        return this.budgetVariant?.variantUuid ?? null
    }

    private get getStaffTabVersionId(): number | null {
        return this.version?.id ?? null
    }

    private createBudgetProgVariants(): { type: string, dataType: string, value: number | null } [] {
        return this.multiSelectedBudgetProgram.map(value => ({
            type: 'parameter',
            dataType: 'number',
            value: value.prg
        }));
    }

    private createSpecificityVariants(): { type: string, dataType: string, value: number | null } [] {
        return this.selectedSpecificOptions.map(specific => ({
            type: 'parameter',
            dataType: 'number',
            value: specific.spf
        }));
    }

    public validateNulls(): boolean {
        const uuid = this.budgetVariant?.variantUuid ?? null
        const isEmptyStaffTab = this.version
        const isEmptyBudgetProg = this.multiSelectedBudgetProgram.isEmpty
        const isEmptySpecificOptions = this.selectedSpecificOptions.isEmpty

        return uuid == null || isEmptyStaffTab == null || isEmptyBudgetProg || isEmptySpecificOptions
    }

    private resetBudgetVariant() {
        this.budgetVariant = null
    }

    private resetStaffTabVersion() {
        this.version = null
    }

    public resetParentTemplateKey() {
        this.$emit('reset-selected-templateKey');
    }

    emitNullExpressionToParent() {
        this.$emit('expression-to-parent', null, templateKey);
    }

    emitExpressionToParent() {
        const item = this.expressionItem
        prepareForBackend(item)
        this.$emit('expression-to-parent', item, templateKey);
    }

    async updateBudgetVariant(org: Org | null) {
        this.variantLoading = true
        this.budgetVariant = org ? await this.setActualVariant(org, this.reportYear) : null;
        this.variantLoading = false
    }

    get abpCode(): number | null {
        const guCode = this.gu?.code ?? null;

        if (guCode === null) {
            return null;
        }
        if (guCode.length < 3) {
            return null;
        }

        const abpCodeString = guCode.substring(0, 3);
        const abpCode = parseInt(abpCodeString);

        if (String(abpCode) !== abpCodeString) {
            console.error(`GU code "${guCode}" contains invalid ABP code "${abpCodeString}"`);
            return null;
        }
        return abpCode;
    }

    public get gu(): Dict.Gu | null {
        const org = this.org;
        if (org === null) {
            return null;
        }

        const type = org.type;
        switch (org.type) {
            case 'KGKP':
                return org.gu;
            case 'GU':
                return org.gu;
            default:
                console.error(`Unexpected org type \"${type}\"`);
                return null;
        }
    }

}

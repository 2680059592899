



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Datepicker from 'vue2-datepicker';
import { ComboBox, ItemPane, Pane } from '../part';
import { NumberInput, SvgIcon } from '../../index';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    dataType: {
        label: internal.localized(
            'Data type',
            'Тип данных (каз)', // TODO translate
            'Тип данных',
        ),
    },
    deleteButtonText: internal.localized(
        'Delete this simple value',
        'Удалить это простое значение (каз)', // TODO translate
        'Удалить это простое значение',
    ),
    head: (config: Index.Config | null | undefined, dataType: string): string => {
        return internal.localize(
            () => (`Simple value [data type "${index.getDataTypeName(config, dataType)}"]`),
            () => (`Простое значение [тип данных "${index.getDataTypeName(config, dataType)}"] (каз)`), // TODO translate
            () => (`Простое значение [тип данных "${index.getDataTypeName(config, dataType)}"]`),
        );
    },
    value: {
        empty: internal.localized(
            '[ Empty ]',
            '[ Пусто ] (каз)', // TODO translate
            '[ Пусто ]',
        ),
        label: internal.localized(
            'Value',
            'Значение (каз)', // TODO translate
            'Значение',
        ),
        types: {
            boolean: {
                items: {
                    false: internal.localized(
                        'No',
                        'Нет (каз)', // TODO translate
                        'Нет',
                    ),
                    true: internal.localized(
                        'Yes',
                        'Да (каз)', // TODO translate
                        'Да',
                    ),
                },
            },
        },
    },
};

/**
 * @example `0000-00-00`
 */
const dateToString = (date: Date): string => {
    let result: string;
    let string: string;

    string = date.getFullYear().toString();
    switch (string.length) {
        case 1:
            result = '000' + string;
            break;
        case 2:
            result = '00' + string;
            break;
        case 3:
            result = '0' + string;
            break;
        default:
            result = string;
            break;
    }
    result = string;

    result += '-';

    string = (date.getMonth() + 1).toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    result += '-';

    string = date.getDate().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    return result;
};

/**
 * @example `00:00:00`
 */
const timeToString = (date: Date): string => {
    let result: string;
    let string: string;

    string = date.getHours().toString();
    if (string.length === 2) {
        result = string;
    } else {
        result = '0' + string;
    }

    result += ':';

    string = date.getMinutes().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    result += ':';

    string = date.getSeconds().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    return result;
};

/**
 * @example `0000-00-00 00:00:00`
 */
const dateTimeToString = (date: Date): string => {
    return dateToString(date) + ' ' + timeToString(date);
};


@Component({
    components: {
        ComboBox,
        ItemPane,
        Datepicker,
        NumberInput,
        Pane,
        SvgIcon,
    },
})
export default class Parameter extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.Parameter;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;

    @Prop({
        type: Array,
        required: true,
    })
    public dataTypes!: Array<string>;
    // endregion


    // region Утилиты
    public index = index;
    public internal = internal;
    public translates = translates;

    public get dataTypeText(): string {
        return index.getDataTypeName(this.config, this.value.dataType);
    }

    public get inputFieldPlaceholder(): string {
        return translates.value.empty.toString();
    }
    // endregion


    // region Значение
    public get datePickerType(): 'date' | 'datetime' | 'time' {
        switch (this.value.dataType) {
            case index.dataTypes.time:
                return 'time';
            case index.dataTypes['date-time']:
                return 'datetime';
            default:
                return 'date';
        }
    }

    public get dateTimeValueText(): string {
        const value = this.value.value;
        if (!(value instanceof Date)) {
            return '';
        }

        switch (this.datePickerType) {
            case 'date':
                return dateToString(value);
            case 'time':
                return timeToString(value);
            case 'datetime':
                return dateTimeToString(value);
        }
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }

    public onStringValueChange(newValue: unknown): void {
        if (typeof newValue === 'string') {
            this.value.value = newValue;
        } else {
            this.value.value = '';
        }
    }
    // endregion
}

import Vue from 'vue';
import i18n from '@/services/i18n';
import type * as Index from './index';
import * as index from './index';


export const icons = {
    'alert-decagram': 'M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M13,17H11V15H13V17M13,13H11V7H13V13Z',
    'check-circle': 'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z',
    'circle': 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
    'delete': 'M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z',
    'export': 'M23,12L19,8V11H10V13H19V16M1,18V6C1,4.89 1.9,4 3,4H15A2,2 0 0,1 17,6V9H15V6H3V18H15V15H17V18A2,2 0 0,1 15,20H3A2,2 0 0,1 1,18Z',
    'import': 'M14,12L10,8V11H2V13H10V16M20,18V6C20,4.89 19.1,4 18,4H6A2,2 0 0,0 4,6V9H6V6H18V18H6V15H4V18A2,2 0 0,0 6,20H18A2,2 0 0,0 20,18Z',
};


// region Localized (I18n utils)
export type Locale = 'en' | 'kk' | 'ru';

const localeSource = Vue.observable({
    get locale(): Locale {
        const locale = i18n.locale.trim().toLowerCase();
        switch (locale) {
            case 'en':
            case 'kk':
                return locale;
            default:
                return 'ru';
        }
    },
});


export const localize = (
    en: (() => string) | string,
    kk: (() => string) | string,
    ru: (() => string) | string,
): string => {
    let chosen: (() => string) | string;
    switch (localeSource.locale) {
        case 'en':
            chosen = en;
            break;
        case 'kk':
            chosen = kk;
            break;
        default:
            chosen = ru;
            break;
    }

    if (typeof chosen === 'string') {
        return chosen;
    } else {
        return chosen();
    }
};


export interface Localized {
    toString(): string;
}

export const localized = (
    en: (() => string) | string,
    kk: (() => string) | string,
    ru: (() => string) | string,
): Localized => {
    const result = {
        en,
        kk,
        ru,
        toString(): string {
            return localize(this.en, this.kk, this.ru);
        },
    };
    return result as Localized;
};
// endregion


// region Translates
const translates = {
    between: {
        error: {
            diffDataTypeInTargetAndRangeStart(
                config: Index.Config | null | undefined,
                targetDataType: string,
                rangeStartDataType: string,
            ): string {
                return localize(
                    () => (`Value being checked and range start has different data types: "${index.getDataTypeName(config, targetDataType)}" and "${index.getDataTypeName(config, rangeStartDataType)}"`),
                    () => (`У проверяемого значения и начала диапазона разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, rangeStartDataType)}" (каз)`), // TODO translate
                    () => (`У проверяемого значения и начала диапазона разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, rangeStartDataType)}"`),
                );
            },
            diffDataTypeInTargetAndRangeEnd(
                config: Index.Config | null | undefined,
                targetDataType: string,
                rangeEndDataType: string,
            ): string {
                return localize(
                    () => (`Value being checked and range end has different data types: "${index.getDataTypeName(config, targetDataType)}" and "${index.getDataTypeName(config, rangeEndDataType)}"`),
                    () => (`У проверяемого значения и конца диапазона разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, rangeEndDataType)}" (каз)`), // TODO translate
                    () => (`У проверяемого значения и конца диапазона разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, rangeEndDataType)}"`),
                );
            },
            rangeEndHasError: localized(
                'Range end has error',
                'В конце диапазона есть ошибка (каз)', // TODO translate
                'В конце диапазона есть ошибка',
            ),
            rangeEndIsNull: localized(
                'Range end is not set',
                'Конец диапазона не установлен (каз)', // TODO translate
                'Конец диапазона не установлен',
            ),
            rangeStartHasError: localized(
                'Range start has error',
                'В начале диапазона есть ошибка (каз)', // TODO translate
                'В начале диапазона есть ошибка',
            ),
            rangeStartIsNull: localized(
                'Range start is not set',
                'Начало диапазона не установлено (каз)', // TODO translate
                'Начало диапазона не установлено',
            ),
            targetHasError: localized(
                'Value being checked has error',
                'В проверяемом значении есть ошибка (каз)', // TODO translate
                'В проверяемом значении есть ошибка',
            ),
            targetIsNull: localized(
                'Value being checked is not set',
                'Проверяемое значение не установлено (каз)', // TODO translate
                'Проверяемое значение не установлено',
            ),
        },
    },
    booleanGroup: {
        error: {
            itemHasError(i: number): string {
                return localize(
                    () => (`Nested condition at index #${i + 1} has error`),
                    () => (`Во вложенном условии с индексом #${i + 1} есть ошибка (каз)`), // TODO translate
                    () => (`Во вложенном условии с индексом #${i + 1} есть ошибка`),
                );
            },
            itemHasInvalidType(config: Index.Config | null | undefined, i: number, dataType: string): string {
                return localize(
                    () => (`Nested condition at index #${i + 1} must have data type "${index.getDataTypeName(config, index.dataTypes.boolean)}", but its data type is "${index.getDataTypeName(config, dataType)}"`),
                    () => (`У вложенного условия с индексом #${i + 1} должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.boolean)}", но у него тип данных "${index.getDataTypeName(config, dataType)}" (каз)`), // TODO translate
                    () => (`У вложенного условия с индексом #${i + 1} должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.boolean)}", но у него тип данных "${index.getDataTypeName(config, dataType)}"`),
                );
            },
            noItems: localized(
                'No nested conditions',
                'Нет вложенных условий (каз)', // TODO translate
                'Нет вложенных условий',
            ),
        },
    },
    customValue: {
        error: {
            valueIsNull: localized(
                'Value is not set',
                'Значение не установлено (каз)', // TODO translate
                'Значение не установлено',
            ),
        },
    },
    in: {
        error: {
            noValueVariants: localized(
                'No value options',
                'Нет вариантов значений (каз)', // TODO translate
                'Нет вариантов значений',
            ),
            targetHasError: localized(
                'Value being checked has error',
                'В проверяемом значении есть ошибка (каз)', // TODO translate
                'В проверяемом значении есть ошибка',
            ),
            targetIsNull: localized(
                'Value being checked is not set',
                'Проверяемое значение не установлено (каз)', // TODO translate
                'Проверяемое значение не установлено',
            ),
            valueVariantHasError(i: number): string {
                return localize(
                    () => (`Value option at index #${i + 1} has error`),
                    () => (`В варианте значения с индексом #${i + 1} есть ошибка (каз)`), // TODO translate
                    () => (`В варианте значения с индексом #${i + 1} есть ошибка`),
                );
            },
            valueVariantHasInvalidType(config: Index.Config | null | undefined, i: number, targetDataType: string, variantDataType: string): string {
                return localize(
                    () => (`Value being checked and value option at index #${i + 1} has different data types: "${index.getDataTypeName(config, targetDataType)}" and "${index.getDataTypeName(config, variantDataType)}"`),
                    () => (`У проверяемого значения и варианта значения с индексом #${i + 1} разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, variantDataType)}" (каз)`), // TODO translate
                    () => (`У проверяемого значения и варианта значения с индексом #${i + 1} разные типы данных: "${index.getDataTypeName(config, targetDataType)}" и "${index.getDataTypeName(config, variantDataType)}"`),
                );
            },
        },
    },
    isNull: {
        error: {
            itemHasError: localized(
                'Value being checked has error',
                'В проверяемом значении есть ошибка (каз)', // TODO translate
                'В проверяемом значении есть ошибка',
            ),
            noItem: localized(
                'Value being checked is not set',
                'Проверяемое значение не установлено (каз)', // TODO translate
                'Проверяемое значение не установлено',
            ),
        },
    },
    like: {
        error: {
            templateHasError: localized(
                'Value template has error',
                'В шаблоне значения есть ошибка (каз)', // TODO translate
                'В шаблоне значения есть ошибка',
            ),
            templateHasInvalidDataType(config: Index.Config | null | undefined, dataType: string): string {
                return localize(
                    () => (`Value template must have data type "${index.getDataTypeName(config, index.dataTypes.string)}", but its data type is "${index.getDataTypeName(config, dataType)}"`),
                    () => (`У шаблона значения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.string)}", но у него тип данных "${index.getDataTypeName(config, dataType)}" (каз)`), // TODO translate
                    () => (`У шаблона значения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.string)}", но у него тип данных "${index.getDataTypeName(config, dataType)}"`),
                );
            },
            templateIsNull: localized(
                'Value template is not set',
                'Шаблон значения не установлен (каз)', // TODO translate
                'Шаблон значения не установлен',
            ),
            testedHasError: localized(
                'Value being checked has error',
                'В проверяемом значении есть ошибка (каз)', // TODO translate
                'В проверяемом значении есть ошибка',
            ),
            testedHasInvalidDataType(config: Index.Config | null | undefined, dataType: string): string {
                return localize(
                    () => (`Value being checked must have data type "${index.getDataTypeName(config, index.dataTypes.string)}", but its data type is "${index.getDataTypeName(config, dataType)}"`),
                    () => (`У проверяемого значения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.string)}", но у него тип данных "${index.getDataTypeName(config, dataType)}" (каз)`), // TODO translate
                    () => (`У проверяемого значения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.string)}", но у него тип данных "${index.getDataTypeName(config, dataType)}"`),
                );
            },
            testedIsNull: localized(
                'Value being checked is not set',
                'Проверяемое значение не установлено (каз)', // TODO translate
                'Проверяемое значение не установлено',
            ),
        },
    },
    not: {
        error: {
            itemHasError: localized(
                'Nested expression has error',
                'Во вложенном выражении есть ошибка (каз)', // TODO translate
                'Во вложенном выражении есть ошибка',
            ),
            itemHasInvalidDataType(config: Index.Config | null | undefined, dataType: string): string {
                return localize(
                    () => (`Nested expression must have data type "${index.getDataTypeName(config, index.dataTypes.boolean)}", but its data type is "${index.getDataTypeName(config, dataType)}"`),
                    () => (`У вложенного выражения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.boolean)}", но у него тип данных "${index.getDataTypeName(config, dataType)}" (каз)`), // TODO translate
                    () => (`У вложенного выражения должен быть тип данных "${index.getDataTypeName(config, index.dataTypes.boolean)}", но у него тип данных "${index.getDataTypeName(config, dataType)}"`),
                );
            },
            itemIsNull: localized(
                'Nested expression is not set',
                'Вложенное выражение не установлено (каз)', // TODO translate
                'Вложенное выражение не установлено',
            ),
        },
    },
    parameter: {
        error: {
            forbiddenDataType(config: Index.Config | null | undefined, dataType: string): string {
                return localize(
                    () => (`Data type "${index.getDataTypeName(config, dataType)}" is forbidden, please, choose other data type`),
                    () => (`Тип данных "${index.getDataTypeName(config, dataType)}" запрещен, пожалуйста, выберите другой тип данных (каз)`), // TODO translate
                    () => (`Тип данных "${index.getDataTypeName(config, dataType)}" запрещен, пожалуйста, выберите другой тип данных`),
                );
            },
            valueIsNull: localized(
                'Value is not set',
                'Значение не установлено (каз)', // TODO translate
                'Значение не установлено',
            ),
        },
    },
    twoValueComparison: {
        error: {
            diffDataTypes(config: Index.Config | null | undefined, firstDataType: string, secondDataType: string): string {
                return localize(
                    () => (`Nested expressions has different data types: "${index.getDataTypeName(config, firstDataType)}" and "${index.getDataTypeName(config, secondDataType)}"`),
                    () => (`У вложенных выражений разные типы данных: "${index.getDataTypeName(config, firstDataType)}" и "${index.getDataTypeName(config, secondDataType)}" (каз)`), // TODO translate
                    () => (`У вложенных выражений разные типы данных: "${index.getDataTypeName(config, firstDataType)}" и "${index.getDataTypeName(config, secondDataType)}"`),
                );
            },
            firstHasError: localized(
                'First expression has error',
                'В первом выражении есть ошибка (каз)', // TODO translate
                'В первом выражении есть ошибка',
            ),
            firstIsNull: localized(
                'First expression is not set',
                'Первое выражение не установлено (каз)', // TODO translate
                'Первое выражение не установлено',
            ),
            secondHasError: localized(
                'Second expression has error',
                'Во втором выражении есть ошибка (каз)', // TODO translate
                'Во втором выражении есть ошибка',
            ),
            secondIsNull: localized(
                'Second expression is not set',
                'Второе выражение не установлено (каз)', // TODO translate
                'Второе выражение не установлено',
            ),
        },
    },
};
// endregion


// region ID generator
interface IdGenerator extends Record<string, unknown> {
    nextId(): number;
    freeId(id: number): void;
}

const idGenerator: IdGenerator = ((): IdGenerator => {
    const usedIdSet = new Set<number>();
    let lastId = Number.MAX_SAFE_INTEGER;

    const nextIdAfter = (previousId: number): number => {
        if (previousId >= Number.MAX_SAFE_INTEGER) {
            return Number.MIN_SAFE_INTEGER;
        } else {
            return (previousId + 1);
        }
    };

    return {
        nextId(): number {
            let result = nextIdAfter(lastId);
            const startValue = result;

            while (usedIdSet.has(result)) {
                result = nextIdAfter(result);
                if (startValue === result) {
                    throw new Error(`Cannot define free ID, all integer values in range [${Number.MIN_SAFE_INTEGER} - ${Number.MAX_SAFE_INTEGER}] are in use`);
                }
            }

            usedIdSet.add(result);
            lastId = result;

            return result;
        },
        freeId(id: number) {
            usedIdSet.delete(id);
        },
    };
})();
// endregion


// region Date utils
const parseDate = (string: string, prepared?: Date): Date => {
    // 0000-00-00

    const throwError = (): never => {
        throw new Error(`Cannot parse "${string}" as "date" ("YYYY-MM-DD" format required)`);
    };

    const firstDashIndex = string.indexOf('-');
    if (firstDashIndex < 1) { throwError(); }
    if (string.length !== (firstDashIndex + 6)) { throwError(); }

    const yearString = string.substring(0, firstDashIndex);
    const year = parseInt(yearString);
    if ((!Number.isInteger(year)) || (year.toString() !== yearString)) { throwError(); }

    const monthString = string.substring(firstDashIndex + 1, firstDashIndex + 3);
    const month = parseInt(monthString);
    if ((!Number.isInteger(month)) || (month.toString() !== monthString)) { throwError(); }

    const dayString = string.substring(firstDashIndex + 4, firstDashIndex + 6);
    const day = parseInt(dayString);
    if ((!Number.isInteger(day)) || (day.toString() !== dayString)) { throwError(); }

    const result = prepared ?? new Date(2000, 0, 1, 0, 0, 0, 0);
    result.setFullYear(year, month, day);

    const test = index.serializeDate(result);
    if (test !== string) { throwError(); }

    return result;
};

const parseTime = (string: string, prepared?: Date): Date => {
    // 00:00:00.000

    const throwError = (): never => {
        throw new Error(`Cannot parse "${string}" as "time" ("hh:mm:ss.SSS" format required)`);
    };

    if (string.length !== 12) { throwError(); }

    const hourString = string.substring(0, 2);
    const hour = parseInt(hourString);
    if ((!Number.isInteger(hour)) || (hour.toString() !== hourString)) { throwError(); }

    const minuteString = string.substring(3, 5);
    const minute = parseInt(minuteString);
    if ((!Number.isInteger(minute)) || (minute.toString() !== minuteString)) { throwError(); }

    const secondString = string.substring(6, 8);
    const second = parseInt(secondString);
    if ((!Number.isInteger(second)) || (second.toString() !== secondString)) { throwError(); }

    const millisecondString = string.substring(9, 12);
    const millisecond = parseInt(millisecondString);
    if ((!Number.isInteger(millisecond)) || (millisecond.toString() !== millisecondString)) { throwError(); }

    const result = prepared ?? new Date(2000, 0, 1, 0, 0, 0, 0);
    result.setHours(hour, minute, second, millisecond);

    const test = index.serializeDate(result);
    if (test !== string) { throwError(); }

    return result;
};

const parseDateTime = (string: string, prepared?: Date): Date => {
    // 0000-00-00 00:00:00.000

    const throwError = (): never => {
        throw new Error(`Cannot parse "${string}" as "date-time" ("YYYY-MM-DD hh:mm:ss.SSS" format required)`);
    };

    const spaceIndex = string.indexOf(' ');
    if (spaceIndex < 1) { throwError(); }
    if (spaceIndex === (string.length - 1)) { throwError(); }

    const result = prepared ?? new Date(2000, 0, 1, 0, 0, 0, 0);

    // Parsing date
    try {
        const dateString = string.substring(0, spaceIndex);
        parseDate(dateString, result);
    } catch (e) {
        console.error(e);
        throwError();
    }

    // Parsing time
    try {
        const timeString = string.substring(spaceIndex + 1);
        parseTime(timeString, result);
    } catch (e) {
        console.error(e);
        throwError();
    }

    return result;
};
// endregion


export interface ItemError {
    logMessage: string;
    localizedMessage: string;
}


// region Items - base
export interface ItemBase {
    readonly config: Index.Config | null;
    changeConfig(newConfig: Index.Config | null | undefined): void;
    readonly id: number;
    readonly type: string;
    error: ItemError | null;
    createReadyItem(): Index.Item;
    expanded: boolean;
    onDeleted(): void;
    equalsTo(readyItem: Index.Item): boolean;
    collectHashParts(target: Array<unknown>): void;
    serialize(): Serialized;
}

export type Item =
    // Items - boolean expressions
    Between | BooleanGroup | In | IsNull | Like | Not | TwoValueComparison
    |
    // Items - other
    CustomField | CustomValue | Parameter
;

const expressionTypeSet = new Set(['between', 'boolean-group', 'custom-field', 'custom-value', 'in', 'is-null', 'like', 'not', 'parameter', 'two-value-comparison']);


export interface ExpressionBase extends ItemBase {
    dataType: string;
}

export type Expression = ExpressionBase & Item;


interface Serialized extends Record<string, unknown> {
    expanded: boolean;
    type: string;
}
// endregion


// region Items - boolean expressions
interface SerializedBetween extends Serialized {
    type: 'between';
    negative: boolean;
    target: Serialized | null;
    rangeStart: Serialized | null;
    rangeEnd: Serialized | null;
}

export interface Between extends ExpressionBase {
    type: 'between';
    readonly dataType: 'boolean';
    negative: boolean;
    target: Expression | null;
    rangeStart: Expression | null;
    rangeEnd: Expression | null;
}

const createBetween = (
    config: Index.Config | null | undefined,
    negative: boolean,
    target: Expression | null,
    rangeStart: Expression | null,
    rangeEnd: Expression | null,
): Between => {
    interface Extended extends Between {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined): void {
            this._config = newConfig ?? null;
            this.target?.changeConfig(newConfig);
            this.rangeStart?.changeConfig(newConfig);
            this.rangeEnd?.changeConfig(newConfig);
        },

        id: idGenerator.nextId(),
        type: 'between',
        dataType: 'boolean',

        negative: negative ?? false,
        target: target ?? null,
        rangeStart: rangeStart ?? null,
        rangeEnd: rangeEnd ?? null,

        get error(): ItemError | null {
            const target = this.target;
            if (target === null) {
                return {
                    logMessage: 'Target is NULL',
                    get localizedMessage(): string {
                        return translates.between.error.targetIsNull.toString();
                    },
                };
            }
            if (target.error !== null) {
                return {
                    logMessage: 'Target has error',
                    get localizedMessage(): string {
                        return translates.between.error.targetHasError.toString();
                    },
                };
            }

            const dataType = target.dataType;

            const rangeStart = this.rangeStart;
            if (rangeStart === null) {
                return {
                    logMessage: 'Range start is NULL',
                    get localizedMessage(): string {
                        return translates.between.error.rangeStartIsNull.toString();
                    },
                };
            }
            if (rangeStart.error !== null) {
                return {
                    logMessage: 'Range start has error',
                    get localizedMessage(): string {
                        return translates.between.error.rangeStartHasError.toString();
                    },
                };
            }
            if (rangeStart.dataType !== dataType) {
                const config = this.config;
                const rangeStartDataType = rangeStart.dataType;
                return {
                    logMessage: `Target and range start has different data types: "${dataType}" and "${rangeStart.dataType}"`,
                    get localizedMessage(): string {
                        return translates.between.error.diffDataTypeInTargetAndRangeStart(config, dataType, rangeStartDataType);
                    },
                };
            }

            const rangeEnd = this.rangeEnd;
            if (rangeEnd === null) {
                return {
                    logMessage: 'Range end is NULL',
                    get localizedMessage(): string {
                        return translates.between.error.rangeEndIsNull.toString();
                    },
                };
            }
            if (rangeEnd.error !== null) {
                return {
                    logMessage: 'Range end has errors',
                    get localizedMessage(): string {
                        return translates.between.error.rangeEndHasError.toString();
                    },
                };
            }
            if (rangeEnd.dataType !== dataType) {
                const config = this.config;
                const rangeEndDataType = rangeEnd.dataType;

                return {
                    logMessage: `Target and range start has different data types: "${dataType}" and "${rangeEnd.dataType}"`,
                    get localizedMessage(): string {
                        return translates.between.error.diffDataTypeInTargetAndRangeEnd(config, dataType, rangeEndDataType);
                    },
                };
            }

            return null;
        },

        createReadyItem(): Index.Between<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'between',
                dataType: 'boolean',
                negative: this.negative,
                target: (this.target!!).createReadyItem() as Index.Expression<unknown>,
                rangeStart: (this.rangeStart!!).createReadyItem() as Index.Expression<unknown>,
                rangeEnd: (this.rangeEnd!!).createReadyItem() as Index.Expression<unknown>,
            };
        },

        expanded: false,

        onDeleted(): void {
            idGenerator.freeId(this.id);
            this.target?.onDeleted();
            this.rangeStart?.onDeleted();
            this.rangeEnd?.onDeleted();
        },

        equalsTo(readyItem: Index.Item): boolean {
            return (
                (this.target !== null)
                &&
                (this.rangeStart !== null)
                &&
                (this.rangeEnd !== null)
                &&
                (readyItem.type === 'between')
                &&
                (this.negative === readyItem.negative)
                &&
                (this.target.equalsTo(readyItem.target))
                &&
                (this.rangeStart.equalsTo(readyItem.rangeStart))
                &&
                (this.rangeEnd.equalsTo(readyItem.rangeEnd))
            );
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.type, this.negative);
            this.target?.collectHashParts(target);
            this.rangeStart?.collectHashParts(target);
            this.rangeEnd?.collectHashParts(target);
        },

        serialize(): SerializedBetween {
            return {
                expanded: this.expanded,
                type: 'between',
                negative: this.negative,
                target: this.target?.serialize() ?? null,
                rangeStart: this.rangeStart?.serialize() ?? null,
                rangeEnd: this.rangeEnd?.serialize() ?? null,
            };
        },
    });
};

export const createBetweenFromReady = (config: Index.Config | null | undefined, readyItem?: Index.Between<unknown>): Between => {
    let target: Expression | null;
    let rangeStart: Expression | null;
    let rangeEnd: Expression | null;
    if (readyItem === undefined) {
        target = null;
        rangeStart = null;
        rangeEnd = null;
    } else {
        target = createInternalFromReady(config, readyItem.target);
        rangeStart = createInternalFromReady(config, readyItem.rangeStart);
        rangeEnd = createInternalFromReady(config, readyItem.rangeEnd);
    }

    return createBetween(config, readyItem?.negative ?? false, target, rangeStart, rangeEnd);
};

const deserializeBetween = (deserializer: Deserializer, serialized: Record<string, unknown>): Between => {
    try {
        deserializer.addTypeToPath('Between');

        const negative = deserializer.getBooleanFromObject(serialized, 'negative');
        const target = deserializer.getExpressionFromObject(serialized, 'target');
        const rangeStart = deserializer.getExpressionFromObject(serialized, 'rangeStart');
        const rangeEnd = deserializer.getExpressionFromObject(serialized, 'rangeEnd');

        const result = createBetween(deserializer.config, negative ?? false, target, rangeStart, rangeEnd);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


export namespace BooleanGroup {
    export type Operation = Index.BooleanGroup.Operation;
}

interface SerializedBooleanGroup extends Serialized {
    type: 'boolean-group';
    operation: BooleanGroup.Operation;
    items: Array<Serialized | null>;
}

export interface BooleanGroup extends ExpressionBase {
    readonly type: 'boolean-group';
    readonly dataType: 'boolean';
    operation: BooleanGroup.Operation;
    items: Array<Expression>;
    createReadyItem(): Index.BooleanGroup;
}

const createBooleanGroup = (
    config: Index.Config | null | undefined,
    operation: Index.BooleanGroup.Operation,
    items: Array<Expression>,
): BooleanGroup => {
    interface Extended extends BooleanGroup {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined) {
            this._config = newConfig ?? null;
            this.items.forEach((item) => {
                item?.changeConfig(newConfig);
            });
        },

        id: idGenerator.nextId(),
        type: 'boolean-group',
        dataType: 'boolean',
        operation: operation ?? 'AND',

        items: items ?? [],

        get error(): ItemError | null {
            const items = this.items;
            if (items.length === 0) {
                return {
                    logMessage: 'No items',
                    get localizedMessage(): string {
                        return translates.booleanGroup.error.noItems.toString();
                    },
                };
            }

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.error !== null) {
                    return {
                        logMessage: `Found item with errors at index #${i}`,
                        get localizedMessage(): string {
                            return translates.booleanGroup.error.itemHasError(i);
                        },
                    };
                } else if (index.dataTypes.boolean !== item.dataType) {
                    const config = this.config;

                    return {
                        logMessage: `Found item with unexpected data type "${item.dataType}" at index #${i}, data type must be "${index.dataTypes.boolean}"`,
                        get localizedMessage(): string {
                            return translates.booleanGroup.error.itemHasInvalidType(config, i, (item!!).dataType);
                        },
                    };
                }
            }

            return null;
        },

        expanded: false,

        onDeleted() {
            idGenerator.freeId(this.id);
            this.items.forEach((item) => {
                item?.onDeleted();
            });
        },

        createReadyItem(): Index.BooleanGroup {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'boolean-group',
                dataType: index.dataTypes.boolean,
                operation: this.operation,
                items: this.items.map((internalItem) => {
                    return (internalItem as Expression)
                        .createReadyItem() as Index.Expression<boolean>;
                }),
            };
        },

        equalsTo(readyItem: Index.Item): boolean {
            if (readyItem.type !== 'boolean-group') {
                return false;
            }
            if (readyItem.operation !== this.operation) {
                return false;
            }

            const readyItems = readyItem.items;
            if (readyItems.length !== this.items.length) {
                return false;
            }
            for (let i = 0; i < readyItems.length; i++) {
                const readyItem = readyItems[i];
                const item = this.items[i];
                if (item === null) {
                    return false;
                }
                if (!item.equalsTo(readyItem)) {
                    return false;
                }
            }

            return true;
        },

        collectHashParts(target: Array<unknown>) {
            target.push(this.config, this.type, this.operation);
            this.items.forEach((item) => {
                if (item === null) {
                    target.push(null);
                } else {
                    item?.collectHashParts(target);
                }
            });
        },

        serialize(): SerializedBooleanGroup {
            return {
                expanded: this.expanded,
                type: 'boolean-group',
                operation: this.operation,
                items: this.items.map((item) => (item?.serialize() ?? null)),
            };
        },
    });
};

export const createBooleanGroupFromReady = (config: Index.Config | null | undefined, readyItem?: Index.BooleanGroup): BooleanGroup => {
    const items = readyItem?.items?.map((readyItem) => {
        return createInternalFromReady<Expression>(config, readyItem);
    });

    return createBooleanGroup(config, readyItem?.operation ?? 'AND', items ?? []);
};

const deserializeBooleanGroup = (deserializer: Deserializer, serialized: Record<string, unknown>): BooleanGroup => {
    deserializer.addTypeToPath('BooleanGroup');
    try {
        const operation = deserializer.getEnumStringFromObject<Index.BooleanGroup.Operation>(serialized, 'operation', 'AND', 'OR');
        const items = deserializer.getExpressionArrayFromObject(serialized, 'items');

        const result = createBooleanGroup(deserializer.config, operation ?? 'AND', items ?? []);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
}


interface SerializedIn extends Serialized {
    type: 'in';
    negative: boolean;
    target: Serialized | null;
    valueVariants: Array<Serialized>;
}

export interface In extends ExpressionBase {
    type: 'in';
    readonly dataType: 'boolean';
    negative: boolean;
    target: Expression | null;
    valueVariants: Array<Expression>;
}

const createIn = (
    config: Index.Config | null | undefined,
    negative: boolean,
    target: Expression | null,
    valueVariants: Array<Expression>,
): In => {
    interface Extended extends In {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined): void {
            this._config = newConfig ?? null;
            this.target?.changeConfig(newConfig);
            this.valueVariants.forEach((valueVariant) => {
                valueVariant.changeConfig(newConfig);
            });
        },

        id: idGenerator.nextId(),
        type: 'in',
        dataType: 'boolean',

        negative,
        target,
        valueVariants,

        get error(): ItemError | null {
            const target = this.target;
            if (target === null) {
                return {
                    logMessage: 'Target is NULL',
                    get localizedMessage(): string {
                        return translates.in.error.targetIsNull.toString();
                    },
                };
            }
            if (target.error !== null) {
                return {
                    logMessage: 'Target has errors',
                    get localizedMessage(): string {
                        return translates.in.error.targetHasError.toString();
                    },
                };
            }

            const valueVariants = this.valueVariants;
            if (valueVariants.length === 0) {
                return {
                    logMessage: 'Value variant list is empty',
                    get localizedMessage(): string {
                        return translates.in.error.noValueVariants.toString();
                    },
                };
            }

            for (let i = 0; i < valueVariants.length; i++) {
                const valueVariant = valueVariants[i];
                if (valueVariant.dataType !== target.dataType) {
                    const config = this.config;

                    return {
                        logMessage: `Target and value variant at index #${i} has different data types: "${target.dataType}" and "${valueVariant.dataType}"`,
                        get localizedMessage(): string {
                            return translates.in.error.valueVariantHasInvalidType(config, i, (target!!).dataType, valueVariant.dataType);
                        },
                    };
                }
                if (valueVariant.error !== null) {
                    return {
                        logMessage: 'Value variant list is empty',
                        get localizedMessage(): string {
                            return translates.in.error.valueVariantHasError(i);
                        },
                    };
                }
            }

            return null;
        },

        createReadyItem(): Index.In<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'in',
                dataType: 'boolean',
                negative: this.negative,
                target: (this.target!!).createReadyItem() as Index.Expression<unknown>,
                valueVariants: this.valueVariants.map((valueVariant) => (valueVariant.createReadyItem() as Index.Expression<unknown>)),
            };
        },

        expanded: false,

        onDeleted(): void {
            idGenerator.freeId(this.id);
            this.target?.onDeleted();
            this.valueVariants.forEach((valueVariant) => {
                valueVariant.onDeleted();
            });
        },

        equalsTo(readyItem: Index.Item): boolean {
            if (this.target === null) {
                return false;
            }

            if (
                (readyItem.type !== 'in')
                ||
                (this.negative !== readyItem.negative)
                ||
                (!(this.target!!).equalsTo(readyItem.target))
            ) {
                return false;
            }

            if (this.valueVariants.length !== readyItem.valueVariants.length) {
                return false;
            }
            for (let i = 0; i < this.valueVariants.length; i++) {
                if (!this.valueVariants[i].equalsTo(readyItem.valueVariants[i])) {
                    return false;
                }
            }

            return true;
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.type, this.negative);
            this.target?.collectHashParts(target);
            this.valueVariants.forEach((valueVariant) => {
                valueVariant.collectHashParts(target);
            });
        },

        serialize(): SerializedIn {
            return {
                expanded: this.expanded,
                type: 'in',
                negative: this.negative,
                target: this.target?.serialize() ?? null,
                valueVariants: this.valueVariants.map((valueVariant) => (valueVariant.serialize())),
            };
        },
    });
};

export const createInFromReady = (config: Index.Config | null | undefined, readyItem?: Index.In<unknown>): In => {
    let target: Expression | null;
    let valueVariants: Array<Expression>;
    if (readyItem === undefined) {
        target = null;
        valueVariants = [];
    } else {
        target = createInternalFromReady(config, readyItem.target);
        valueVariants = readyItem.valueVariants.map((valueVariant) => {
            return createInternalFromReady(config, valueVariant);
        });
    }

    return createIn(config, readyItem?.negative ?? false, target, valueVariants);
};

const deserializeIn = (deserializer: Deserializer, serialized: Record<string, unknown>): In => {
    deserializer.addTypeToPath('In');
    try {
        const negative = deserializer.getBooleanFromObject(serialized, 'negative');
        const target = deserializer.getExpressionFromObject(serialized, 'target');
        const valueVariants = deserializer.getExpressionArrayFromObject(serialized, 'valueVariants');

        const result = createIn(deserializer.config, negative ?? false, target, valueVariants ?? []);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedIsNull extends Serialized {
    type: 'is-null';
    negative: boolean;
    item: Serialized | null;
}

export interface IsNull extends ExpressionBase {
    readonly type: 'is-null';
    readonly dataType: 'boolean';
    negative: boolean;
    item: Expression | null;
}

const createIsNull = (
    config: Index.Config | null | undefined,
    negative: boolean,
    item: Expression | null,
): IsNull => {
    interface Extended extends IsNull {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined): void {
            this._config = newConfig ?? null;
            this.item?.changeConfig(newConfig);
        },

        id: idGenerator.nextId(),
        type: 'is-null',
        dataType: 'boolean',

        negative,
        item,

        get error(): ItemError | null {
            const item = this.item;
            if (item === null) {
                return {
                    logMessage: 'Item is NULL',
                    get localizedMessage(): string {
                        return translates.isNull.error.noItem.toString();
                    },
                };
            }
            if (item.error !== null) {
                return {
                    logMessage: 'Item has error',
                    get localizedMessage(): string {
                        return translates.isNull.error.itemHasError.toString();
                    },
                };
            }

            return null;
        },

        createReadyItem(): Index.IsNull {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'is-null',
                dataType: 'boolean',
                negative: this.negative,
                item: (this.item!!).createReadyItem(),
            };
        },

        expanded: false,

        onDeleted(): void {
            idGenerator.freeId(this.id);
            this.item?.onDeleted();
        },

        equalsTo(readyItem: Index.Item): boolean {
            const item = this.item;
            if (item === null) {
                return false;
            }

            return (
                (readyItem.type === 'is-null')
                &&
                (readyItem.negative === this.negative)
                &&
                item.equalsTo(readyItem.item)
            );
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.type, this.negative);
            this.item?.collectHashParts(target);
        },

        serialize(): SerializedIsNull {
            return {
                expanded: this.expanded,
                type: 'is-null',
                negative: this.negative,
                item: this.item?.serialize() ?? null,
            };
        },
    });
};

export const createIsNullFromReady = (config: Index.Config | null | undefined, readyItem?: Index.IsNull): IsNull => {
    let item: Expression | null;
    if (readyItem === undefined) {
        item = null;
    } else {
        item = createInternalFromReady(config, readyItem.item);
    }

    return createIsNull(config, readyItem?.negative ?? false, item);
};

const deserializeIsNull = (deserializer: Deserializer, serialized: Record<string, unknown>): IsNull => {
    deserializer.addTypeToPath('IsNull');
    try {
        const negative = deserializer.getBooleanFromObject(serialized, 'negative');
        const item = deserializer.getExpressionFromObject(serialized, 'item');

        const result = createIsNull(deserializer.config, negative ?? false, item);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedLike extends Serialized {
    type: 'like';
    negative: boolean;
    tested: Serialized | null;
    template: Serialized | null;
}

export interface Like extends ExpressionBase {
    readonly type: 'like';
    readonly dataType: 'boolean';
    negative: boolean;
    tested: Expression | null;
    template: Expression | null;
}

const createLike = (
    config: Index.Config | null | undefined,
    negative: boolean,
    tested: Expression | null,
    template: Expression | null,
): Like => {
    interface Extended extends Like {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined): void {
            this._config = newConfig ?? null;
            this.tested?.changeConfig(newConfig);
            this.template?.changeConfig(newConfig);
        },

        id: idGenerator.nextId(),
        type: 'like',
        dataType: 'boolean',

        negative,
        tested,
        template,

        get error(): ItemError | null {
            const tested = this.tested;
            if (tested === null) {
                return {
                    logMessage: 'Tested item is NULL',
                    get localizedMessage(): string {
                        return translates.like.error.testedIsNull.toString();
                    },
                };
            }
            if (tested.error !== null) {
                return {
                    logMessage: 'Tested item has error',
                    get localizedMessage(): string {
                        return translates.like.error.testedHasError.toString();
                    },
                };
            }
            if (tested.dataType !== index.dataTypes.string) {
                const config = this.config;

                return {
                    logMessage: `Tested item has invalid data type "${tested.dataType}"`,
                    get localizedMessage(): string {
                        return translates.like.error.testedHasInvalidDataType(config, (tested!!).dataType);
                    },
                };
            }


            const template = this.template;
            if (template === null) {
                return {
                    logMessage: 'Template item is NULL',
                    get localizedMessage(): string {
                        return translates.like.error.templateIsNull.toString();
                    },
                };
            }
            if (template.error !== null) {
                return {
                    logMessage: 'Template item has error',
                    get localizedMessage(): string {
                        return translates.like.error.templateHasError.toString();
                    },
                };
            }
            if (template.dataType !== index.dataTypes.string) {
                const config = this.config;

                return {
                    logMessage: `Template item has invalid data type "${template.dataType}"`,
                    get localizedMessage(): string {
                        return translates.like.error.templateHasInvalidDataType(config, (template!!).dataType);
                    },
                };
            }


            return null;
        },

        createReadyItem(): Index.Like {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'like',
                dataType: 'boolean',
                negative: this.negative,
                tested: (this.tested!!).createReadyItem() as Index.Expression<string>,
                template: (this.template!!).createReadyItem() as Index.Expression<string>,
            };
        },

        expanded: false,

        onDeleted(): void {
            idGenerator.freeId(this.id);
            this.tested?.onDeleted();
            this.template?.onDeleted();
        },

        equalsTo(readyItem: Index.Item): boolean {
            const tested = this.tested;
            if (tested === null) {
                return false;
            }

            const template = this.template;
            if (template === null) {
                return false;
            }

            return (
                (readyItem.type === 'like')
                &&
                (readyItem.negative === this.negative)
                &&
                (tested.equalsTo(readyItem.tested))
                &&
                (template.equalsTo(readyItem.template))
            );
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.type, this.negative);
            this.tested?.collectHashParts(target);
            this.template?.collectHashParts(target);
        },

        serialize(): SerializedLike {
            return {
                expanded: this.expanded,
                type: 'like',
                negative: this.negative,
                tested: this.tested?.serialize() ?? null,
                template: this.template?.serialize() ?? null,
            };
        },
    });
};

export const createLikeFromReady = (config: Index.Config | null | undefined, readyItem?: Index.Like): Like => {
    let tested: Expression | null;
    let template: Expression | null;
    if (readyItem === undefined) {
        tested = null;
        template = null;
    } else {
        tested = createInternalFromReady(config, readyItem.tested);
        template = createInternalFromReady(config, readyItem.template);
    }

    return createLike(config, readyItem?.negative ?? false, tested, template);
};

const deserializeLike = (deserializer: Deserializer, serialized: Record<string, unknown>): Like => {
    deserializer.addTypeToPath('Like');
    try {
        const negative = deserializer.getBooleanFromObject(serialized, 'negative');
        const tested = deserializer.getExpressionFromObject(serialized, 'tested');
        const template = deserializer.getExpressionFromObject(serialized, 'template');

        const result = createLike(deserializer.config, negative ?? false, tested, template);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedNot extends Serialized {
    type: 'not';
    item: Serialized | null;
}

export interface Not extends ExpressionBase {
    readonly type: 'not';
    readonly dataType: 'boolean';
    item: Expression | null;
}

const createNot = (
    config: Index.Config | null | undefined,
    item: Expression | null,
): Not => {
    interface Extended extends Not {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined): void {
            this._config = newConfig ?? null;
            this.item?.changeConfig(newConfig);
        },

        id: idGenerator.nextId(),
        type: 'not',
        dataType: 'boolean',

        item,

        get error(): ItemError | null {
            const item = this.item;
            if (item === null) {
                return {
                    logMessage: 'Nested item is NULL',
                    get localizedMessage(): string {
                        return translates.not.error.itemIsNull.toString();
                    },
                };
            }
            if (item.error !== null) {
                return {
                    logMessage: 'Nested item has errors',
                    get localizedMessage(): string {
                        return translates.not.error.itemHasError.toString();
                    },
                };
            }
            if (item.dataType !== index.dataTypes.boolean) {
                const config = this.config;

                return {
                    logMessage: `Nested item must have data type "${index.dataTypes.boolean}", but its data type is "${item.dataType}"`,
                    get localizedMessage(): string {
                        return translates.not.error.itemHasInvalidDataType(config, (item!!).dataType);
                    },
                };
            }

            return null;
        },

        createReadyItem(): Index.Not {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'not',
                dataType: 'boolean',
                item: ((this.item!!).createReadyItem() as Index.Expression<boolean>),
            };
        },

        expanded: false,

        onDeleted(): void {
            idGenerator.freeId(this.id);
            this.item?.onDeleted();
        },

        equalsTo(readyItem: Index.Item): boolean {
            const item = this.item;
            if (item === null) {
                return false;
            }

            return (
                (readyItem.type === 'not')
                &&
                item.equalsTo(readyItem.item)
            );
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.type, this.item);
        },

        serialize(): SerializedNot {
            return {
                expanded: this.expanded,
                type: 'not',
                item: this.item?.serialize() ?? null,
            };
        },
    });
};

export const createNotFromReady = (config: Index.Config | null | undefined, readyItem?: Index.Not): Not => {
    const readyChildItem = readyItem?.item ?? null;
    let childItem: Expression | null;
    if (readyChildItem === null) {
        childItem = null;
    } else {
        childItem = createInternalFromReady(config, readyChildItem);
    }

    return createNot(config, childItem);
};

const deserializeNot = (deserializer: Deserializer, serialized: Record<string, unknown>): Not => {
    deserializer.addTypeToPath('Not');
    try {
        const item = deserializer.getExpressionFromObject(serialized, 'item');

        const result = createNot(deserializer.config, item);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedTwoValueComparison extends Serialized {
    type: 'two-value-comparison';
    first: Serialized | null;
    operation: Index.TwoValueComparison.Operation;
    second: Serialized | null;
}

export interface TwoValueComparison extends ExpressionBase {
    readonly type: 'two-value-comparison';
    dataType: 'boolean';
    first: Expression | null;
    operation: Index.TwoValueComparison.Operation;
    second: Expression | null;
}

const createTwoValueComparison = (
    config: Index.Config | null | undefined,
    first: Expression | null,
    operation: Index.TwoValueComparison.Operation,
    second: Expression | null,
): TwoValueComparison => {
    interface Extended extends TwoValueComparison {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined) {
            this._config = newConfig ?? null;
            this.first?.changeConfig(newConfig);
            this.second?.changeConfig(newConfig);
        },

        id: idGenerator.nextId(),
        type: 'two-value-comparison',
        dataType: 'boolean',

        first,
        operation,
        second,

        get error(): ItemError | null {
            const first = this.first;
            if (first === null) {
                return {
                    logMessage: 'First expression is NULL',
                    get localizedMessage(): string {
                        return translates.twoValueComparison.error.firstIsNull.toString();
                    },
                };
            }
            if (first.error) {
                return {
                    logMessage: 'First expression has error',
                    get localizedMessage(): string {
                        return translates.twoValueComparison.error.firstHasError.toString();
                    },
                };
            }

            const second = this.second;
            if (second === null) {
                return {
                    logMessage: 'Second expression is NULL',
                    get localizedMessage(): string {
                        return translates.twoValueComparison.error.secondIsNull.toString();
                    },
                };
            }
            if (second.error) {
                return {
                    logMessage: 'Second expression has error',
                    get localizedMessage(): string {
                        return translates.twoValueComparison.error.secondHasError.toString();
                    },
                };
            }

            if (first.dataType !== second.dataType) {
                const config = this.config;

                return {
                    logMessage: `Nested expressions has different data types: "${first.dataType}" and "${second.dataType}"`,
                    get localizedMessage(): string {
                        return translates.twoValueComparison.error.diffDataTypes(config, (first!!).dataType, (second!!).dataType);
                    },
                };
            }

            return null;
        },

        createReadyItem(): Index.TwoValueComparison<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            const first = this.first;
            if (first === null) {
                throw new Error('First expression is null');
            }

            const second = this.second;
            if (second === null) {
                throw new Error('Second expression is null');
            }

            return {
                type: 'two-value-comparison',
                dataType: 'boolean',
                first: first.createReadyItem() as Index.Expression<unknown>,
                operation: this.operation,
                second: second.createReadyItem() as Index.Expression<unknown>,
            };
        },

        expanded: false,

        onDeleted() {
            idGenerator.freeId(this.id);
            this.first?.onDeleted();
            this.second?.onDeleted();
        },

        equalsTo(readyItem: Index.Item): boolean {
            const first = this.first;
            if (first === null) {
                return false;
            }

            const second = this.second;
            if (second === null) {
                return false;
            }

            return (
                (readyItem.type === 'two-value-comparison')
                &&
                (readyItem.operation === this.operation)
                &&
                first.equalsTo(readyItem.first)
                &&
                second.equalsTo(readyItem.second)
            );
        },

        collectHashParts(target: Array<unknown>) {
            target.push(this.config, this.type, this.operation);
            this.first?.collectHashParts(target);
            this.second?.collectHashParts(target);
        },

        serialize(): SerializedTwoValueComparison {
            return {
                expanded: this.expanded,
                type: 'two-value-comparison',
                first: this.first?.serialize() ?? null,
                operation: this.operation,
                second: this.second?.serialize() ?? null,
            };
        },
    });
};

export const createTwoValueComparisonFromReady = (config: Index.Config | null | undefined, readyItem?: Index.TwoValueComparison<unknown>): TwoValueComparison => {
    let first: Expression | null;
    let second: Expression | null;
    if (readyItem === undefined) {
        first = null;
        second = null;
    } else {
        first = createInternalFromReady(config, readyItem.first);
        second = createInternalFromReady(config, readyItem.second);
    }

    return createTwoValueComparison(config, first, readyItem?.operation ?? 'equals', second);
};

const deserializeTwoValueComparison = (deserializer: Deserializer, serialized: Record<string, unknown>): TwoValueComparison => {
    deserializer.addTypeToPath('TwoValueComparison');
    try {
        const first = deserializer.getExpressionFromObject(serialized, 'first');
        const operation = deserializer.getEnumStringFromObject<Index.TwoValueComparison.Operation>(
            serialized,
            'operation',
            'equals',
            'not-equals',
            'greater-than',
            'greater-than-or-equals',
            'lesser-than',
            'lesser-than-or-equals',
        );
        const second = deserializer.getExpressionFromObject(serialized, 'second');

        const result = createTwoValueComparison(deserializer.config, first, operation ?? 'equals', second);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};
// endregion


// region Items - other
interface SerializedCustomField extends Serialized {
    type: 'custom-field';
    key: string;
}

export interface CustomField extends ExpressionBase {
    readonly type: 'custom-field';
    readonly key: string;
    readonly readyItem: Index.CustomField<unknown> | null;
}

const createCustomField = (
    config: Index.Config | null | undefined,
    key: string,
): CustomField => {
    interface Extended extends CustomField {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined) {
            this._config = newConfig ?? null;
        },

        id: idGenerator.nextId(),
        type: 'custom-field',
        key,

        get readyItem(): Index.CustomField<unknown> | null {
            const config = this.config;
            if (config !== null) {
                const customFields = config.customFields;
                if ((customFields !== undefined) && (customFields !== null)) {
                    for (const customField of customFields) {
                        if (customField.key === this.key) {
                            return customField;
                        }
                    }
                }
            }

            return null;
        },

        get dataType(): string {
            return this.readyItem?.dataType ?? index.dataTypes.unknown;
        },

        get error(): ItemError | null {
            const config = this.config;
            if (config === null) {
                return {
                    logMessage: 'Config is null',
                    localizedMessage: 'Internal error (configuration lost)',
                };
            }

            const customFields = config.customFields;
            if ((customFields === undefined) || (customFields === null)) {
                return {
                    logMessage: 'Config has null or undefined "customFields"',
                    localizedMessage: 'Internal error (custom field list lost)',
                };
            }

            let itemFound = false;
            for (const customField of customFields) {
                if (customField.key === this.key) {
                    itemFound = true;
                }
            }

            if (!itemFound) {
                return {
                    logMessage: `Cannot find custom field with key "${this.key}"`,
                    localizedMessage: `Internal error (custom field with key "${this.key}" lost)`,
                };
            }

            return null;
        },

        createReadyItem(): Index.CustomField<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            const result = this.readyItem;
            if (result === null) {
                throw new Error(`Cannot return "ready" custom field - it cannot be found in config`);
            }
            return result;
        },

        expanded: false,

        onDeleted() {
            idGenerator.freeId(this.id);
        },

        equalsTo(readyItem: Index.Item): boolean {
            return (readyItem === this.readyItem);
        },

        collectHashParts(target: Array<unknown>) {
            target.push(this.config, this.type, this.readyItem);
        },

        serialize(): SerializedCustomField {
            return {
                expanded: this.expanded,
                type: 'custom-field',
                key: this.key,
            };
        },
    });
};

export const createCustomFieldFromReady = (config: Index.Config | null | undefined, readyItem?: Index.CustomField<unknown>, key?: string): CustomField => {
    let usedKey: string;
    if (readyItem !== undefined) {
        usedKey = readyItem.key;
    } else if (key !== undefined) {
        usedKey = key;
    } else {
        throw new Error('Both parameters "readyItem" and "key" are undefined');
    }

    return createCustomField(config, usedKey);
};

const deserializeCustomField = (deserializer: Deserializer, serialized: Record<string, unknown>): CustomField | null => {
    deserializer.addTypeToPath('CustomField');
    try {
        const key = deserializer.getStringFromObject(serialized, 'key');
        if (key === null) {
            return null;
        }

        const result = createCustomField(deserializer.config, key);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedCustomValue extends Serialized {
    type: 'custom-value';
    selectorKey: string;
    value: unknown;
}

export interface CustomValue extends ExpressionBase {
    readonly type: 'custom-value';
    readonly selectorKey: string;
    selector: Index.CustomValueSelector | null;
    value: unknown;
}

const createCustomValue = (
    config: Index.Config | null | undefined,
    selectorKey: string,
    value: unknown,
): CustomValue => {
    interface Extended extends CustomValue {
        _config: Index.Config | null;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined) {
            this._config = newConfig ?? null;
        },

        id: idGenerator.nextId(),
        type: 'custom-value',

        selectorKey,
        get selector(): Index.CustomValueSelector | null {
            const config = this.config;
            if (config === null) {
                return null;
            }

            const customValueSelectors = config.customValueSelectors;
            if ((customValueSelectors === undefined) || (customValueSelectors === null)) {
                return null;
            }

            for (const result of customValueSelectors) {
                if (result.key === this.selectorKey) {
                    return result;
                }
            }

            return null;
        },
        get dataType(): string {
            const selector = this.selector;
            if (selector === null) {
                return index.dataTypes.unknown;
            } else {
                return selector.dataType;
            }
        },

        value,

        get error(): ItemError | null {
            const config = this.config;
            if (config === null) {
                return {
                    logMessage: 'Config is NULL',
                    localizedMessage: 'Internal error (config is not set)',
                };
            }

            const customValueSelectors = config.customValueSelectors;
            if ((customValueSelectors === undefined) || (customValueSelectors === null) || (customValueSelectors.length === 0)) {
                return {
                    logMessage: 'Config has no custom value selectors (property "customValueSelectors")',
                    localizedMessage: 'Internal error (no custom value selectors in config)',
                };
            }

            let selector: Index.CustomValueSelector | null = null;
            for (const loopSelector of customValueSelectors) {
                if (loopSelector.key === this.selectorKey) {
                    selector = loopSelector;
                    break;
                }
            }

            if (selector === null) {
                const selectorKey = this.selectorKey;

                return {
                    logMessage: `Config has no custom value selector with key "${selectorKey}"`,
                    localizedMessage: `Internal error (no custom value selector with key "${selectorKey}" in config)`,
                };
            }

            if (this.value === null) {
                return {
                    logMessage: 'Value is NULL',
                    get localizedMessage(): string {
                        return translates.customValue.error.valueIsNull.toString();
                    },
                };
            }

            return null;
        },

        createReadyItem(): Index.CustomValue<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'custom-value',
                selectorKey: this.selectorKey,
                dataType: (this.selector!!).dataType,
                value: this.value,
            };
        },

        expanded: false,

        onDeleted() {
            idGenerator.freeId(this.id);
        },

        equalsTo(readyItem: Index.Item): boolean {
            return (
                (readyItem.type === 'custom-value')
                &&
                (readyItem.selectorKey === this.selectorKey)
                &&
                (readyItem.value === this.value)
            );
        },

        collectHashParts(target: Array<unknown>): void {
            target.push(this.config, this.selectorKey, this.value);
        },

        serialize(): SerializedCustomValue {
            return {
                expanded: this.expanded,
                type: 'custom-value',
                selectorKey: this.selectorKey,
                value: this.selector?.serializeValue(this.value) ?? null,
            };
        },
    });
};

export const createCustomValueFromReady = (config: Index.Config | null | undefined, readyItem?: Index.CustomValue<unknown>, selectorKey?: string): CustomValue => {
    let usedSelectorKey: string;
    if (readyItem !== undefined) {
        usedSelectorKey = readyItem.selectorKey;
    } else if (selectorKey !== undefined) {
        usedSelectorKey = selectorKey;
    } else {
        throw new Error('Both parameters "readyItem" and "selectorKey" are undefined');
    }

    return createCustomValue(config, usedSelectorKey, readyItem?.value ?? null);
};

const deserializeCustomValue = (deserializer: Deserializer, serialized: Record<string, unknown>): CustomValue | null => {
    deserializer.addTypeToPath('CustomValue');
    try {
        const selectorKey = deserializer.getStringFromObject(serialized, 'selectorKey');
        if (selectorKey === null) {
            return null;
        }

        const customValueSelector = ((): Index.CustomValueSelector | null => {
            const customValueSelectors = deserializer.config?.customValueSelectors;
            if (Array.isArray(customValueSelectors)) {
                for (const customValueSelector of customValueSelectors) {
                    if (customValueSelector.key === selectorKey) {
                        return customValueSelector;
                    }
                }
            }
            return null;
        })();
        if (customValueSelector === null) {
            return null;
        }

        const value = customValueSelector.deserializeValue(deserializer.getUnknownFromObject(serialized, 'value'));

        const result = createCustomValue(deserializer.config, selectorKey, value);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};


interface SerializedParameter extends Serialized {
    type: 'parameter';
    dataType: string;
    value: unknown;
}

export interface Parameter extends ExpressionBase {
    readonly type: 'parameter';
    value: unknown;
    createReadyItem(): Index.Parameter<unknown>;
}

const createParameter = (
    config: Index.Config | null | undefined,
    dataType: string,
    value: unknown,
): Parameter => {
    interface Extended extends Parameter {
        _config: Index.Config | null;
        _dataType: string;
    }

    return Vue.observable<Extended>({
        _config: config ?? null,
        get config(): Index.Config | null {
            return this._config;
        },
        changeConfig(newConfig: Index.Config | null | undefined) {
            this._config = newConfig ?? null;
        },

        id: idGenerator.nextId(),
        type: 'parameter',
        _dataType: dataType,

        get dataType(): string {
            return this._dataType;
        },
        set dataType(value) {
            if (this._dataType !== value) {
                this._dataType = value;

                switch (value) {
                    case index.dataTypes.boolean:
                        this.value = true;
                        break;
                    case index.dataTypes.number:
                        this.value = 0;
                        break;
                    case index.dataTypes.string:
                        this.value = '';
                        break;
                    default:
                        this.value = null;
                        break;
                }
            }
        },

        value,

        get error(): ItemError | null {
            if (this.dataType === index.dataTypes.unknown) {
                const config = this.config;
                return {
                    logMessage: `Parameter has data type "${index.dataTypes.unknown}"`,
                    get localizedMessage(): string {
                        return translates.parameter.error.forbiddenDataType(config, index.dataTypes.unknown);
                    },
                };
            }
            if (this.value === null) {
                return {
                    logMessage: 'Parameter has NULL value',
                    get localizedMessage(): string {
                        return translates.parameter.error.valueIsNull.toString();
                    },
                };
            }
            return null;
        },

        expanded: false,

        onDeleted() {
            idGenerator.freeId(this.id);
        },

        createReadyItem(): Index.Parameter<unknown> {
            const error = this.error;
            if (error !== null) {
                throw new Error(error.logMessage);
            }

            return {
                type: 'parameter',
                dataType: this.dataType,
                value: this.value,
            };
        },

        equalsTo(readyItem: Index.Item): boolean {
            return (
                (readyItem.type === 'parameter')
                &&
                (readyItem.dataType === this.dataType)
                &&
                (readyItem.value === this.value)
            );
        },

        collectHashParts(target: Array<unknown>) {
            target.push(this.config, this.type, this.dataType);

            const value = this.value;
            const valueType = (typeof value);
            switch (valueType) {
                case 'object':
                    if (value === null) {
                        target.push('null');
                    } else {
                        target.push('object', (value as Object).constructor.name, String(value));
                    }
                    break;
                case 'string':
                    target.push('string', value);
                    break;
                default:
                    target.push(valueType, String(value));
                    break;
            }
        },

        serialize(): SerializedParameter {
            return {
                expanded: this.expanded,
                type: 'parameter',
                dataType: this.dataType,
                value: index.serializeSimpleValue(this.dataType, this.value),
            };
        },
    });
};

export const createParameterFromReady = (config: Index.Config | null | undefined, readyItem?: Index.Parameter<unknown>): Parameter => {
    return createParameter(config, readyItem?.dataType ?? index.dataTypes.unknown, readyItem?.value ?? null);
};

const deserializeParameter = (deserializer: Deserializer, serialized: Record<string, unknown>): Parameter | null => {
    deserializer.addTypeToPath('Parameter');
    try {
        const dataType = deserializer.getEnumStringFromObject<keyof Index.DataTypes>(
            serialized,
            'dataType',
            'unknown',
            'boolean',
            'number',
            'string',
            'date',
            'time',
            'date-time',
        );

        let value: unknown;
        switch (dataType) {
            case 'boolean':
                value = deserializer.getBooleanFromObject(serialized, 'value') ?? false;
                break;
            case 'number':
                value = deserializer.getNumberFromObject(serialized, 'value') ?? 0;
                break;
            case 'string':
                value = deserializer.getStringFromObject(serialized, 'value');
                break;
            case 'date':
                value = deserializer.getStringFromObject(serialized, 'value');
                if (value !== null) {
                    try {
                        value = parseDate(value as string);
                    } catch (e) {
                        console.error(e);
                        deserializer.logError(serialized, `Cannot parse "${value}" as "date"`);
                    }
                }
                break;
            case 'time':
                value = deserializer.getStringFromObject(serialized, 'value');
                if (value !== null) {
                    try {
                        value = parseTime(value as string);
                    } catch (e) {
                        console.error(e);
                        deserializer.logError(serialized, `Cannot parse "${value}" as "time"`);
                    }
                }
                break;
            case 'date-time':
                value = deserializer.getStringFromObject(serialized, 'value');
                if (value !== null) {
                    try {
                        value = parseDateTime(value as string);
                    } catch (e) {
                        console.error(e);
                        deserializer.logError(serialized, `Cannot parse "${value}" as "date-time"`);
                    }
                }
                break;
            default:
                value = null;
                break;
        }

        const result = createParameter(deserializer.config, dataType ?? index.dataTypes.unknown, value);
        result.expanded = deserializer.getBooleanFromObject(serialized, 'expanded') ?? false;
        return result;
    } finally {
        deserializer.removeLastTypeFromPath();
    }
};
// endregion


export const createInternalFromReady = <T = ItemBase>(config: Index.Config | null | undefined, readyItem: Index.Item): T => {
    const type = readyItem.type;
    switch (readyItem.type) {
        case 'between':
            return createBetweenFromReady(config, readyItem) as unknown as T;
        case 'boolean-group':
            return createBooleanGroupFromReady(config, readyItem) as unknown as T;
        case 'custom-field':
            return createCustomFieldFromReady(config, readyItem as Index.CustomField<unknown>) as unknown as T;
        case 'custom-value':
            return createCustomValueFromReady(config, readyItem as Index.CustomValue<unknown>) as unknown as T;
        case 'in':
            return createInFromReady(config, readyItem) as unknown as T;
        case 'is-null':
            return createIsNullFromReady(config, readyItem) as unknown as T;
        case 'like':
            return createLikeFromReady(config, readyItem) as unknown as T;
        case 'not':
            return createNotFromReady(config, readyItem) as unknown as T;
        case 'parameter':
            return createParameterFromReady(config, readyItem) as unknown as T;
        case 'two-value-comparison':
            return createTwoValueComparisonFromReady(config, readyItem) as unknown as T;
        default:
            throw new Error(`Found unknown expression type: \"${type}\"`);
    }
};


// region Deserialization
export interface DeserializationResult<T> {
    value: T | null;
    wasErrors: boolean;
}

class Deserializer {
    readonly config: Index.Config | null | undefined;
    wasErrors: boolean;

    constructor(config: Index.Config | null | undefined) {
        this.config = config;
        this.wasErrors = false;
    }

    private typePath: Array<string> = [];

    addTypeToPath(type: string) {
        this.typePath.push(type);
    }

    removeLastTypeFromPath() {
        if (this.typePath.length > 0) {
            this.typePath.splice(this.typePath.length - 1, 1);
        }
    }

    logError(failedObject: Record<string, unknown>, message: string) {
        let type: string;
        if (this.typePath.length === 0) {
            type = '<unknown>';
        } else {
            type = this.typePath[this.typePath.length - 1];
        }

        console.error(`Deserializing ${type}.`, message, 'Failed object:', failedObject);

        this.wasErrors = true;
    }


    getBooleanFromObject(source: Record<string, unknown>, propertyName: string): boolean | null {
        const result = source[propertyName];
        if (typeof result === 'boolean') {
            return result;
        } else {
            this.logError(source, `Invalid property "${propertyName}" - boolean required`);
            return null;
        }
    }

    getEnumStringFromObject<T extends string>(source: Record<string, unknown>, propertyName: string, ...allowedValues: Array<T>): T | null {
        const value = this.getStringFromObject(source, propertyName);
        if (value === null) {
            return null;
        }

        for (const allowedValue of allowedValues) {
            if (value === allowedValue) {
                return allowedValue;
            }
        }

        this.logError(source, `Invalid property "${propertyName}" - it contains unknown value "${value}"`);
        return null;
    }

    getExpressionFromObject(source: Record<string, unknown>, propertyName: string): Expression | null {
        const result = source[propertyName];
        if (result === null) {
            return null;
        }
        if (!(result instanceof Object)) {
            this.logError(source, `Invalid property "${propertyName}" - Expression required`);
            return null;
        }

        const typedResult = result as Record<string, unknown>;

        const type = this.getStringFromObject(typedResult, 'type');
        if (type === null) {
            return null;
        }
        if (!expressionTypeSet.has(type)) {
            this.logError(typedResult, `Property "type" has value "${type}" - object is not Expression`);
            return null;
        }

        return _deserialize<Expression>(this, typedResult);
    }

    getExpressionArrayFromObject(source: Record<string, unknown>, propertyName: string): Array<Expression> | null {
        const array = source[propertyName];
        if (!Array.isArray(array)) {
            this.logError(source, `Invalid property "${propertyName}" - Array required`);
            return null;
        }

        const result: Array<Expression> = [];
        array.forEach((item, i) => {
            if (!(item instanceof Object)) {
                this.logError(source, `Property "${propertyName}" has non-Object at index ${i}`);
                return;
            }

            const typedItem = item as Record<string, unknown>;

            const type = this.getStringFromObject(typedItem, 'type');
            if (type === null) {
                return;
            }
            if (!expressionTypeSet.has(type)) {
                this.logError(typedItem, `Property "type" has value "${type}" - object is not Expression`);
                return null;
            }

            const expression = _deserialize<Expression>(this, typedItem);
            if (expression !== null) {
                result.push(expression);
            }
        });
        return result;
    }

    getNumberFromObject(source: Record<string, unknown>, propertyName: string): number | null {
        const result = source[propertyName];
        if (typeof result === 'number') {
            return result;
        } else {
            this.logError(source, `Invalid property "${propertyName}" - number required`);
            return null;
        }
    }

    getStringFromObject(source: Record<string, unknown>, propertyName: string): string | null {
        const result = source[propertyName];
        if (typeof result === 'string') {
            return result;
        } else {
            this.logError(source, `Invalid property "${propertyName}" - string required`);
            return null;
        }
    }

    getUnknownFromObject(source: Record<string, unknown>, propertyName: string): unknown {
        if (Object.getOwnPropertyNames(source).includes(propertyName)) {
            return source[propertyName];
        } else {
            this.logError(source, `No property "${propertyName}"`);
            return null;
        }
    }
}

const _deserialize = <T extends ItemBase>(deserializer: Deserializer, serialized: Record<string, unknown>): T | null => {
    deserializer.addTypeToPath('<unknown>');
    const type = deserializer.getStringFromObject(serialized, 'type');
    deserializer.removeLastTypeFromPath();

    switch (type) {
        case null:
            return null;
        case 'between':
            return deserializeBetween(deserializer, serialized) as unknown as T;
        case 'boolean-group':
            return deserializeBooleanGroup(deserializer, serialized) as unknown as T;
        case 'custom-field':
            return deserializeCustomField(deserializer, serialized) as unknown as (T | null);
        case 'custom-value':
            return deserializeCustomValue(deserializer, serialized) as unknown as (T | null);
        case 'in':
            return deserializeIn(deserializer, serialized) as unknown as T;
        case 'is-null':
            return deserializeIsNull(deserializer, serialized) as unknown as T;
        case 'like':
            return deserializeLike(deserializer, serialized) as unknown as T;
        case 'not':
            return deserializeNot(deserializer, serialized) as unknown as T;
        case 'parameter':
            return deserializeParameter(deserializer, serialized) as unknown as T;
        case 'two-value-comparison':
            return deserializeTwoValueComparison(deserializer, serialized) as unknown as T;
        default:
            deserializer.logError(serialized, `Property "type" has unknown value "${type}"`);
            return null;
    }
};

export const deserialize = <T extends ItemBase>(config: Index.Config | null | undefined, serialized: unknown): DeserializationResult<T> => {
    if (serialized instanceof Object) {
        const deserializer = new Deserializer(config);
        const value = _deserialize<T>(deserializer, serialized as Record<string, unknown>);

        return { wasErrors: deserializer.wasErrors, value };
    } else {
        console.error(`BoolEx deserialization: input value is not Object. Invalid value:`, serialized);
        return { wasErrors: true, value: null };
    }
};
// endregion












import { Component, Prop, Vue } from 'vue-property-decorator';
import ItemPane from '../part/ItemPane.vue';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    deleteButtonText: internal.localized(
        'Delete this data field',
        'Удалить это поле данных (каз)', // TODO translate
        'Удалить это поле данных',
    ),
    head: (dataFieldTitle: string, dataTypeName: string): string => {
        return internal.localize(
            () => (`Data field "${dataFieldTitle}" [data type "${dataTypeName}"]`),
            () => (`Поле данных "${dataFieldTitle}" [тип данных "${dataTypeName}"] (каз)`), // TODO translate
            () => (`Поле данных "${dataFieldTitle}" [тип данных "${dataTypeName}"]`),
        );
    },
};


@Component({
    components: {
        ItemPane,
    },
})
export default class CustomField extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.CustomField;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public translates = translates;

    public get head(): string {
        const readyItem = this.value.readyItem;
        if (readyItem === null) {
            return 'Data field [internal error]';
        }

        const dataFieldTitle = readyItem.title;
        const dataTypeName = index.getDataTypeName(this.config, readyItem.dataType);
        return translates.head(dataFieldTitle, dataTypeName)
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

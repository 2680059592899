




































import { Component, Prop, Vue } from 'vue-property-decorator';
import type * as Index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';
import { Child, ComboBox, ItemPane } from '../part';


const eventNames = {
    delete: 'delete',
};

const translates = {
    checkedValue: {
        label: internal.localized(
            'Value being checked',
            'Проверяемое значение (каз)', // TODO translate
            'Проверяемое значение',
        ),
        newItemLabel: internal.localized(
            'Click to set value being checked',
            'Кликните для выбора проверяемого значения (каз)', // TODO translate
            'Кликните для выбора проверяемого значения',
        ),
    },
    deleteButtonText: internal.localized(
        'Delete this check',
        'Удалить эту проверку (каз)', // TODO translate
        'Удалить эту проверку',
    ),
    head: internal.localized(
        'Checking for presence of a value',
        'Проверка наличия значения (каз)', // TODO translate
        'Проверка наличия значения',
    ),
    typeOfCheck: {
        items: {
            negative: internal.localized(
                'Value is present',
                'Значение есть (каз)', // TODO translate
                'Значение есть',
            ),
            positive: internal.localized(
                'There is no value',
                'Значения нет (каз)', // TODO translate
                'Значения нет',
            ),
        },
        label: internal.localized(
            'Type of check',
            'Тип проверки (каз)', // TODO translate
            'Тип проверки',
        ),
    },
};


@Component({
    components: {
        Child,
        ComboBox,
        ItemPane,
    },
})
export default class IsNull extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.IsNull;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public translates = translates;

    public getTypeOfCheckText(negative: boolean): string {
        if (negative) {
            return translates.typeOfCheck.items.negative.toString();
        } else {
            return translates.typeOfCheck.items.positive.toString();
        }
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

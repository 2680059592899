




















import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import Pane from './Pane.vue';


const modelEventName = 'change';


@Component({
    components: {
        Pane,
    },
})
export default class ComboBox extends Vue {
    // region Модель, свойства
    @Model(modelEventName, {
        required: false,
        default: () => null,
    })
    public value!: unknown;

    @Prop({
        type: String,
        required: true,
    })
    public label!: string;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;

    @Prop({
        type: String,
        required: true,
    })
    public readonlyValueText!: string;
    // endregion


    public onChange(newValue: unknown) {
        if (this.value !== newValue) {
            this.$emit(modelEventName, newValue);
        }
    }
}

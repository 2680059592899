

















































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import type * as Index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';
import ItemRenderer from './ItemRenderer.vue';
import NewItemButton from './NewItemButton.vue';
import Pane from './Pane.vue';


const modelEventName = 'change';


@Component({
    components: {
        NewItemButton,
        Pane,
    },
})
export default class Child extends Vue {
    // region Модель, свойства
    @Model(modelEventName, {
        type: Object,
        required: false,
        default: () => null,
    })
    public value!: Internal.Item | null;

    @Prop({
        type: Object,
        required: true,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Array,
        required: true,
    })
    public dataTypes!: Array<string>;

    @Prop({
        type: String,
        required: true,
    })
    public label!: string;

    @Prop({
        type: String,
        required: true,
    })
    public newItemLabel!: string;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    public ItemRenderer = ItemRenderer;

    public noValueText = internal.localized(
        'No value',
        'Нет значения (каз)', // TODO translate
        'Нет значения',
    );


    public onChanged(newValue: unknown) {
        if (this.value !== newValue) {
            this.$emit(modelEventName, newValue);
        }
    }

    public onDeleted() {
        if (this.value !== null) {
            this.$emit(modelEventName, null);
        }
    }
}

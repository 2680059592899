










































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import type * as Index from '../index';
import type * as Internal from '../internal';
import ItemRenderer from './ItemRenderer.vue';
import NewItemButton from './NewItemButton.vue';
import Pane from './Pane.vue';


const modelEventName = 'change';


@Component({
    components: {
        NewItemButton,
        Pane,
    },
})
export default class ChildrenArray extends Vue {
    // region Модель, свойства
    @Model(modelEventName, {
        type: Array,
        required: true,
    })
    public value!: Array<Internal.Item>;

    @Prop({
        type: Object,
        required: true,
    })
    public config!: Index.Config;

    @Prop({
        type: Array,
        required: true,
    })
    public dataTypes!: Array<string>;

    @Prop({
        type: String,
        required: true,
    })
    public label!: string;

    @Prop({
        type: String,
        required: true,
    })
    public newItemLabel!: string;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    public ItemRenderer = ItemRenderer;


    // region Значение
    private changeValue(change: (newValue: Array<Internal.Item>) => void) {
        const newValue = [...this.value];
        change(newValue);
        this.$emit(modelEventName, newValue);
    }

    public onItemCreatedAtIndex(i: number, item: unknown) {
        this.changeValue((newValue) => {
            newValue.splice(i, 0, (item as Internal.Item));
        });
    }

    public onItemCreatedAtEnd(item: unknown) {
        this.changeValue((newValue) => {
            newValue.push(item as Internal.Item);
        });
    }

    public onItemChangedAtIndex(i: number, item: unknown) {
        this.changeValue((newValue) => {
            newValue.splice(i, 0, (item as Internal.Item));
        });
    }

    public onItemDeletedAtIndex(i: number) {
        this.changeValue((newValue) => {
            newValue.splice(i, 1);
        });
    }
    // endregion
}


















































































import {BaseTemplate} from "@/modules/budget/staffing-table/reports/templates/base-template";
import {Component} from "vue-property-decorator";
import {Overlay} from "@/modules/budget/staffing-table/components";
import {SingleBudgetProgramField} from "@/modules/budget/staffing-table/budget-programs";
import {SingleBudgetVariantField} from "@/modules/budget/staffing-table/budget-variants";
import {SingleOrgField} from "@/modules/budget/staffing-table/organizations";
import {SingleVersionField} from "@/modules/budget/staffing-table/versions";
import {BudgetVariants, Dict, Org, Version} from "@/modules/budget/staffing-table/types";
import {store} from "@/modules/budget/staffing-table/common";
import {Expression, prepareForBackend} from "@/components/custom/bool-ex";
import i18n from "@/services/i18n";
import MultiSpecificityFormField from "@/modules/budget/staffing-table/ebk-ek/MultiSpecificityFormField.vue";
import MultiBudgetProgramSelectionTemp from "@/modules/budget/staffing-table/budget-programs/MultiBudgetProgramSelectionTemp.vue";

const LocalTranslates = {
    templates: {
        formReports: {
            get title(): string {
                return i18n.choose(
                    'Данный шаблон предназначен для отображения отчетов по выбранным формам в рамках выбранных бюджетных программ и версии бюджета для учреждения',
                    'Бұл шаблон таңдалған бюджет бағдарламалары мен бюджеттің нұсқасы аясында мекеме үшін таңдалған формалар бойынша есептерді көрсетуге арналған',
                    'This template is designed to display reports for the selected forms within the chosen budget programs and budget version for the institution',
                );
            },
        },
    },
};

// region Ключ шаблона
const templateKey  = 'form-reports'


@Component({
    components: {
        Overlay,
        MultiBudgetProgramSelectionTemp,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
        MultiSpecificityFormField
    },
})
export default class FormTemplate extends BaseTemplate {

    // region Утилиты
    public i18n = i18n;

    // region Утилиты
    public translates = LocalTranslates;

    private created() {
        this.$watch('org', (org: Org | null) => {
            store.org = org
            this.resetStaffTabVersion()
            this.updateBudgetVariant(org)
            this.emitNullExpressionToParent()

        });

        this.$watch('reportYear', () => {
            store.reportYear = this.reportYear;
            this.updateBudgetVariant(this.org)
        });

        this.$watch('budgetVariant', () => {
            store.budgetVariant = this.budgetVariant
            this.emitNullExpressionToParent()
        })

        this.$watch('version', () => {
            store.version = this.version
        })

        this.$watch('multiSelectedBudgetProgram', () => {
            store.multiSelectedBudgetProgram = this.multiSelectedBudgetProgram
        })


        this.$watch('selectedOptionsForms', () => {
            store.selectedOptionsForms = this.selectedOptionsForms
        })
    }

    public get loading(): boolean {
        return this.variantLoading
    }

    public variantLoading = false;

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = store.org;

    // region Версия Шр
    public version: Version | null = store.version;

    public reportYear = store.reportYear;

    public budgetVariant: BudgetVariants | null = store.budgetVariant;

    // region Выбранная бюджетная программа
    public multiSelectedBudgetProgram = store.multiSelectedBudgetProgram
    public selectedOptionsForms = store.selectedOptionsForms

    get expressionItem(): Expression<boolean> {
        return {
            type: 'boolean-group',
            dataType: 'boolean',
            operation: 'AND',
            items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: this.getStaffTabVersionId
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'version-id',
                        title: 'staffTab version'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.getBudgetVariantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                        title: 'Budget Variant UUID'
                    }
                },
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'budg-prog-code',
                        title: 'Budget Subprogram Code'
                    },
                    valueVariants: this.createBudgetProgVariants()
                } as Expression<boolean>,
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'form-code',
                        title: 'form code'
                    },
                    valueVariants: this.createFormsVariants()
                } as Expression<boolean>,
            ]
        };
    }


    private get getBudgetVariantUuid(): string | null {
        return this.budgetVariant?.variantUuid ?? null
    }

    private get getStaffTabVersionId(): number | null {
        return this.version?.id ?? null
    }

    private createBudgetProgVariants(): { type: string, dataType: string, value: number | null } [] {
        return this.multiSelectedBudgetProgram.map(value => ({
            type: 'parameter',
            dataType: 'number',
            value: value.prg
        }));
    }

    private createFormsVariants(): { type: string, dataType: string, value: string | null } [] {
        return this.selectedOptionsForms.map(from => ({
            type: 'parameter',
            dataType: 'string',
            value: from
        }));
    }

    public validateNulls(): boolean {
        const uuid = this.budgetVariant?.variantUuid ?? null
        const isEmptyStaffTab = this.version
        const isEmptyBudgetProg = this.multiSelectedBudgetProgram.isEmpty
        const isEmptyForms = this.selectedOptionsForms.isEmpty

        return isEmptyStaffTab == null || uuid == null  || isEmptyBudgetProg || isEmptyForms
    }

    private resetBudgetVariant() {
        this.budgetVariant = null
    }

    private resetStaffTabVersion() {
        this.version = null
    }

    public resetParentTemplateKey() {
        this.$emit('reset-selected-templateKey');
    }

    async updateBudgetVariant(org: Org | null) {
        this.variantLoading = true
        this.budgetVariant = org ? await this.setActualVariant(org, this.reportYear) : null;
        this.variantLoading = false
    }

    emitNullExpressionToParent() {
        this.$emit('expression-to-parent', null, templateKey);
    }

    emitExpressionToParent() {
        const item = this.expressionItem
        prepareForBackend(item)
        this.$emit('expression-to-parent', item, templateKey);
    }

    get abpCode(): number | null {
        const guCode = this.gu?.code ?? null;

        if (guCode === null) {
            return null;
        }
        if (guCode.length < 3) {
            return null;
        }

        const abpCodeString = guCode.substring(0, 3);
        const abpCode = parseInt(abpCodeString);

        if (String(abpCode) !== abpCodeString) {
            console.error(`GU code "${guCode}" contains invalid ABP code "${abpCodeString}"`);
            return null;
        }
        return abpCode;
    }

    public get gu(): Dict.Gu | null {
        const org = this.org;
        if (org === null) {
            return null;
        }

        const type = org.type;
        switch (org.type) {
            case 'KGKP':
                return org.gu;
            case 'GU':
                return org.gu;
            default:
                console.error(`Unexpected org type \"${type}\"`);
                return null;
        }
    }

}



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Child, ItemPane } from '../part';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    child: {
        label: internal.localized(
            'Expression',
            'Выражение (каз)', // TODO translate
            'Выражение',
        ),
        newItemLabel: internal.localized(
            'Click to set expression',
            'Кликните для добавления выражения (каз)', // TODO translate
            'Кликните для добавления выражения',
        ),
    },
    deleteButtonText: internal.localized(
        'Delete this negation',
        'Удалить это отрицание (каз)', // TODO translate
        'Удалить это отрицание',
    ),
    head: internal.localized(
        'Negation (boolean "NO")',
        'Отрицание (логическое "НЕТ") (каз)', // TODO translate
        'Отрицание (логическое "НЕТ")',
    ),
};


@Component({
    components: {
        Child,
        ItemPane,
    },
})
export default class Not extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.Not;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public index = index;
    public dataTypes: Array<string> = [index.dataTypes.boolean];
    public translates = translates;

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

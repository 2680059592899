
















































import {BaseTemplate} from "@/modules/budget/staffing-table/reports/templates/base-template";
import {Component} from "vue-property-decorator";
import {Overlay} from "@/modules/budget/staffing-table/components";
import {SingleBudgetProgramField} from "@/modules/budget/staffing-table/budget-programs";
import {SingleBudgetVariantField} from "@/modules/budget/staffing-table/budget-variants";
import {SingleOrgField} from "@/modules/budget/staffing-table/organizations";
import {SingleVersionField} from "@/modules/budget/staffing-table/versions";
import {BudgetVariants, Org} from "@/modules/budget/staffing-table/types";
import * as common from "@/modules/budget/staffing-table/common";
import {store} from "@/modules/budget/staffing-table/common";
import {Expression, prepareForBackend} from "@/components/custom/bool-ex";
import i18n from "@/services/i18n";

const LocalTranslates = {
    templates: {
        variantBudgetReports: {
            get title(): string {
                return i18n.choose(
                    'Данный шаблон предназначен для отображения всех отчетов по выбранному варианту бюджета',
                    'Таңдалған бюджет варианты бойынша есептіліктерді көрсету үшін бұл үлгі',
                    'This template is designed to display all reports for the selected budget variant',
                );
            },
        },
    },
};

// region Ключ шаблона
const templateKey  = 'variant-budget-reports'


@Component({
    components: {
        Overlay,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
    },
})
export default class VariantBudgetTemplate extends BaseTemplate {

    // region Утилиты
    public i18n = i18n;

    // region Утилиты
    public translates = LocalTranslates;

    private created() {
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            this.updateBudgetVariant(org)
            this.emitNullExpressionToParent()
        });

        this.$watch('reportYear', () => {
            store.reportYear = this.reportYear;
            this.updateBudgetVariant(this.org)
        });

        this.$watch('budgetVariant', () => {
            store.budgetVariant = this.budgetVariant
            this.emitNullExpressionToParent()
        })
    }

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = common.store.org;

    public reportYear = store.reportYear;

    public budgetVariant: BudgetVariants | null = store.budgetVariant;

    get expressionItem(): Expression<boolean> {
      return {
            type: 'boolean-group',
                dataType: 'boolean',
            operation: 'AND',
            items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: this.getOrgCode
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'version--org-code',
                        title: 'code org'
                    }
                },
            {
                type: 'two-value-comparison',
                dataType: 'boolean',
                first: {
                    type: 'parameter',
                    dataType: 'string',
                    value: this.getBudgetVariantUuid
                },
                operation: 'equals',
                second: {
                    type: 'custom-field',
                    dataType: 'string',
                    key: 'budget-variant-uuid',
                    title: 'Budget Variant UUID'
                }
            }
        ]
        } as Expression<boolean>;

    }

// region Общие данные
    public get loading(): boolean {
        return this.variantLoading
    }

    public variantLoading = false;

    private get getBudgetVariantUuid(): string | null {
        return this.budgetVariant?.variantUuid ?? null
    }

    private get getOrgCode(): string | null {
        return this.org?.org.code ?? null
    }

    public validateNulls(): boolean {
        const uuid = this.budgetVariant?.variantUuid ?? null
        const codeOrg = this.getOrgCode
        return uuid == null && codeOrg === null
    }

    private resetBudgetVariant() {
        this.budgetVariant = null
    }

  public resetParentTemplateKey() {
        this.$emit('reset-selected-templateKey');
    }

    emitExpressionToParent() {
        const item = this.expressionItem
        prepareForBackend(item)
        this.$emit('expression-to-parent', item, templateKey);
    }

    emitNullExpressionToParent() {
        this.$emit('expression-to-parent', null, templateKey);
    }

    async updateBudgetVariant(org: Org | null) {
        this.variantLoading = true
        this.budgetVariant = org ? await this.setActualVariant(org, this.reportYear) : null;
        this.variantLoading = false
    }

}

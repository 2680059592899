








































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Org, Version } from '../types';
import I18n from '../I18n';
import SingleVersionSelection from './SingleVersionSelection.vue';
import SelectionFieldRow from '../components/SelectionFieldRow.vue';


const decorationVariants = {
    fieldRow: null,
    input: 'input',
};
const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleVersionField');


@Component({
    components: {
        SelectionFieldRow,
        SingleVersionSelection,
    },
})
export default class SingleVersionField extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Version | null;

    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public decorationVariant!: string | null;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    public created() {
        // region Model, properties
        this.$watch('value', (value: Version | null) => {
            if (this.localValue?.id !== value?.id) {
                this.localValue = value;
            }
        });
        // endregion


        // region Data
        this.$watch('localValue', (localValue: Version | null) => {
            if (this.value?.id !== localValue?.id) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    public mounted() {
        if (this.localValue?.id !== this.value?.id) {
            this.localValue = this.value;
        }
    }
    // endregion


    // region Utils
    public i18n = i18n;
    public decorationVariants = decorationVariants;
    // endregion


    // region Modal
    public modalVisible: boolean = false;

    public modalValue: Version | null = null;

    public get modalOkDisabled(): boolean { return (this.modalValue === null); }

    public onModalOk() {
        this.localValue = this.modalValue;
    }
    // endregion


    // region Data
    public localValue: Version | null = null;

    public get localValueText(): string | null {
        const localValue = this.localValue;
        if (localValue === null) return null;

        return this.i18n.translate('version_text_template', [localValue.id, localValue.year]) + ': «' + (localValue?.title ?? '') + '»';
    }

    public onFieldClick() {
        this.modalValue = this.localValue;
        this.modalVisible = true;
    }
    // endregion
}






































import { Component, Model, Prop } from 'vue-property-decorator';
import { ComponentBase } from '../components';
import { Dict } from '../types';
import SingleBudgetProgramSelection from './SingleBudgetProgramSelection.vue';


const modelChangeEvent = 'change';


@Component({
    components: {
        SingleBudgetProgramSelection,
    },
})
export default class SingleBudgetProgramField extends ComponentBase {
    constructor() {
        super('modules.budget.staffing_table.budget_programs.*SingleBudgetProgramField*');
    }


    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Dict.EbkFunc | null;

    @Prop({
        type: Number,
        required: true,
    })
    public abpCode!: number;

    @Prop({
        type: Number,
        required: true,
    })
    public year!: number;

    @Prop({
        type: String,
        required: false,
        default: 'auto',
    })
    public fieldWidth!: string;
    // endregion


    // region Lifecycle
    protected created() {
        super.created();


        // region Модель, свойства
        this.$watch('value', (value: Dict.EbkFunc | null) => {
            if (this.selectedBudgetProgram?.id !== value?.id) {
                this.selectedBudgetProgram = value;
            }
        });
        // endregion


        // region Выбранная бюджетная программа
        this.$watch('selectedBudgetProgram', (selectedBudgetProgram: Dict.EbkFunc | null) => {
            if (this.value?.id !== selectedBudgetProgram?.id) {
                this.$emit(modelChangeEvent, selectedBudgetProgram);
            }
        });
        // endregion
    }

    protected mounted() {
        super.mounted();

        if (this.selectedBudgetProgram?.id !== this.value?.id) {
            this.selectedBudgetProgram = this.value;
        }
    }
    // endregion


    // region Модальное окно
    public modalVisible = false;

    public modalValue: Dict.EbkFunc | null = null;

    public showModal() {
        this.modalValue = this.selectedBudgetProgram;
        this.modalVisible = true;
    }

    public onModalOk() {
        this.modalVisible = false;
        this.selectedBudgetProgram = this.modalValue;
        this.modalValue = null;
    }

    public onModalCancel() {
        this.modalVisible = false;
        this.modalValue = null;
    }
    // endregion


    // region Выбранная бюджетная программа
    public selectedBudgetProgram: Dict.EbkFunc | null = null;

    public get selectedBudgetProgramText(): string | null {
        const selectedBudgetProgram = this.selectedBudgetProgram;
        if (selectedBudgetProgram === null) {
            return null;
        }

        const id = selectedBudgetProgram.id;
        const code = ((): string => {
            let result = String(selectedBudgetProgram.prg);
            while (result.length < 3) {
                result = '0' + result;
            }
            return result;
        })();
        const name = this.localize(
            () => (selectedBudgetProgram.nameRu || ''),
            () => (selectedBudgetProgram.nameKk || ''),
            () => (selectedBudgetProgram.nameRu || ''),
        );

        return `${code} - ${name} [#${id}]`;
    }
    // endregion
}

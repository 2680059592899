
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Child, ComboBox, ItemPane } from '../part';
import type * as Index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    deleteThisItem: internal.localized(
        'Delete this check',
        'Удалить эту проверку (каз)', // TODO translate
        'Удалить эту проверку',
    ),
    head: {
        negative: internal.localized(
            'Checking if a value NOT in range of values',
            'Проверка отсутствия значения в диапазоне значений (каз)', // TODO translate
            'Проверка отсутствия значения в диапазоне значений',
        ),
        positive: internal.localized(
            'Checking if a value IS in range of values',
            'Проверка наличия значения в диапазоне значений (каз)', // TODO translate
            'Проверка наличия значения в диапазоне значений',
        ),
    },
    rangeEnd: {
        label: internal.localized(
            'Range end',
            'Конец диапазона (каз)', // TODO translate
            'Конец диапазона',
        ),
        newItemLabel: internal.localized(
            'Click to set range end',
            'Кликните для добавления конца диапазона (каз)', // TODO translate
            'Кликните для добавления конца диапазона',
        ),
    },
    rangeStart: {
        label: internal.localized(
            'Range start',
            'Начало диапазона (каз)', // TODO translate
            'Начало диапазона',
        ),
        newItemLabel: internal.localized(
            'Click to set range start',
            'Кликните для добавления начала диапазона (каз)', // TODO translate
            'Кликните для добавления начала диапазона',
        ),
    },
    typeOfCheck: {
        label: internal.localized(
            'Type of check',
            'Тип проверки (каз)', // TODO translate
            'Тип проверки',
        ),
        negative: internal.localized(
            'Value NOT in range',
            'Значение НЕ ВХОДИТ в диапазон (каз)', // TODO translate
            'Значение НЕ ВХОДИТ в диапазон',
        ),
        positive: internal.localized(
            'Value IS in range',
            'Значение ВХОДИТ в диапазон (каз)', // TODO translate
            'Значение ВХОДИТ в диапазон',
        ),
    },
    valueBeingChecked: {
        label: internal.localized(
            'Value being checked',
            'Проверяемое значение (каз)', // TODO translate
            'Проверяемое значение',
        ),
        newItemLabel: internal.localized(
            'Click to set value being checked',
            'Кликните для добавления проверяемого значения (каз)', // TODO translate
            'Кликните для добавления проверяемого значения',
        ),
    },
};


@Component({
    components: {
        Child,
        ComboBox,
        ItemPane,
    },
})
export default class Between extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.Between;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public translates = translates;

    public get head(): string {
        if (this.value.negative) {
            return translates.head.negative.toString();
        } else {
            return translates.head.positive.toString();
        }
    }

    public getTypeOfCheckText(negative: boolean): string {
        if (negative) {
            return translates.typeOfCheck.negative.toString();
        } else {
            return translates.typeOfCheck.positive.toString();
        }
    }

    public get childrenDataTypes(): Array<string> {
        let item: Internal.Expression | null;

        item = this.value.target;
        if (item !== null) {
            return [item.dataType];
        }

        item = this.value.rangeStart;
        if (item !== null) {
            return [item.dataType];
        }

        item = this.value.rangeEnd;
        if (item !== null) {
            return [item.dataType];
        }

        return [];
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

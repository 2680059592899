











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ComboBox, ChildrenArray, ItemPane } from '../part';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const translates = {
    deleteButtonText: internal.localized(
        'Delete this condition group',
        'Удалить эту группу условий (каз)', // TODO translate
        'Удалить эту группу условий',
    ),
    head: {
        and: internal.localized(
            'Condition group [AND]',
            'Группа условий [И] (каз)', // TODO translate
            'Группа условий [И]',
        ),
        or: internal.localized(
            'Condition group [OR]',
            'Группа условий [ИЛИ] (каз)', // TODO translate
            'Группа условий [ИЛИ]',
        ),
    },
    items: {
        label: internal.localized(
            'Nested conditions',
            'Вложенные условия (каз)', // TODO translate
            'Вложенные условия',
        ),
        newItemLabel: internal.localized(
            'Click to add condition here',
            'Кликните для добавления условия (каз)', // TODO translate
            'Кликните для добавления условия',
        ),
    },
    operation: {
        label: internal.localized(
            'Connect nested conditions using...',
            'Соединять вложенные условия, используя... (каз)', // TODO translate
            'Соединять вложенные условия, используя...',
        ),
        value: {
            and: internal.localized(
                'AND',
                'И (каз)', //TODO translate
                'И',
            ),
            or: internal.localized(
                'OR',
                'ИЛИ (каз)', //TODO translate
                'ИЛИ',
            ),
        },
    },
};


@Component({
    components: {
        ComboBox,
        ChildrenArray,
        ItemPane,
    },
})
export default class BooleanGroup extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.BooleanGroup;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public index = index;
    public translates = translates;

    public get head(): string {
        if (this.value.operation === 'AND') {
            return translates.head.and.toString();
        } else {
            return translates.head.or.toString();
        }
    }
    // endregion


    // region Значение
    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}

































































































































































import { Component, Prop } from 'vue-property-decorator';
import i18n from '@/services/i18n';
import { store } from '../common';
import { CollapsiblePane, ComponentBase, Overlay, Pagination } from '../components';
import { BudgetVariants, Dict, Org, Report2, Version, Utils } from '../types';
import type { BoolEx, ReportV2ListRequestData as RequestData } from './types';


const translates = {
    temporalFormats: {
        get dateNumericTimeHMS(): Intl.DateTimeFormat {
            return i18n.choose(
                () => (new Intl.DateTimeFormat('ru', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('kk', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('en', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    hour12: true,
                    minute: '2-digit',
                    second: '2-digit',
                })),
            )();
        },
        get dateNumeric(): Intl.DateTimeFormat {
            return i18n.choose(
                () => (new Intl.DateTimeFormat('ru', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('kk', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('en', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })),
            )();
        },
        get timeHM(): Intl.DateTimeFormat {
            return i18n.choose(
                () => (new Intl.DateTimeFormat('ru', {
                    hour: '2-digit',
                    minute: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('kk', {
                    hour: '2-digit',
                    minute: '2-digit',
                })),
                () => (new Intl.DateTimeFormat('en', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                })),
            )();
        },
    },
    reportFields: {
        abp: {
            get title(): string {
                return i18n.choose(
                    'АБП',
                    'АБП (каз)', // TODO translate
                    'ABP',
                );
            },
        },
        budgetProgram: {
            get title(): string {
                return i18n.choose(
                    'БП',
                    'БП (каз)', // TODO translate
                    'BP',
                );
            },
        },
        budgetSubprogram: {
            get title(): string {
                return i18n.choose(
                    'БПП',
                    'БПП (каз)', // TODO translate
                    'BSP',
                );
            },
        },
        budgetVariant: {
            get title(): string {
                return i18n.choose(
                    'Вариант бюджета',
                    'Вариант бюджета (каз)', // TODO translate
                    'Budget variant',
                );
            },
        },
        creationMoment: {
            get title(): string {
                return i18n.choose(
                    'Дата и время создания',
                    'Дата и время создания (каз)', // TODO translate
                    'Creation date and time',
                );
            },
            value(report: Report2): string {
                return translates.temporalFormats.dateNumericTimeHMS.format(new Date(report.creationMoment));
            },
        },
        submissionDateToTotal: {
            get title(): string {
                return i18n.choose(
                    'Дата и время отправки в свод',
                    'Сводқа жіберілген күн мен уақыт',
                    'Date and time of submission to the total',
                );
            },
            value(report: Report2): string {
                return report.submissionDateToTotal
                    ? translates.temporalFormats.dateNumericTimeHMS.format(new Date(report.submissionDateToTotal)) : '';
            }

        },
        reportForm: {
            get title(): string {
                return i18n.choose(
                    'Отчет',
                    'Отчет (каз)', // TODO translate
                    'Report',
                );
            },
        },
        funcGroup: {
            get title(): string {
                return i18n.choose(
                    'ФГ',
                    'ФГ (каз)', // TODO translate
                    'FG',
                );
            },
        },
        funcSubgroup: {
            get title(): string {
                return i18n.choose(
                    'ФПГ',
                    'ФПГ (каз)', // TODO translate
                    'FSG',
                );
            }
        },
        id: {
            get title(): string {
                return i18n.choose(
                    'ID',
                    'ID',
                    'ID',
                );
            },
        },
        org: {
            get title(): string {
                return i18n.choose(
                    'Организация',
                    'Организация (каз)', // TODO translate
                    'Organization',
                );
            },
            value(org: Org): string {
                const type = org.type;
                switch (org.type) {
                    case 'GU':
                        return i18n.choose(
                            () => (`ГУ "${org.gu.code}" - ${org.gu.nameRu}`),
                            () => (`ГУ "${org.gu.code}" - ${org.gu.nameKk ?? org.gu.nameRu} (каз)`), // TODO translate
                            () => (`GU "${org.gu.code}" - ${org.gu.nameRu ?? org.gu.nameRu}`), // TODO translate
                        )();
                    case 'KGKP':
                        return i18n.choose(
                            () => (`КГКП "${org.kgkp.bin}" - ${org.kgkp.nameRu}`),
                            () => (`КГКП "${org.kgkp.bin}" - ${org.kgkp.nameKk ?? org.kgkp.nameRu} (каз)`), // TODO translate
                            () => (`KGKP "${org.kgkp.bin}" - ${org.kgkp.nameRu}`), // TODO translate
                        )();
                    default:
                        throw new Error(`Unexpected org type "${type}"`);
                }
            },
        },
        planningPeriod: {
            get title(): string {
                return i18n.choose(
                    'Плановый период',
                    'Плановый период (каз)', // TODO translate
                    'Planning period',
                );
            },
        },
        regionCode: {
            get title(): string {
                return i18n.choose(
                    'Код региона',
                    'Код региона (каз)', // TODO translate
                    'Region code',
                );
            },
        },
        regionName: {
            get title(): string {
                return i18n.choose(
                    'Название региона',
                    'Название региона (каз)', // TODO translate
                    'Region name',
                );
            },
            value(report: Report2): string | null {
                const region = (report.regionObject!!);
                return i18n.choose(
                    region.nameRu,
                    region.nameKk,
                );
            },
        },
        reportDate: {
            get title(): string {
                return i18n.choose(
                    'Дата отчета',
                    'Дата отчета (каз)', // TODO translate
                    'Report date',
                );
            },
            value(report: Report2): string {
                const budgetVariant = (report.budgetVariantObject!!);
                const dateTime = budgetVariant.dateTime;
                if (dateTime === null) {
                    return '';
                } else {
                    return translates.temporalFormats.dateNumeric.format(new Date(dateTime));
                }
            },
        },
        specificity: {
            get title(): string {
                return i18n.choose(
                    'Специфика',
                    'Специфика (каз)', // TODO translate
                    'Specificity',
                );
            },
        },
        sendToTotalMoment: {
            get title(): string {
                return i18n.choose(
                    'Дата и время отправки в свод',
                    'Дата и время отправки в свод (каз)', // TODO translate
                    'Date and time of sending to total',
                );
            },
        },
        version: {
            get title(): string {
                return i18n.choose(
                    'Версия ШР',
                    'Версия ШР (каз)', // TODO translate
                    'ST version',
                );
            },
        },
    },
};


const eventNames = {
    reportSelectionChanged: 'report-selection-changed',
};


@Component({
    components: {
        CollapsiblePane,
        Overlay,
        Pagination,
    }
})
export default class ReportListPane extends ComponentBase {
    constructor() {
        super('');
    }


    // region Модель, свойства
    @Prop({
        required: false,
        default: null,
    })
    public readonly lastReportEventTrigger: unknown;

    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public readonly condition!: BoolEx.Expression<boolean> | null;
    // endregion


    // region Lifecycle
    protected created() {
        super.created();
        this.updateRequestData()

        // region Модель, свойства
        this.$watch('lastReportEventTrigger', () => {
            this.reloadReports();
        });
        this.$watch('condition', (condition: BoolEx.Expression<boolean> | null) => {
            store.conditionExp = condition
            this.updateRequestData();
        });
        // endregion

        // region Навигация по страницам
        this.$watch('itemsPerPage', (/* itemsPerPage: number */) => {
            this.updateRequestData();
        });
        this.$watch('page', (/* page: number */) => {
            this.updateRequestData();
        });
        // endregion

        // region Данные для запроса
        this.$watch('requestData', (/* requestData: RequestData | null */) => {
            this.reloadReports();
        });
        // endregion

        // region Отчеты
        this.$watch('selectedReports', (selectedReports: Array<Report2>) => {
            this.$emit(eventNames.reportSelectionChanged, selectedReports);
        });
        // endregion
    }

    protected mounted() {
        super.mounted();
        this.reloadReports();
    }
    // endregion


    // region Утилиты
    public translates = translates;

    public get loading(): boolean {
        return this.loadingReports;
    }
    // endregion


    // region Навигация по страницам
    public itemsPerPage = 25;
    public page = 0;
    public totalItems = 0;

    public onItemsPerPageChanged(itemsPerPage: unknown) {
        this.itemsPerPage = (itemsPerPage as number);
    }
    // endregion


    // region Данные для запроса
    public requestData: RequestData | null = null;

    public updateRequestData() {
        const itemsPerPage = this.itemsPerPage;
        const page = this.page;
        const condition = this.condition;
        if (condition === null) {
            this.requestData = null;
        }

        this.requestData = {
            pagination: {
                itemsPerPage,
                page,
            },
            condition,
        };
    }
    // endregion


    // region Отчеты
    public reports: Array<Report2> = [];
    public loadingReports = false;
    public selectedIdSet = new Set<number>();

    public get selectedReports(): Array<Report2> {
        const result: Array<Report2> = [];

        const idSet = this.selectedIdSet;
        if (idSet.size > 0) {
            const reports = this.reports;
            if (reports.length > 0) {
                return reports.filter((report) => {
                    const id = report.id;
                    return ((id !== null) && idSet.has(id));
                });
            }
        }

        return result;
    }

    public reloadReports() {
       const condition = this.requestData?.condition ?? null

        if (condition === null) {
            this.reports = [];
            return;
        }

        const requestData = this.requestData;
        if (requestData == null) {
            this.loadingReports = false;
            this.reports = [];
            this.selectedIdSet = new Set();
        } else {
            if (this.loadingReports) {
                console.error('Cannot reload report list - another loading is running');
                return;
            }

            this.loadingReports = true;
            this.reports = [];
            this.selectedIdSet = new Set();
            this.request<Utils.PaginationList<Report2>>(
                {
                    url: '/api/budget/staffing_table/report-v2/list',
                    method: 'POST',
                    data: requestData,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                (data) => {
                    this.totalItems = data.itemCount;
                    this.itemsPerPage = data.itemsPerPage;
                    this.page = data.page;
                    this.reports = data.items;
                },
                undefined,
                () => {
                    this.loadingReports = false;
                },
            );
        }
    }

    public getRegion(report: Report2): Dict.BudgetRegions {
        return report.regionObject!!;
    }

    public getBudgetVariant(report: Report2): BudgetVariants {
        return report.budgetVariantObject!!;
    }

    public getBudgetVariantTitle(report: Report2): string {
        const budgetVariant = this.getBudgetVariant(report);
        return i18n.choose(
            () => (`[${budgetVariant.variantUuid}] ${budgetVariant.nameRu}`),
            () => (`[${budgetVariant.variantUuid}] ${budgetVariant.nameKk ?? budgetVariant.nameRu}`),
            () => (`[${budgetVariant.variantUuid}] ${budgetVariant.nameEn ?? budgetVariant.nameRu}`),
        )();
    }

    public getVersion(report: Report2): Version {
        return report.version!!;
    }

    public getVersionTitle(report: Report2): string {
        const version = this.getVersion(report);
        return `[#${version.id}] ${version.title}`;
    }

    public getOrg(report: Report2): Org {
        return report.org!!;
    }

    public getBudgetSubprogramTitle(report: Report2): string {
        const budgetSubprogram = report.budgetSubprogram;
        if (budgetSubprogram === null) {
            return '';
        } else {
            return this.padStart(budgetSubprogram, '0', 3);
        }
    }

    public padStart(value: unknown, padding: string, length: number): string {
        const parts = [String(value)];
        let currentLength = parts[0].length;
        while (currentLength < length) {
            parts.splice(0, 0, padding);
            currentLength += padding.length;
        }
        return parts.join('');
    }

    public openReportDblClicked(report: Report2) {
        const id = report.id;
        if (id === null) {
            console.error('Cannot open report, ID is null');
            return;
        }

        this.$router.push({ path: `/staffing-table/report-v2/${id}` });
    }

    public isReportSelected(report: Report2): boolean {
        const id = report.id;
        if (id !== null) {
            return this.selectedIdSet.has(id);
        }

        return false;
    }

    public onReportSelectionToggled(report: Report2) {
        const id = report.id;
        if (id === null) {
            return;
        }

        const newSet = new Set(this.selectedIdSet);
        if (newSet.has(id)) {
            newSet.delete(id);
        } else {
            newSet.add(id);
        }
        this.selectedIdSet = newSet;
    }
    // endregion
}

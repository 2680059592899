





















import i18n from "@/services/i18n";
import {ComponentBase} from "@/modules/budget/staffing-table/components";
import {Model, Prop} from "vue-property-decorator";
import {Dict} from "@/modules/budget/staffing-table/types";
import Component from "vue-class-component";
import MultiSelectionSpecificity from "@/modules/budget/staffing-table/ebk-ek/MultiSelectionSpecificity.vue";
import MultiSelectionForm from "@/modules/budget/staffing-table/ebk-ek/MultiSelectionForm.vue";

const Translates = {
    optionFields: {
        title: {
            get title(): string {
                return i18n.choose(
                    'Выбор специфики',
                    'Ерекшелікті таңдау',
                    'Specific Selection',
                );
            },
        },
    },
};


const modelChangeEvent = 'change';

@Component({
    components: {
        MultiSelectionSpecificity,
        MultiSelectionForm,
    },
})

export default class MultiSpecificityFormField extends ComponentBase {
    constructor() {
        super('');
    }

    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Array,
            required: false,
            default: () => []
        }
    )
    public selectedOptionsForms!: string[];
    public localSelectedOptionsForms: string[] = [];


    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly selectedSpecificOptions!: Dict.EbkEk[] ;
    private localSelectedSpecificOptions: Dict.EbkEk[] = []


    @Prop({
        type: Number,
        required: true,
    })
    public readonly reportYear!: number;
    // region

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly useSpecificity!: boolean;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly useForms!: boolean;


    protected created() {
        super.created();
       if (this.selectedSpecificOptions.isNotEmpty) {
           this.localSelectedSpecificOptions =  this.selectedSpecificOptions
       }

        if (this.selectedOptionsForms.isNotEmpty) {
            this.localSelectedOptionsForms =  this.selectedOptionsForms
        }

        this.$watch('localSelectedSpecificOptions', () => {
            this.$emit('update-selected-items', this.localSelectedSpecificOptions);

        })

        this.$watch('localSelectedOptionsForms', () => {
            this.$emit(modelChangeEvent, this.localSelectedOptionsForms);
        })
    }
}













































import {ComponentBase, Overlay} from "@/modules/budget/staffing-table/components";
import {Model, Prop} from "vue-property-decorator";
import {Comp, Dict, Org} from "@/modules/budget/staffing-table/types";
import {Ax} from "@/utils";
import Component from "vue-class-component";
import {store} from "@/modules/budget/staffing-table/common";
import DropdownItemDef = Comp.DropdownItemDef;
import i18n from "@/services/i18n";


const Translates = {
    optionFields: {
        title: {
            get title(): string {
                return i18n.choose(
                    'Выбор бюджетной программы',
                    'Бюджет бағдарламасын таңдау',
                    'Budget Program Selection',
                );
            },
        },
    },
};

const modelChangeEvent = 'change';

interface ExtendedDropdownItemDef<T> extends DropdownItemDef<T> {
    disabled: boolean
}


@Component({
    components: {
        Overlay
    },
})

export default class MultiBudgetProgramSelectionTemp extends ComponentBase {
    constructor() {
        super('');
    }

    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Array,
            required: true,
            default: [],
        },
    )
    public value!: Dict.EbkFunc[];

    @Prop({
        type: Number,
        required: true,
    })
    public abpCode!: number;

    @Prop({
        type: Number,
        required: true,
    })
    public readonly reportYear!: number;


    protected created() {
        super.created();
        if (this.value.isNotEmpty) {
            this.multiSelectedBudgetProgram = this.value
        }

        // region Выбранная бюджетная программа
        this.$watch('multiSelectedBudgetProgram', (selectedBudgetProgram: Dict.EbkFunc []) => {
            this.$emit(modelChangeEvent, selectedBudgetProgram);
        });

        this.$watch('reportYear', () => {
            this.multiSelectedBudgetProgram = []
            this.reloadBudgetPrograms()
        });

        this.$watch('abpCode', () => {
            this.multiSelectedBudgetProgram = []
            this.reloadBudgetPrograms()
        });
    }

    protected mounted() {
        super.mounted();
        this.reloadBudgetPrograms();
    }


    public translates = Translates
    public loading = false;

    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = store.org;

    // region Выбранная бюджетная программа
    public multiSelectedBudgetProgram: Dict.EbkFunc [] = [];
    // endregion

    public budgetPrograms: Array<Dict.EbkFunc> = [];
    // endregion


    public addTag(program: Dict.EbkFunc) {
        if (!this.multiSelectedBudgetProgram.includes(program)) {
            this.multiSelectedBudgetProgram.push(program)
        }

        this.budgetPrograms = [...this.budgetPrograms];
    }

    public onOptionClick(option: { value: Dict.EbkFunc }) {
        this.addTag(option.value);
    }

    private get selectedIds(): Array<number | null> {
        return this.multiSelectedBudgetProgram.map(item => item.id);
    }

    public unselect(program: Dict.EbkFunc) {
        const index = this.selectedIds.indexOf(program.id);
        if (index >= 0) {
            this.multiSelectedBudgetProgram.splice(index, 1);
        }
    }

    public isSelected(item: Dict.EbkFunc): boolean {
        return this.multiSelectedBudgetProgram.some(selectedItem => {
            return selectedItem.id === item.id;
        });
    }


    public get budgetProgramOptions(): Array<ExtendedDropdownItemDef<Dict.EbkFunc>> {
        const result: Array<ExtendedDropdownItemDef<Dict.EbkFunc>> = [];

        this.budgetPrograms.forEach(program => {
            let code = String(program.prg);
            while (code.length < 3) code = '0' + code;

            let title: string;
            if (this.i18n.isKazakh) {
                title = program.nameKk || '';
            } else {
                title = program.nameRu || '';
            }

            const disable = this.isSelected(program)

            const item: ExtendedDropdownItemDef<Dict.EbkFunc> = {
                value: program,
                text: `${code} - ${title}`,
                disabled: disable
            };
            result.push(item);
        });

        return result;
    }

    public shortenText(text: string) {
        const maxLength = 70;
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    }

    public shortenTag(item: Dict.EbkFunc): string {
        const title = this.getItemFullText(item)
        const maxLength = 11;
        if (title.length > maxLength) {
            return title.slice(0, maxLength) + '...';
        } else {
            return title;
        }
    }

    public getItemFullText(program: Dict.EbkFunc): string {
        let code = String(program.prg);
        while (code.length < 3) code = '0' + code;

        let title: string;
        if (this.i18n.isKazakh) {
            title = program.nameKk || '';
        } else {
            title = program.nameRu || '';
        }
        return `${code} - ${title}`
    }


    public reloadBudgetPrograms() {
        if (this.loading) {
            console.error('Cannot reload budget programs - another loading is running');
            return;
        }

        const abpCode = this.abpCode;
        const year = this.reportYear;
        this.budgetPrograms = [];
        this.loading = true;

        Ax<Array<Dict.EbkFunc>>(
            {
                url: `/api/budget/staffing_table/budget-programs?abp-code=${abpCode}&year=${year}`,
            },
            (data) => {
                this.budgetPrograms = data;
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('errors.cannot_load_budget_programs', [abpCode, year]),
                    error.toString(),
                )
            },
            () => {
                this.loading = false;
            },
        );
    }
}



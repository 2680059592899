


























import { Component, Prop, Vue } from 'vue-property-decorator';
import Between from '../item/Between.vue';
import BooleanGroup from '../item/BooleanGroup.vue';
import CustomField from '../item/CustomField.vue';
import CustomValue from '../item/CustomValue.vue';
import In from '../item/In.vue';
import IsNull from '../item/IsNull.vue';
import Like from '../item/Like.vue';
import Not from '../item/Not.vue';
import Parameter from '../item/Parameter.vue';
import TwoValueComparison from '../item/TwoValueComparison.vue';
import type * as Index from '../index';
import type * as Internal from '../internal';


const eventNames = {
    delete: 'delete',
};


const itemTypeComponents: Record<string, typeof Vue> = {
    'between': Between,
    'boolean-group': BooleanGroup,
    'custom-field': CustomField,
    'custom-value': CustomValue,
    'in': In,
    'is-null': IsNull,
    'like': Like,
    'not': Not,
    parameter: Parameter,
    'two-value-comparison': TwoValueComparison,
};


@Component({
    name: 'modules__budget__staffing_table__BoolExItemRenderer',
})
export default class ItemRenderer extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.Item;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;

    @Prop({
        type: Array,
        required: true,
    })
    public dataTypes!: Array<string>;
    // endregion


    public get componentClass(): (typeof Vue | null) {
        return itemTypeComponents[this.value.type] ?? null;
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
}

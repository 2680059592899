


















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CollapsiblePane from '../../CollapsiblePane.vue';
import SvgIcon from '../../SvgIcon.vue';
import type * as Internal from '../internal';
import * as internal from '../internal';


const eventNames = {
    delete: 'delete',
};

const deleteButtonText = internal.localized(
    'Delete this item',
    'Удалить этот элемент (каз)', // TODO translate
    'Удалить этот элемент',
);


@Component({
    components: {
        CollapsiblePane,
        SvgIcon,
    },
})
export default class ItemPane extends Vue {
    // region Модель, свойства
    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.Item;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;

    @Prop({
        type: String,
        required: true,
    })
    public head!: string;

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public deleteButtonText!: string | null;
    // endregion


    // region Lifecycle
    protected mounted() {
        this.checkSlots();
    }

    protected updated() {
        this.checkSlots();
    }
    // endregion


    // region Разное
    public internal = internal;


    public get paneDecoration(): 'green-head-pane' | 'red-head-pane' {
        if (this.value.error === null) {
            return 'green-head-pane';
        } else {
            return 'red-head-pane';
        }
    }


    public get notNullDeleteButtonText(): string {
        return this.deleteButtonText ?? deleteButtonText.toString();
    }

    public onDeleteClick() {
        this.$emit(eventNames.delete);
        this.value.onDeleted();
    }


    public get errorText(): string | null {
        const error = this.value.error;
        if (error === null) {
            return null;
        } else {
            return error.localizedMessage;
        }
    }


    public hasFooterSlot = false;
    public hasDefaultSlot = false;

    public checkSlots() {
        const slots = (this.$slots as unknown);
        if (slots instanceof Object) {
            let hasFooterSlot = false;
            let hasDefaultSlot = false;

            Object.getOwnPropertyNames(slots).forEach((slotName) => {
                switch (slotName) {
                    case 'footer':
                        hasFooterSlot = true;
                        break;
                    case 'default':
                        hasDefaultSlot = true;
                        break;
                }
            });

            if (this.hasFooterSlot !== hasFooterSlot) {
                this.hasFooterSlot = hasFooterSlot;
            }
            if (this.hasDefaultSlot !== hasDefaultSlot) {
                this.hasDefaultSlot = hasDefaultSlot;
            }
        }
    }
    // endregion
}












































import {ComponentBase, Overlay} from "@/modules/budget/staffing-table/components";
import {Model, Prop} from "vue-property-decorator";
import {Comp, Dict} from "@/modules/budget/staffing-table/types";
import i18n from "@/services/i18n";
import * as common from "@/modules/budget/staffing-table/common";
import {Ax} from "@/utils";
import DropdownItemDef = Comp.DropdownItemDef;
import Component from "vue-class-component";

const Translates = {
    optionFields: {
        title: {
            get title(): string {
                return i18n.choose(
                    'Выбор специфики',
                    'Ерекшелікті таңдау',
                    'Specificity Selection',
                );
            },
        },
    },
};

const modelChangeEvent = 'change';

interface ExtendedDropdownItemDef<T> extends DropdownItemDef<T> {
    disabled: boolean
}

@Component({
    components: {
        Overlay
    },
})
export default class MultiSelectionSpecificity extends ComponentBase {
    constructor() {
        super('');
    }

    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Array,
            required: true,
            default: () => []
        }
    )
    public value!: Dict.EbkEk[];


    @Prop({
        type: Number,
        required: true,
    })
    public readonly reportYear!: number;
    // region


    private handleSpecificsToParent() {
        this.$emit(modelChangeEvent, this.selectedSpecificOptions);
    }

    public translates = Translates
    public loading = false;

// region Специфика/форма
    public selectedSpecificOptions: Array<Dict.EbkEk> = [];
    private specificities: Array<Dict.EbkEk> = []

    protected created() {
        super.created();
        if (this.value.isNotEmpty) {
            this.selectedSpecificOptions = this.value
        }

        this.$watch('selectedSpecificOptions', () => {
            this.handleSpecificsToParent()
        })

        this.$watch('reportYear', () => {
           this.selectedSpecificOptions = []
        })
    }

    protected mounted() {
        super.mounted();
        this.reloadSpecificities()
    }

    public onOptionClick(option: { value: Dict.EbkEk }) {
        this.addTag(option.value);
    }

    public shortenText(text: string) {
        const maxLength = 70;
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    }

    public getItemFullText(item: Dict.EbkEk): string {
        const code = ((): string => {
            let result = String(item.spf);
            while (result.length < 3) {
                result = '0' + result;
            }
            return result;
        })();

        const name = this.localize(
            () => (item.nameRu || ''),
            () => (item.nameKk || ''),
            () => (item.nameRu || ''),
        );

        return `${code} - ${name}`
    }

    public shortenTag(item: Dict.EbkEk): string {
        const title = this.getItemFullText(item)
        const maxLength = 11;
        if (title.length > maxLength) {
            return title.slice(0, maxLength) + '...';
        } else {
            return title;
        }
    }

    public addTag(item: Dict.EbkEk) {
        if (!this.selectedSpecificOptions.includes(item)) {
            this.selectedSpecificOptions.push(item)
        }

        this.specificities = [...this.specificities];
    }

    public isSelected(item: Dict.EbkEk): boolean {
        return this.selectedSpecificOptions.some(option => {
            return option.id === item.id;
        });
    }

    private get selectedIds(): Array<number | null> {
        return this.selectedSpecificOptions.map(option => option.id);
    }

    public unselect(item: Dict.EbkEk) {
        const index = this.selectedIds.indexOf(item.id);
        if (index >= 0) {
            this.selectedSpecificOptions.splice(index, 1);
        }
    }

    public get specificityOptions(): Array<ExtendedDropdownItemDef<Dict.EbkEk>> {
        const result: Array<ExtendedDropdownItemDef<Dict.EbkEk>> = [];

        this.specificities.forEach((specificity) => {
            const code = ((): string => {
                let result = String(specificity.spf);
                while (result.length < 3) {
                    result = '0' + result;
                }
                return result;
            })();

            const name = this.localize(
                () => (specificity.nameRu || ''),
                () => (specificity.nameKk || ''),
                () => (specificity.nameRu || ''),
            );

            const disabled = this.isSelected(specificity)

            result.push({
                text: `${code} - ${name}`,
                value: specificity,
                disabled: disabled
            });
        });

        return result;
    }


    private reloadSpecificities() {
        if (this.loading) {
            console.error('Cannot load specificities - another loading is running');
            return;
        }

        const codes = common.specificityCodes;
        const reportYear = this.reportYear;

        this.specificities = [];
        this.loading = true;

        Ax<Array<Dict.EbkEk>>(
            {
                url: this.prepareUrl(
                    '/api/budget/staffing_table/specificities',
                    [
                        ['codes', codes],
                        ['year', reportYear],
                    ],
                ),
            },
            (data) => {
                this.specificities = data;
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('cannot_load_specificities'),
                    error.toString(),
                );
            },
            () => {
                this.loading = false;

            },
        );
    }
}

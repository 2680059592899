


































import { Component, Model, Prop } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { ComponentBase, ListItem, ListItemPart, Overlay } from '../components';
import { Dict } from '../types';


const modelChangeEvent = 'change';


@Component({
    components: {
        ListItem,
        ListItemPart,
        Overlay,
    },
})
export default class SingleBudgetProgramSelection extends ComponentBase {
    constructor() {
        super('modules.budget.staffing_table.budget_programs.*SingleBudgetProgramSelection*');
    }


    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Dict.EbkFunc | null;

    @Prop({
        type: Number,
        required: true,
    })
    public abpCode!: number;

    @Prop({
        type: Number,
        required: true,
    })
    public year!: number;
    // endregion


    // region Lifecycle
    protected created() {
        super.created();

        // region Модель, свойства
        this.$watch('value', (value: Dict.EbkFunc | null) => {
            if (this.selectedBudgetProgram?.id !== value?.id) {
                this.selectedBudgetProgram = value;
            }
        });
        this.$watch('abpCode', (abpCode: number) => {
            this.reloadBudgetPrograms();
        });
        this.$watch('year', (year: number) => {
            this.reloadBudgetPrograms();
        });
        // endregion

        // region Выбранная бюджетная программа
        this.$watch('selectedBudgetProgram', (selectedBudgetProgram: Dict.EbkFunc | null) => {
            if (this.value?.id !== selectedBudgetProgram?.id) {
                this.$emit(modelChangeEvent, selectedBudgetProgram);
            }
        });
        // endregion
    }

    protected mounted() {
        super.mounted();

        if (this.selectedBudgetProgram?.id !== this.value?.id) {
            this.selectedBudgetProgram = this.value;
        }

        this.reloadBudgetPrograms();
    }
    // endregion


    // region Бюджетные программы
    public loading = false;

    public budgetPrograms: Array<Dict.EbkFunc> = [];

    public reloadBudgetPrograms() {
        if (this.loading) {
            console.error('Cannot reload budget programs - another loading is running');
            return;
        }

        const abpCode = this.abpCode;
        const year = this.year;
        this.budgetPrograms = [];
        this.loading = true;

        Ax<Array<Dict.EbkFunc>>(
            {
                url: `/api/budget/staffing_table/budget-programs?abp-code=${abpCode}&year=${year}`,
            },
            (data) => {
                this.budgetPrograms = data;
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('errors.cannot_load_budget_programs', [abpCode, this.year]),
                    error.toString(),
                )
            },
            () => {
                this.loading = false;
            },
        );
    }

    public getBudgetProgramCode(budgetProgram: Dict.EbkFunc): string {
        const code = budgetProgram.prg;
        if (code === null) {
            return '';
        } else {
            let result = String(code);
            while (result.length < 3) {
                result = '0' + result;
            }
            return result;
        }
    }

    public getBudgetProgramName(budgetProgram: Dict.EbkFunc): string {
        return this.localize(
            () => (budgetProgram.nameRu || ''),
            () => (budgetProgram.nameKk || ''),
            () => (budgetProgram.nameRu || ''),
        );
    }
    // endregion


    // region Выбранная бюджетная программа
    public selectedBudgetProgram: Dict.EbkFunc | null = null;

    public get selectedBudgetProgramIndex(): number | null {
        const selectedBudgetProgram = this.selectedBudgetProgram;
        if (selectedBudgetProgram === null) {
            return null;
        }

        for (let i = 0; i < this.budgetPrograms.length; i++) {
            const budgetProgram = this.budgetPrograms[i];
            if (budgetProgram.id === selectedBudgetProgram.id) {
                return i;
            }
        }

        return null;
    }

    public onBudgetProgramClick(budgetProgram: Dict.EbkFunc) {
        if (this.selectedBudgetProgram?.id === budgetProgram.id) {
            return;
        } else {
            this.selectedBudgetProgram = budgetProgram;
        }
    }
    // endregion
}

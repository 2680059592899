





















































































































































import { Component, Prop } from 'vue-property-decorator';
import i18n from '@/services/i18n';
import { ComponentBase } from '../components';
import type { BudgetVariants, Org, Report2 } from '../types';
import type { ReportSelection } from './types';


const eventNames = {
    changed: 'changed',
};


// region Утилиты
const utils = {
    getOrgTypedCode(org: Org): string {
        const type = org.type;
        switch (org.type) {
            case 'GU':
                return i18n.choose(
                    () => (`ГУ "${org.gu.code}"`),
                    () => (`ГУ "${org.gu.code}"`),
                    () => (`GO "${org.gu.code}"`),
                )();
            default:
                return `[unknown org type "${type}"]`;
        }
    },
};
// endregion


// region Selection items
interface BaseItem {
    type: string;
    report: Report2;
}

interface ValidItem extends BaseItem {
    type: 'valid';
    budgetVariant: BudgetVariants;
    org: Org;
    form: string;
    budgetProgram: number;
    selectionMapByYear: Map<number, ReportSelection>;
}

interface InvalidItem extends BaseItem {
    type: 'invalid';
    desc: Array<string>;
}

type Item = ValidItem | InvalidItem;

const createInvalidItem = (report: Report2, getDesc: () => Array<string>): InvalidItem => ({
    type: 'invalid',
    report,
    get desc(): Array<string> {
        return getDesc();
    },
});

const createValidItem3Years = (
    report: Report2,
    budgetVariant: BudgetVariants,
    org: Org,
    form: string,
    budgetProgram: number,
    firstYear: number,
    secondYear: number,
    thirdYear: number,
): ValidItem => {
    const createSelection = (year: number): ReportSelection => {
        return {
            report,
            budgetVariant,
            org,
            form,
            year,
            budgetProgram,
        };
    };

    const selectionMapByYear = new Map<number, ReportSelection>([
        [firstYear, createSelection(firstYear)],
        [secondYear, createSelection(secondYear)],
        [thirdYear, createSelection(thirdYear)],
    ]);

    return {
        type: 'valid',
        report,
        budgetVariant,
        org,
        form,
        budgetProgram,
        selectionMapByYear,
    };
};

const createValidItemFirstYear = (
    report: Report2,
    budgetVariant: BudgetVariants,
    org: Org,
    form: string,
    budgetProgram: number,
    firstYear: number,
): ValidItem => {
    const createSelection = (year: number): ReportSelection => {
        return {
            report,
            budgetVariant,
            org,
            form,
            year,
            budgetProgram,
        };
    };

    const selectionMapByYear = new Map<number, ReportSelection>([
        [firstYear, createSelection(firstYear)],
    ]);

    return {
        type: 'valid',
        report,
        budgetVariant,
        org,
        form,
        budgetProgram,
        selectionMapByYear,
    };
};
// endregion


// region Control items
/**
 * Проверка - дубли по набору "вариант бюджета + организация + форма + БП"
 */
namespace Control1 {
    const createKey = (
        budgetVariantUuid: string,
        orgCode: string,
        form: string,
        budgetProgramCode: number,
    ): string => {
        return `<<${budgetVariantUuid}>>--${orgCode}--${form}--${budgetProgramCode}`;
    };

    export const createKeyFromReport = (report: Report2): string => {
        return createKey(
            report.budgetVariantObject!!.variantUuid,
            report.org!!.code,
            report.form,
            report.budgetProgram,
        );
    };
}

/**
 * Проверка - "У отчетов должны быть выбраны все 3 года"
 */
namespace Control2 {
    export const getInvalidReports = (selectedItems: Array<ReportSelection>): Array<Report2> => {
        interface TmpReportSelection {
            report: Report2;
            selectedYearCount: number;
        }

        const map = new Map<number, TmpReportSelection>();
        selectedItems.forEach((selectedItem) => {
            const report = selectedItem.report;
            const budgetVariantDataType = report.budgetVariantObject!!.dataType!!
            if ((budgetVariantDataType === 1) || (budgetVariantDataType === 2)) {
                const savedSelection = map.get(report.id!!);

                const selection: TmpReportSelection = savedSelection ?? { report, selectedYearCount: 0 };
                if (savedSelection === undefined) {
                    map.set(report.id!!, selection);
                }

                selection.selectedYearCount++;
            }
        });

        const result: Array<Report2> = [];
        map.forEach((selection) => {
            if (selection.selectedYearCount < 3) {
                result.push(selection.report);
            }
        });

        return result;
    };
}
// endregion


const translates = {
    budgetProgram(value: number): string {
        let code = String(value);
        while (code.length < 3) {
            code = '0' + code;
        }

        return i18n.choose(
            () => (`БП ${code}`),
            () => (`БП ${code}`),
            () => (`BP ${code}`),
        )();
    },
    error: {
        control1: {
            groupTitle(report: Report2): string {
                return i18n.choose(
                    () => (`Дублирование атрибутов: вариант бюджета "${report.budgetVariantObject!!.variantUuid}", ${utils.getOrgTypedCode(report.org!!)}, форма "${report.form}", БП ${report.budgetProgram}`),
                    () => (`Дублирование атрибутов: вариант бюджета "${report.budgetVariantObject!!.variantUuid}", ${utils.getOrgTypedCode(report.org!!)}, форма "${report.form}", БП ${report.budgetProgram} (каз)`),
                    () => (`Doubles of attributes: budget variant "${report.budgetVariantObject!!.variantUuid}", ${utils.getOrgTypedCode(report.org!!)}, form "${report.form}", BP ${report.budgetProgram}`),
                )();
            },
            get panelTitle(): string {
                return i18n.choose(
                    'Контроль на уровне формы, БП и БПП',
                    'Контроль на уровне формы, БП и БПП (каз)',
                    'Control on level of form, BP and BSP',
                );
            },
            get reports(): string {
                return i18n.choose(
                    'Отчеты',
                    'Отчеты (каз)',
                    'Reports',
                );
            },
        },
        control2: {
            get panelTitle(): string {
                return i18n.choose(
                    'Отчеты, для которых должны быть выбраны все три года (из-за типа бюджета)',
                    'Отчеты, для которых должны быть выбраны все три года (из-за типа бюджета) (каз)',
                    'Reports for which all three years must be selected (due to budget type)',
                );
            },
        },
        noBudgetSubprogramsInYear(year: number): string {
            return i18n.choose(
                () => (`Год ${year} не содержит распределения по бюджетным подпрограммам`),
                () => (`Год ${year} не содержит распределения по бюджетным подпрограммам (каз)`),
                () => (`Year ${year} does not contain budget subprogram distribution`),
            )();
        },
        get noBudgetVariant(): string {
            return i18n.choose(
                'Внутренняя ошибка - к отчету не привязан вариант бюджета',
                'Внутренняя ошибка - к отчету не привязан вариант бюджета (каз)',
                'Internal error - no budget variant is bound to report',
            );
        },
        noDataTypeInBudgetVariant(uuid: string): string {
            return i18n.choose(
                () => (`Внутренняя ошибка - у варианта бюджета "${uuid}" нет типа данных`),
                () => (`Внутренняя ошибка - у варианта бюджета "${uuid}" нет типа данных (каз)`),
                () => (`Internal error - budget variant "${uuid}" has no data type`),
            )();
        },
        get noOrg(): string {
            return i18n.choose(
                'Внутренняя ошибка - к отчету не привязана организация',
                'Внутренняя ошибка - к отчету не привязана организация (каз)',
                'Internal error - no organization is bound to report',
            );
        },
        unknownDataTypeInBudgetVariant(uuid: string, dataType: unknown): string {
            return i18n.choose(
                () => (`Внутренняя ошибка - у варианта бюджета "${uuid}" неизвестный тип данных "${dataType}"`),
                () => (`Внутренняя ошибка - у варианта бюджета "${uuid}" неизвестный тип данных "${dataType}" (каз)`),
                () => (`Internal error - budget variant "${uuid}" has unknown data type "${dataType}"`),
            )();
        },
        yearIsInvalid(year: number): string {
            return i18n.choose(
                () => (`Год ${year} содержит ошибки`),
                () => (`Год ${year} содержит ошибки (каз)`),
                () => (`Year ${year} has errors`),
            )();
        },
    },
    form(value: string): string {
        return i18n.choose(
            () => (`Форма ${value}`),
            () => (`Форма ${value}`),
            () => (`Form ${value}`),
        )();
    },
    get selectAll(): string {
        return i18n.choose(
            'Выбрать все',
            'Выбрать все',
            'Select all',
        );
    },
};


const createSelectionKey = (selection: ReportSelection): string => {
    return `${selection.report.id}--${selection.year}`;
};


@Component({})
export default class ReportSelectionPane extends ComponentBase {
    constructor() {
        super('');
    }


    // region Lifecycle
    protected created() {
        super.created();


        // region Элементы для выбора
        this.$watch('validSelectedItems', (validSelectedItems: Array<ReportSelection>) => {
            this.$emit(eventNames.changed, validSelectedItems);
        });
        // endregion
    }

    protected mounted() {
        super.mounted();
    }
    // endregion


    // region Модель, свойства
    @Prop({
        type: Array,
        required: true,
    })
    public readonly reports!: Array<Report2>;
    // endregion


    // region Утилиты
    public translates = translates;

    public get hasErrors(): boolean {
        return (this.control1DoublesMap.size > 0) || (this.control2InvalidReports.length > 0);
    }
    // endregion


    // region Элементы для выбора
    public selectedItemKeys: Set<string> = new Set();

    public get items(): Array<Item> {
        const result: Array<Item> = [];

        this.reports.forEach((report) => {
            const budgetVariant = report.budgetVariantObject;
            const year = budgetVariant?.year ?? null
            const org = report.org;
            if (budgetVariant === null || year === null) {
                result.push(createInvalidItem(report, () => ([translates.error.noBudgetVariant])));
            } else if (org === null) {
                result.push(createInvalidItem(report, () => ([translates.error.noOrg])));
            } else {
                const budgetVariantDataType = budgetVariant.dataType;
                const form = report.form;
                const budgetProgram = report.budgetProgram;
                const firstYear = year;
                const secondYear = firstYear + 1;
                const thirdYear = secondYear + 1;
                const y1Invalid = report.y1Invalid;
                const y1NoBSP = (report.y1BudgetSubprograms.length === 0);
                const y2Invalid = report.y2Invalid;
                const y2NoBSP = (report.y2BudgetSubprograms.length === 0);
                const y3Invalid = report.y3Invalid;
                const y3NoBSP = (report.y3BudgetSubprograms.length === 0);
                switch (budgetVariantDataType) {
                    case 1:
                    case 2:
                    case 3:
                        // отобразить все три года
                        (() => {
                            if (y1Invalid || y1NoBSP || y2Invalid || y2NoBSP || y3Invalid || y3NoBSP) {
                                result.push(createInvalidItem(report, () => {
                                    const result: Array<string> = [];
                                    if (y1Invalid) result.push(translates.error.yearIsInvalid(firstYear));
                                    if (y1NoBSP) result.push(translates.error.noBudgetSubprogramsInYear(firstYear));
                                    if (y2Invalid) result.push(translates.error.yearIsInvalid(secondYear));
                                    if (y2NoBSP) result.push(translates.error.noBudgetSubprogramsInYear(secondYear));
                                    if (y3Invalid) result.push(translates.error.yearIsInvalid(thirdYear));
                                    if (y3NoBSP) result.push(translates.error.noBudgetSubprogramsInYear(thirdYear));
                                    return result;
                                }));
                            } else {
                                result.push(createValidItem3Years(
                                    report,
                                    budgetVariant,
                                    org,
                                    form,
                                    budgetProgram,
                                    firstYear,
                                    secondYear,
                                    thirdYear,
                                ));
                            }
                        })();
                        break;
                    case 4:
                        // отобразить только первый год
                        (() => {
                            if (y1Invalid || y1NoBSP) {
                                result.push(createInvalidItem(report, () => {
                                    const result: Array<string> = [];
                                    if (y1Invalid) result.push(translates.error.yearIsInvalid(firstYear));
                                    if (y1NoBSP) result.push(translates.error.noBudgetSubprogramsInYear(firstYear));
                                    return result;
                                }));
                            } else {
                                result.push(createValidItemFirstYear(
                                    report,
                                    budgetVariant,
                                    org,
                                    form,
                                    budgetProgram,
                                    firstYear,
                                ));
                            }
                        })();
                        break;
                    case null:
                        result.push({
                            type: 'invalid',
                            report,
                            get desc(): Array<string> {
                                return [translates.error.noDataTypeInBudgetVariant(budgetVariant!!.variantUuid)];
                            },
                        });
                        break;
                    default:
                        result.push({
                            type: 'invalid',
                            report,
                            get desc(): Array<string> {
                                return [translates.error.unknownDataTypeInBudgetVariant(budgetVariant!!.variantUuid, budgetVariant!!.dataType)];
                            },
                        });
                        break;
                }
            }
        });

        return result;
    }

    public get selectedItems(): Array<ReportSelection> {
        const result: Array<ReportSelection> = [];

        const selectedItemKeys = this.selectedItemKeys;
        const items = this.items;
        items.forEach((item) => {
            if (item.type === 'valid') {
                item.selectionMapByYear.forEach((selection) => {
                    const key = createSelectionKey(selection);
                    if (selectedItemKeys.has(key)) {
                        result.push(selection);
                    }
                });
            }
        });

        return result;
    }

    public get selectedReports(): Array<Report2> {
        const map = new Map<number, Report2>();

        this.selectedItems.forEach((item) => {
            const report = item.report;
            const reportId = report.id!!;
            map.set(reportId, report);
        });

        return [...map.values()];
    }

    public get validSelectedItems(): Array<ReportSelection> {
        if (this.hasErrors) {
            return [];
        } else {
            return this.selectedItems;
        }
    }

    public getYears(map: Map<number, ReportSelection>): Array<number> {
        return [...map.keys()].sort();
    }

    public getBudgetSubprograms(map: Map<number, ReportSelection>, year: number): Array<number> {
        const selection = map.get(year);
        if (selection === undefined) {
            return [];
        }

        const firstYear = selection.report.budgetVariantObject!!.year!!;
        const yearDiff = year - firstYear;
        switch (yearDiff) {
            case 0:
                return selection.report.y1BudgetSubprograms;
            case 1:
                return selection.report.y2BudgetSubprograms;
            case 2:
                return selection.report.y3BudgetSubprograms;
            default:
                return [];
        }
    }

    public getSelection(item: ValidItem, year: number): ReportSelection {
        return item.selectionMapByYear.get(year)!!;
    }

    public toggleItemSelection(item: ValidItem, year: number) {
        const newSet = new Set(this.selectedItemKeys);
        const key = createSelectionKey(this.getSelection(item, year));
        if (newSet.has(key)) {
            newSet.delete(key);
        } else {
            newSet.add(key);
        }
        this.selectedItemKeys = newSet;
    }

    public isItemSelected(item: ValidItem, year: number): boolean {
        const key = createSelectionKey(this.getSelection(item, year));
        return this.selectedItemKeys.has(key);
    }

    public selectAllValidItems() {
        const keys = new Set<string>();
        this.items.forEach((item) => {
            if (item.type === 'valid') {
                item.selectionMapByYear.forEach((selection) => {
                    const key = createSelectionKey(selection);
                    keys.add(key);
                });
            }
        });

        this.selectedItemKeys = keys;
    }
    // endregion


    // region Элементы для проверки
    public get control1DoublesMap(): Map<string, Array<Report2>> {
        const result = new Map<string, Array<Report2>>();
        const countMap = new Map<string, number>();

        this.selectedReports.forEach((report) => {
            const key = Control1.createKeyFromReport(report);

            let array: Array<Report2>;
            if (result.has(key)) {
                array = result.get(key)!!;
            } else {
                array = [];
                result.set(key, array);
            }

            array.push(report);
            countMap.set(key, array.length);
        });

        countMap.forEach((count, key) => {
            if (count < 2) {
                result.delete(key);
            }
        });

        countMap.clear();

        return result;
    }

    public get control1DoublesList(): Array<Array<Report2>> {
        const map = this.control1DoublesMap;
        if (map.size === 0) {
            return [];
        } else {
            return [...map.entries()]
                .sort((entry1, entry2) => {
                    const key1 = entry1[0];
                    const key2 = entry2[0];
                    if (key1 > key2) {
                        return 1;
                    } else if (key1 < key2) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                .map((entry) => (entry[1]));
        }
    }

    public get control2InvalidReports(): Array<Report2> {
        return Control2.getInvalidReports(this.selectedItems);
    }
    // endregion
}

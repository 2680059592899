


































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { ComponentBase, ListItem, ListItemPart, Overlay } from '../components';
import I18n from '../I18n';
import { BudgetVariants, Org } from '../types';


const modelChangeEvent = 'change';
const i18n = new I18n('');

const getBudgetVariantIndex = (variants: Array<BudgetVariants>, neededVariant: BudgetVariants): number | null => {
    for (let i = 0; i < variants.length; i++) {
        const testedBudgetVariant = variants[i];
        if (testedBudgetVariant.id === neededVariant.id) {
            return i;
        }
    }
    return null;
};


@Component({
    components: {
        ListItem,
        ListItemPart,
        Overlay,
    },
})
export default class SingleBudgetVariantSelection extends ComponentBase {
    constructor() {
        super('modules.budget.staffing_table.components.SingleVersionSelection');
    }


    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: BudgetVariants | null;

    @Prop({
        type: Object,
        required: true,
    })
    public org!: Org;

    @Prop({
        type: Number,
        required: false,
        default: () => null,
    })
    public year!: number | null;
    // endregion


    // region Lifecycle
    protected created() {
        super.created();

        // region Модель, свойства
        this.$watch('value', (value: BudgetVariants | null) => {
            if (value?.id !== this.selectedBudgetVariant?.id) {
                this.selectedBudgetVariant = value;
            }
        });
        this.$watch('org', (org: Org) => {
            this.reloadBudgetVariants();
        });
        this.$watch('year', (year: number) => {
            this.reloadBudgetVariants();
        });
        // endregion

        // region Выбранный вариант бюджета
        this.$watch('selectedBudgetVariant', (selectedBudgetVariant: BudgetVariants | null) => {
            if (this.value?.id !== selectedBudgetVariant?.id) {
                this.$emit(modelChangeEvent, selectedBudgetVariant);
            }
        });
        // endregion
    }

    protected mounted() {
        super.mounted();

        if (this.value?.id !== this.selectedBudgetVariant?.id) {
            this.selectedBudgetVariant = this.value;
        }

        this.reloadBudgetVariants();
    }

    // endregion


    // region Утилиты
    public getBudgetVariantText(budgetVariant: BudgetVariants): string {

        const statuses: Array<string> = [];
        if (budgetVariant.attribute) {
            statuses.push(this.i18n.translate('actual'));
        }
        if (budgetVariant.status) {
            statuses.push(this.i18n.translate('approved'));
        }

        let status: string;
        if (statuses.length > 0) {
            status = ` (${statuses.join(', ')})`;
        } else {
            status = '';
        }


        return this.localize(
            () => (`${budgetVariant.nameEn}${status}` || ''),
            () => (`${budgetVariant.nameKk}${status}`|| ''),
            () => (`${budgetVariant.nameRu}${status}`),
        )
    }
    // endregion


    // region Список вариантов бюджета
    public loading = false;

    public budgetVariants: Array<BudgetVariants> = [];

    public reloadBudgetVariants() {
        if (this.loading) {
            console.error('Cannot reload budget variants - another loading is running');
            return;
        }

        const year = this.year;
        const orgCode = this.org.org.code;
        this.loading = true;
        this.budgetVariants = [];

        const paramMap: Array<[string, unknown]> = [
            ['org-code', orgCode],
        ];
        if (year != null) {
            paramMap.push(['year', this.year]);
        }

        const url = this.prepareUrl(
            '/api/budget/staffing_table/budget-variants',
            paramMap,
        );

        Ax<Array<BudgetVariants>>(
            {
                url: url,
            },
            (data) => {
                this.budgetVariants = data;

                if (this.selectedBudgetVariant !== null) {
                    const index = getBudgetVariantIndex(this.budgetVariants, this.selectedBudgetVariant);
                    if (index === null) {
                        this.selectedBudgetVariant = null;
                    }
                }
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('cannot_load_budget_variants', [year, orgCode]),
                    error.toString(),
                );
            },
            () => {
                this.loading = false;
            },
        );
    }
    // endregion


    // region Выбранный вариант бюджета
    public selectedBudgetVariant: BudgetVariants | null = null;

    public get selectedBudgetVariantIndex(): number | null {
        if (this.selectedBudgetVariant === null) {
            return null;
        } else {
            return getBudgetVariantIndex(this.budgetVariants, this.selectedBudgetVariant);
        }
    }

    public onBudgetVariantClick(budgetVariant: BudgetVariants) {
        if (this.selectedBudgetVariant?.id === budgetVariant.id) {
            return;
        }
        this.selectedBudgetVariant = budgetVariant;
    }
    // endregion
}

import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import i18n from "@/services/i18n";
import {BudgetVariants, Org} from "@/modules/budget/staffing-table/types";
import axios from "axios";

const CommonTranslates = {
    filterPanel: {
        year: {
            get title(): string {
                return i18n.choose(
                    'Год',
                    'Жыл',
                    'Year',
                );
            },
        },
        organization: {
            get title(): string {
                return i18n.choose(
                    'Выбор организации',
                    'Ұйымды таңдау',
                    'Organization selection',
                );
            },
        },
        budget_version: {
            get title(): string {
                return i18n.choose(
                    'Выбор версии бюджета',
                    'Бюджеттік нұсқа таңдау',
                    'Budget version selection',
                );
            },
        },
        staff_tab_version: {
            get title(): string {
                return i18n.choose(
                    'Выбор версии штатного расписания',
                    'Бюджеттік нұсқа таңдау',
                    'Budget version selection',
                );
            },
        },
        budget_program: {
            get title(): string {
                return i18n.choose(
                    'Выбор бюджетной программы',
                    'Бюджет бағдарламасын таңдау',
                    'Budget Program Selection',
                );
            },
        },
    },
    buttons: {
        selection: {
            get title(): string {
                return i18n.choose(
                    'Выбрать',
                    'Таңдау',
                    'Select',
                );
            },
        },
        back: {
            get title(): string {
                return i18n.choose(
                    'Назад',
                    'Артқа',
                    'Back',
                );
            }
        }

    }
};

@Component
export class BaseTemplate extends Vue {

    // region Утилиты
    public i18n = i18n;

    // region Утилиты
    public commonTranslates = CommonTranslates;

    protected async setActualVariant(org: Org, year: number): Promise<BudgetVariants | null> {

        const orgCode = org.org.code;
        try {
            const response = await axios.get<Array<BudgetVariants>>('/api/budget/staffing_table/budget-variants',
                {
                    params: {'org-code': orgCode, 'year': year}
                });
            return response.data.find(variant => variant.attribute === true) ?? null;
        } catch (error) {
            console.error(`Error retrieving the actual variant for the year ${year}:`, error);
            return null;
        }
    }
}

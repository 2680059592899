






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';


const translates = {
    between: {
        groupTitle: internal.localized(
            '[ ⧦ ] Checking whether a value is within a range of values',
            '[ ⧦ ] Проверка вхождения значения в диапазон значений (каз)', // TODO translate
            '[ ⧦ ] Проверка вхождения значения в диапазон значений',
        ),
        items: {
            positive: internal.localized(
                '[ + ] Value IS in range',
                '[ + ] Значение ВХОДИТ в диапазон (каз)', // TODO translate
                '[ + ] Значение ВХОДИТ в диапазон',
            ),
            negative: internal.localized(
                '[ - ] Value NOT in range',
                '[ - ] Значение НЕ ВХОДИТ в диапазон (каз)', // TODO translate
                '[ - ] Значение НЕ ВХОДИТ в диапазон',
            ),
        },
    },
    booleanGroup: {
        groupTitle: internal.localized(
            '[ ≡ ] Group of conditions linked by...',
            '[ ≡ ] Группа условий, соединенных с помощью... (каз)', // TODO translate
            '[ ≡ ] Группа условий, соединенных с помощью...',
        ),
        items: {
            and: internal.localized(
                'AND',
                'И (каз)', // TODO translate
                'И',
            ),
            or: internal.localized(
                'OR',
                'ИЛИ (каз)', // TODO translate
                'ИЛИ',
            ),
        },
    },
    dataField: {
        groupTitle: internal.localized(
            '[ ⊚ ] Data fields',
            '[ ⊚ ] Поля данных (каз)', // TODO translate
            '[ ⊚ ] Поля данных',
        ),
    },
    in: {
        groupTitle: internal.localized(
            '[ ⋯ ] Checking if a value is in list of value options',
            '[ ⋯ ] Проверка наличия значения в списке вариантов значений (каз)', // TODO translate
            '[ ⋯ ] Проверка наличия значения в списке вариантов значений',
        ),
        items: {
            positive: internal.localized(
                '[ + ] Value IS in list',
                '[ + ] Значение ЕСТЬ в списке (каз)', // TODO translate
                '[ + ] Значение ЕСТЬ в списке',
            ),
            negative: internal.localized(
                '[ - ] Value NOT in list',
                '[ - ] Значения НЕТ в списке (каз)', // TODO translate
                '[ - ] Значения НЕТ в списке',
            ),
        },
    },
    isNull: {
        groupTitle: internal.localized(
            '[ ▣ ] Checking for presence of a value',
            '[ ▣ ] Проверка наличия значения (каз)', // TODO translate
            '[ ▣ ] Проверка наличия значения',
        ),
        items: {
            positive: internal.localized(
                '[ - ] There is NO value (data field is empty)',
                '[ - ] Значения НЕТ (поле данных не заполнено) (каз)', // TODO translate
                '[ - ] Значения НЕТ (поле данных не заполнено)',
            ),
            negative: internal.localized(
                '[ + ] Value is PRESENT (data field is not empty)',
                '[ + ] Значение ЕСТЬ (поле данных заполнено) (каз)', // TODO translate
                '[ + ] Значение ЕСТЬ (поле данных заполнено)',
            ),
        },
    },
    like: {
        groupTitle: internal.localized(
            '[ ∬ ] Checking whether text matches template',
            '[ ∬ ] Проверка соответствия текста шаблону (каз)', // TODO translate
            '[ ∬ ] Проверка соответствия текста шаблону',
        ),
        items: {
            positive: internal.localized(
                '[ + ] Text MATCHES template',
                '[ + ] Текст СООТВЕТСТВУЕТ шаблону (каз)', // TODO translate
                '[ + ] Текст СООТВЕТСТВУЕТ шаблону',
            ),
            negative: internal.localized(
                '[ - ] Text NOT MATCHES template',
                '[ - ] Текст НЕ СООТВЕТСТВУЕТ шаблону (каз)', // TODO translate
                '[ - ] Текст НЕ СООТВЕТСТВУЕТ шаблону',
            ),
        },
    },
    negation: {
        itemTitle: internal.localized(
            '[ ⊘ ] Negation (boolean "NO")',
            '[ ⊘ ] Отрицание (логическое "НЕТ") (каз)', // TODO translate
            '[ ⊘ ] Отрицание (логическое "НЕТ")',
        ),
    },
    newItemCreationTitle: internal.localized(
        'New item creation',
        'Создание нового элемента (каз)', // TODO translate
        'Создание нового элемента',
    ),
    noItemLabel: internal.localized(
        'No item, click to choose new item type',
        'Нет элемента, кликните для выбора типа нового элемента (каз)', // TODO translate
        'Нет элемента, кликните для выбора типа нового элемента',
    ),
    simpleValue: {
        groupTitle: internal.localized(
            '[ ⊙ ] Simple value with data type...',
            '[ ⊙ ] Простое значение с типом данных... (каз)', // TODO translate
            '[ ⊙ ] Простое значение с типом данных...',
        ),
    },
    special: {
        groupTitle: internal.localized(
            '[ ⊛ ] Special',
            '[ ⊛ ] Специальное (каз)', // TODO translate
            '[ ⊛ ] Специальное',
        ),
    },
    twoValueComparison: {
        groupTitle: internal.localized(
            '[ = ] Two value comparison using operation...',
            '[ = ] Сравнение двух значений с помощью операции... (каз)', // TODO translate
            '[ = ] Сравнение двух значений с помощью операции...',
        ),
        items: {
            equals: internal.localized(
                '[ = ] x1 equals x2',
                '[ = ] x1 равно x2 (каз)', /// TODO translate
                '[ = ] x1 равно x2',
            ),
            notEquals: internal.localized(
                '[ ≠ ] x1 not equals x2',
                '[ ≠ ] x1 не равно x2 (каз)', /// TODO translate
                '[ ≠ ] x1 не равно x2',
            ),
            greaterThan: internal.localized(
                '[ > ] x1 is greater than x2',
                '[ > ] x1 больше x2 (каз)', /// TODO translate
                '[ > ] x1 больше x2',
            ),
            greaterThanOrEquals: internal.localized(
                '[ ≥ ] x1 is greater than or equals x2',
                '[ ≥ ] x1 больше или равно x2 (каз)', /// TODO translate
                '[ ≥ ] x1 больше или равно x2',
            ),
            lesserThan: internal.localized(
                '[ < ] x1 is lesser than x2',
                '[ < ] x1 меньше x2 (каз)', /// TODO translate
                '[ < ] x1 меньше x2',
            ),
            lesserThanOrEquals: internal.localized(
                '[ ≤ ] x1 is lesser than or equals x2',
                '[ ≤ ] x1 меньше или равно x2 (каз)', /// TODO translate
                '[ ≤ ] x1 меньше или равно x2',
            ),
        },
    },
};


// region Options of new items
interface NewBetween {
    type: 'between';
    negative: boolean;
    readonly title: string;
}

const allBetweenOptions: Array<NewBetween> = [
    // Positive
    {
        type: 'between',
        negative: false,
        get title(): string {
            return translates.between.items.positive.toString();
        },
    },

    // Negative
    {
        type: 'between',
        negative: true,
        get title(): string {
            return translates.between.items.negative.toString();
        },
    },
];


interface NewBooleanGroup {
    readonly type: 'boolean-group';
    readonly operation: Index.BooleanGroup.Operation;
    readonly title: string;
}

const allBooleanGroupOptions: Array<NewBooleanGroup> = [
    // AND
    {
        type: 'boolean-group',
        operation: 'AND',
        get title(): string {
            return translates.booleanGroup.items.and.toString();
        },
    },

    // OR
    {
        type: 'boolean-group',
        operation: 'OR',
        get title(): string {
            return translates.booleanGroup.items.or.toString();
        },
    },
];


interface NewCustomValue {
    readonly type: 'custom-value';
    readonly selector: Index.CustomValueSelector;
}


interface NewIn {
    type: 'in';
    negative: boolean;
    readonly title: string;
}

const allInOptions: Array<NewIn> = [
    // Positive
    {
        type: 'in',
        negative: false,
        get title(): string {
            return translates.in.items.positive.toString();
        },
    },

    // Negative
    {
        type: 'in',
        negative: true,
        get title(): string {
            return translates.in.items.negative.toString();
        },
    },
];


interface NewIsNull {
    type: 'is-null';
    negative: boolean;
    readonly title: string;
}

const allIsNullOptions: Array<NewIsNull> = [
    // Negative
    {
        type: 'is-null',
        negative: true,
        get title(): string {
            return translates.isNull.items.negative.toString();
        },
    },

    // Positive
    {
        type: 'is-null',
        negative: false,
        get title(): string {
            return translates.isNull.items.positive.toString();
        },
    },
];


interface NewLike {
    type: 'like';
    negative: boolean;
    title: string;
}

const allLikeOptions: Array<NewLike> = [
    // Positive
    {
        type: 'like',
        negative: false,
        get title(): string {
            return translates.like.items.positive.toString();
        },
    },

    // Negative
    {
        type: 'like',
        negative: true,
        get title(): string {
            return translates.like.items.negative.toString();
        },
    },
];


interface NewNot {
    type: 'not';
}

const notOption: NewNot = { type: 'not', };


interface NewParameter {
    readonly type: 'parameter';
    readonly dataType: keyof Index.DataTypes;
    readonly title: string;
}

const allParameterOptions: Array<NewParameter> = [
    // 'boolean'
    {
        type: 'parameter',
        dataType: 'boolean',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },

    // 'string'
    {
        type: 'parameter',
        dataType: 'string',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },

    // 'number'
    {
        type: 'parameter',
        dataType: 'number',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },

    // 'date'
    {
        type: 'parameter',
        dataType: 'date',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },

    // 'time'
    {
        type: 'parameter',
        dataType: 'time',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },

    // 'date-time'
    {
        type: 'parameter',
        dataType: 'date-time',
        get title(): string {
            return index.dataTypeNames[this.dataType];
        }
    },
];


interface NewTwoValueComparison {
    readonly type: 'two-value-comparison';
    readonly operation: Index.TwoValueComparison.Operation;
    readonly title: string;
}

const allTwoValueComparisonOptions: Array<NewTwoValueComparison> = [
    // 'equals'
    {
        type: 'two-value-comparison',
        operation: 'equals',
        get title(): string {
            return translates.twoValueComparison.items.equals.toString();
        },
    },

    // 'not-equals'
    {
        type: 'two-value-comparison',
        operation: 'not-equals',
        get title(): string {
            return translates.twoValueComparison.items.notEquals.toString();
        },
    },

    // 'greater-than'
    {
        type: 'two-value-comparison',
        operation: 'greater-than',
        get title(): string {
            return translates.twoValueComparison.items.greaterThan.toString();
        },
    },

    // 'greater-than-or-equals'
    {
        type: 'two-value-comparison',
        operation: 'greater-than-or-equals',
        get title(): string {
            return translates.twoValueComparison.items.greaterThanOrEquals.toString();
        },
    },

    // 'lesser-than'
    {
        type: 'two-value-comparison',
        operation: 'lesser-than',
        get title(): string {
            return translates.twoValueComparison.items.lesserThan.toString();
        },
    },

    // 'lesser-than-or-equals'
    {
        type: 'two-value-comparison',
        operation: 'lesser-than-or-equals',
        get title(): string {
            return translates.twoValueComparison.items.lesserThanOrEquals.toString();
        },
    },
];


type NewItem = Index.CustomField<unknown> | NewBetween | NewBooleanGroup | NewCustomValue | NewIn | NewIsNull | NewLike | NewNot | NewParameter | NewTwoValueComparison;
// endregion


const eventNames = {
    created: 'created',
};


@Component
export default class NewItemButton extends Vue {
    // region Модель, свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Array,
        required: true,
    })
    public dataTypes!: Array<string>;

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public label!: string | null;
    // endregion


    // region Lifecycle
    protected created() {
        // region Разное (watch)
        this.$watch('newItem', (newItem: NewItem | null) => {
            if (newItem !== null) {
                this.$nextTick(() => {
                    this.newItem = null;
                });

                this.createItem(newItem);
            }
        });
        // endregion
    }
    // endregion


    // region Разное
    public translates = translates;

    public get actualLabel(): string {
        const label = this.label;
        if (label === null) {
            return this.translates.noItemLabel.toString();
        } else {
            return label;
        }
    }

    public index = index;
    public internal = internal;

    public get restrictedByDataTypes(): boolean {
        return (this.dataTypes.length > 0);
    }

    public get dataTypeSet(): Set<string> {
        return new Set(this.dataTypes);
    }

    public get booleanDataTypeAllowed(): boolean {
        return (!this.restrictedByDataTypes) || (this.dataTypeSet.has(index.dataTypes.boolean));
    }
    // endregion


    // region Создание элемента
    public newItem: NewItem | null = null;


    public createItem(newItem: NewItem) {
        const type = newItem.type;
        switch (newItem.type) {
            case 'between':
                this.createBetween(newItem);
                break;
            case 'boolean-group':
                this.createBooleanGroup(newItem);
                break;
            case 'custom-field':
                this.createCustomField(newItem);
                break;
            case 'custom-value':
                this.createCustomValue(newItem);
                break;
            case 'in':
                this.createIn(newItem);
                break;
            case 'is-null':
                this.createIsNull(newItem);
                break;
            case 'like':
                this.createLike(newItem);
                break;
            case 'not':
                this.createNot();
                break;
            case 'parameter':
                this.createParameter(newItem);
                break;
            case 'two-value-comparison':
                this.createTwoValueComparison(newItem);
                break;
            default:
                console.error(`Unknown type of new item - "${type}"`);
                break;
        }
    }

    public onItemCreated(item: Internal.Item, expanded: boolean = true) {
        item.expanded = expanded;
        this.$emit(eventNames.created, item);
    }


    public betweenOptions = allBetweenOptions;

    public createBetween(newItem: NewBetween) {
        const item = internal.createBetweenFromReady(this.config, undefined);
        item.negative = newItem.negative;
        this.onItemCreated(item);
    }


    public booleanGroupOptions = allBooleanGroupOptions;

    public createBooleanGroup(newItem: NewBooleanGroup) {
        const item = internal.createBooleanGroupFromReady(this.config);
        item.operation = newItem.operation;
        this.onItemCreated(item);
    }


    public get customFieldOptions(): Array<Index.CustomField<unknown>> {
        const config = this.config;
        if (config === null) {
            return [];
        }

        const customFields = config.customFields;
        if ((customFields === undefined) || (customFields === null) || (customFields.length === 0)) {
            return [];
        }

        if (this.restrictedByDataTypes) {
            return customFields.filter((customField) => {
                return this.dataTypeSet.has(customField.dataType);
            });
        } else {
            return customFields;
        }
    }

    public createCustomField(newItem: Index.CustomField<unknown>) {
        const item = internal.createCustomFieldFromReady(this.config, undefined, newItem.key);
        this.onItemCreated(item, false);
    }


    public get customValueOptions(): Array<NewCustomValue> {
        const config = this.config;
        if (config === null) {
            return [];
        }

        let customValueSelectors = config.customValueSelectors;
        if ((customValueSelectors === undefined) || (customValueSelectors === null) || (customValueSelectors.length === 0)) {
            return [];
        }

        if (this.restrictedByDataTypes && (customValueSelectors.length > 0)) {
            customValueSelectors = customValueSelectors.filter((option) => {
                return this.dataTypeSet.has(option.dataType);
            });
        }

        if (customValueSelectors.length === 0) {
            return [];
        } else {
            return customValueSelectors.map((selector): NewCustomValue => ({
                type: 'custom-value',
                selector,
            }));
        }
    }

    public createCustomValue(newItem: NewCustomValue) {
        const item = internal.createCustomValueFromReady(this.config, undefined, newItem.selector.key);
        this.onItemCreated(item);
    }


    public inOptions = allInOptions;

    public createIn(newItem: NewIn) {
        const item = internal.createInFromReady(this.config, undefined);
        item.negative = newItem.negative;
        this.onItemCreated(item);
    }


    public isNullOptions = allIsNullOptions;

    public createIsNull(newItem: NewIsNull) {
        const item = internal.createIsNullFromReady(this.config);
        item.negative = newItem.negative;
        this.onItemCreated(item);
    }


    public allLikeOptions = allLikeOptions;

    public createLike(newItem: NewLike) {
        const item = internal.createLikeFromReady(this.config);
        item.negative = newItem.negative;
        this.onItemCreated(item);
    }


    public notOption = notOption;

    public createNot() {
        const item = internal.createNotFromReady(this.config);
        this.onItemCreated(item);
    }


    public get parameterOptions(): Array<NewParameter> {
        if (this.restrictedByDataTypes) {
            return allParameterOptions.filter((option) => {
                return this.dataTypeSet.has(option.dataType);
            });
        } else {
            return allParameterOptions;
        }
    }

    public createParameter(newItem: NewParameter) {
        const item = internal.createParameterFromReady(this.config);
        item.dataType = newItem.dataType;
        this.onItemCreated(item);
    }


    public twoValueComparisonOptions = allTwoValueComparisonOptions;

    public createTwoValueComparison(newItem: NewTwoValueComparison) {
        const item = internal.createTwoValueComparisonFromReady(this.config);
        item.operation = newItem.operation;
        this.onItemCreated(item);
    }
    // endregion
}

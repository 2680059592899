import Vue from 'vue';
import i18n from '@/services/i18n';
import Builder from './Builder.vue';


const translate = (
    en: string,
    kk: string,
    ru: string,
): string => {
    const locale = i18n.locale.trim().toLowerCase();
    switch (locale) {
        case 'en':
            return en;
        case 'kk':
            return kk;
        default:
            return ru;
    }
};


// region Config
export interface Config {
    dataTypeNames?: Partial<Record<string, string>> | null;
    customFields?: Array<CustomField<unknown>> | null;
    customValueSelectors?: Array<CustomValueSelector> | null;
}
// endregion


// region Data types
export interface DataTypes {
    boolean: 'boolean';
    string: 'string';
    number: 'number';
    date: 'date';
    time: 'time';
    'date-time': 'date-time';
    unknown: 'unknown';
}

export const dataTypes: DataTypes = {
    boolean: 'boolean',
    string: 'string',
    number: 'number',
    date: 'date',
    time: 'time',
    'date-time': 'date-time',
    unknown: 'unknown',
};

// TODO translate
export const dataTypeNames: Record<keyof DataTypes, string> = Vue.observable({
    get boolean(): string {
        return translate(
            'Yes/no',
            'Да/нет (каз)', // TODO translate
            'Да/нет',
        );
    },
    get string(): string {
        return translate(
            'Text',
            'Текст (каз)', // TODO translate
            'Текст'
        );
    },
    get number(): string {
        return translate(
            'Number',
            'Число (каз)', // TODO translate
            'Число',
        );
    },
    get date(): string {
        return translate(
            'Date',
            'Дата (каз)', // TODO translate
            'Дата',
        );
    },
    get time(): string {
        return translate(
            'Time',
            'Время (каз)', // TODO translate
            'Время',
        );
    },
    get 'date-time'(): string {
        return translate(
            'Date & time',
            'Дата и время (каз)', // TODO translate
            'Дата и время',
        );
    },
    get unknown(): string {
        return translate(
            'Unknown',
            'Неизвестный (каз)', // TODO translate
            'Неизвестный',
        );
    },
});

export const getDataTypeName = (config: Config | null | undefined, dataType: string): string => {
    const knownDataTypeName = (dataTypeNames[dataType as (keyof DataTypes)] as unknown as (string | undefined));
    if (knownDataTypeName !== undefined) {
        return knownDataTypeName;
    }

    if ((config !== undefined) && (config !== null)) {
        const customDataTypeNames = config.dataTypeNames;
        if ((customDataTypeNames !== undefined) && (customDataTypeNames !== null)) {
            const customDataTypeName = customDataTypeNames[dataType];
            // noinspection SuspiciousTypeOfGuard
            if (typeof customDataTypeName === 'string') {
                return customDataTypeName;
            }
        }
    }

    return dataType;
};
// endregion


// region Custom item utils
export interface CustomValueSelector {
    key: string;
    dataType: string;
    title: string;
    serializeValue(value: unknown): unknown;
    deserializeValue(value: unknown): unknown;
}

export interface CustomValueSlot {
    type: 'custom-value';
    key: string;
    readonly: boolean;
    value: unknown;
    setValue: (value: unknown) => void;
}
// endregion


// region Items - base
export interface ItemBase {
    readonly type: string;
}

export type Item =
    // Items - boolean expressions
    Between<unknown> | BooleanGroup | In<unknown> | IsNull | Like | Not | TwoValueComparison<unknown>
    |
    // Items - other
    CustomField<unknown> | CustomValue<unknown> | Parameter<unknown>
;


export interface ExpressionBase<T> extends ItemBase {
    dataType: string;
}

export type Expression<T> = ExpressionBase<T> & Item;
// endregion


// region Items - boolean expressions
export interface Between<T> extends ExpressionBase<boolean> {
    type: 'between';
    dataType: 'boolean';
    negative: boolean;
    target: Expression<T>;
    rangeStart: Expression<T>;
    rangeEnd: Expression<T>;
}


export namespace BooleanGroup {
    export type Operation = 'AND' | 'OR';
}

export interface BooleanGroup extends ExpressionBase<boolean> {
    readonly type: 'boolean-group';
    readonly dataType: 'boolean';
    operation: BooleanGroup.Operation;
    items: Array<Expression<boolean>>;
}


export interface In<T> extends ExpressionBase<boolean> {
    type: 'in';
    dataType: 'boolean';
    negative: boolean;
    target: Expression<T>
    valueVariants: Array<Expression<T>>;
}


export interface IsNull extends ExpressionBase<boolean> {
    type: 'is-null';
    dataType: 'boolean';
    negative: boolean;
    item: Expression<unknown>;
}


export interface Like extends ExpressionBase<boolean> {
    type: 'like';
    dataType: 'boolean';
    negative: boolean;
    tested: Expression<string>;
    template: Expression<string>;
}


export interface Not extends ExpressionBase<boolean> {
    type: 'not';
    dataType: 'boolean';
    item: Expression<unknown>;
}


export namespace TwoValueComparison {
    export type Operation = 'equals' | 'not-equals' | 'greater-than' | 'greater-than-or-equals' | 'lesser-than' | 'lesser-than-or-equals';
}

export interface TwoValueComparison<T> extends ExpressionBase<boolean> {
    type: 'two-value-comparison';
    dataType: 'boolean';
    first: Expression<T>;
    operation: TwoValueComparison.Operation;
    second: Expression<T>;
}
// endregion


// region Items - other
export interface CustomField<T> extends ExpressionBase<T> {
    readonly type: 'custom-field';
    readonly dataType: string;
    readonly key: string;
    readonly title: string;
}


export interface CustomValue<T> extends ExpressionBase<T> {
    type: 'custom-value';
    selectorKey: string;
    value: T;
}


export interface Parameter<T> extends ExpressionBase<T> {
    readonly type: 'parameter';
    value: T;
}
// endregion


// region Serialize, prepare for backend
/**
 * @example `0000-00-00`
 */
export const serializeDate = (date: Date): string => {
    let result: string;
    let string: string;

    string = date.getFullYear().toString();
    switch (string.length) {
        case 1:
            result = '000' + string;
            break;
        case 2:
            result = '00' + string;
            break;
        case 3:
            result = '0' + string;
            break;
        default:
            result = string;
            break;
    }
    result = string;

    result += '-';

    string = (date.getMonth() + 1).toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    result += '-';

    string = date.getDate().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    return result;
};

/**
 * @example `00:00:00.000`
 */
export const serializeTime = (date: Date): string => {
    let result: string;
    let string: string;

    string = date.getHours().toString();
    if (string.length === 2) {
        result = string;
    } else {
        result = '0' + string;
    }

    result += ':';

    string = date.getMinutes().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    result += ':';

    string = date.getSeconds().toString();
    if (string.length === 2) {
        result += string;
    } else {
        result += '0' + string;
    }

    result += '.';

    string = date.getMilliseconds().toString();
    switch (string.length) {
        case 1:
            result += '00' + string;
            break;
        case 2:
            result += '0' + string;
            break;
        default:
            result += string;
            break;
    }

    return result;
};

/**
 * @example `0000-00-00 00:00:00.000`
 */
export const serializeDateTime = (date: Date): string => {
    return serializeDate(date) + ' ' + serializeTime(date);
};

export const serializeSimpleValue = (dataType: string, value: unknown): boolean | number | string => {
    switch (dataType) {
        case 'boolean':
            if (typeof value === 'boolean') {
                return value;
            } else {
                throw new Error(`Cannot prepare value - data type is "boolean", value must have type "boolean", but its type is "${typeof value}"`);
            }
        case 'string':
            if (typeof value === 'string') {
                return value;
            } else {
                throw new Error(`Cannot prepare value - data type is "string", value must have type "string", but its type is "${typeof value}"`);
            }
        case 'number':
            if (typeof value === 'number') {
                if (Number.isFinite(value)) {
                    return value;
                } else {
                    throw new Error(`Cannot prepare value - data type is "number", but value is not finite (${value})`);
                }
            } else {
                throw new Error(`Cannot prepare value - data type is "number", value must have type "number", but its type is "${typeof value}"`);
            }
        case 'date':
            if (value instanceof Date) {
                return serializeDate(value);
            } else {
                throw new Error(`Cannot prepare value - data type is "date", value must be instance of Date, but it is ${value}`);
            }
        case 'time':
            if (value instanceof Date) {
                return serializeTime(value);
            } else {
                throw new Error(`Cannot prepare value - data type is "time", value must be instance of Date, but it is ${value}`);
            }
        case 'date-time':
            if (value instanceof Date) {
                return serializeDateTime(value);
            } else {
                throw new Error(`Cannot prepare value - data type is "date-time", value must be instance of Date, but it is ${value}`);
            }
        default:
            throw new Error(`Cannot prepare value - met unexpected data type "${dataType}"`);
    }
};

const backendPreparers = {
    'between': (selectorMapByKey: Map<string, CustomValueSelector>, item: Between<unknown>): Object => ({
        type: 'between',
        negative: item.negative,
        target: _prepareForBackend(selectorMapByKey, item.target),
        rangeStart: _prepareForBackend(selectorMapByKey, item.rangeStart),
        rangeEnd: _prepareForBackend(selectorMapByKey, item.rangeEnd),
    }),
    'boolean-group': (selectorMapByKey: Map<string, CustomValueSelector>, item: BooleanGroup): Object => ({
        type: 'boolean-group',
        operation: item.operation,
        items: item.items.map((subitem) => (_prepareForBackend(selectorMapByKey, subitem))),
    }),
    'custom-field': (item: CustomField<unknown>): Object => ({
        type: 'custom-field',
        key: item.key,
    }),
    'custom-value': (selectorMapByKey: Map<string, CustomValueSelector>, item: CustomValue<unknown>): Object => {
        const selector = selectorMapByKey.get(item.selectorKey);
        if (selector === undefined) {
            throw new Error(`Cannot prepare custom value - no custom value selector with key "${item.selectorKey}"`);
        }

        return {
            type: 'custom-value',
            selectorKey: item.selectorKey,
            value: selector.serializeValue(item.value),
        };
    },
    'in': (selectorMapByKey: Map<string, CustomValueSelector>, item: In<unknown>): Object => ({
        type: 'in',
        negative: item.negative,
        target: _prepareForBackend(selectorMapByKey, item.target),
        valueVariants: item.valueVariants.map((valueVariant) => (_prepareForBackend(selectorMapByKey, valueVariant))),
    }),
    'is-null': (selectorMapByKey: Map<string, CustomValueSelector>, item: IsNull): Object => ({
        type: 'is-null',
        negative: item.negative,
        item: _prepareForBackend(selectorMapByKey, item.item),
    }),
    'like': (selectorMapByKey: Map<string, CustomValueSelector>, item: Like): Object => ({
        type: 'like',
        negative: item.negative,
        tested: _prepareForBackend(selectorMapByKey, item.tested),
        template: _prepareForBackend(selectorMapByKey, item.template),
    }),
    'not': (selectorMapByKey: Map<string, CustomValueSelector>, item: Not): Object => ({
        type: 'not',
        item: _prepareForBackend(selectorMapByKey, item.item),
    }),
    parameter: (item: Parameter<unknown>): Object => ({
        type: 'parameter',
        dataType: item.dataType,
        value: serializeSimpleValue(item.dataType, item.value),
    }),
    'two-value-comparison': (selectorMapByKey: Map<string, CustomValueSelector>, item: TwoValueComparison<unknown>): Object => ({
        type: 'two-value-comparison',
        operation: item.operation,
        first: _prepareForBackend(selectorMapByKey, item.first),
        second: _prepareForBackend(selectorMapByKey, item.second),
    }),
};

const _prepareForBackend = (selectorMapByKey: Map<string, CustomValueSelector>, item: Item): Object => {
    const type = item.type;
    switch (item.type) {
        case 'between':
            return backendPreparers['between'](selectorMapByKey, item);
        case 'boolean-group':
            return backendPreparers['boolean-group'](selectorMapByKey, item);
        case 'custom-field':
            return backendPreparers['custom-field'](item);
        case 'custom-value':
            return backendPreparers['custom-value'](selectorMapByKey, item);
        case 'in':
            return backendPreparers['in'](selectorMapByKey, item);
        case 'is-null':
            return backendPreparers['is-null'](selectorMapByKey, item);
        case 'like':
            return backendPreparers['like'](selectorMapByKey, item);
        case 'not':
            return backendPreparers['not'](selectorMapByKey, item);
        case 'parameter':
            return backendPreparers.parameter(item);
        case 'two-value-comparison':
            return backendPreparers['two-value-comparison'](selectorMapByKey, item);
        default:
            throw new Error(`Cannot prepare for backend - unknown item type "${type}"`);
    }
};

// noinspection JSUnusedGlobalSymbols
export const prepareForBackend = (item: Item, customValueSelectors?: Array<CustomValueSelector> | null): Object => {
    const selectorMapByKey = new Map<string, CustomValueSelector>(
        (customValueSelectors ?? [])
            .map((selector) => [selector.key, selector])
    );

    return _prepareForBackend(selectorMapByKey, item);
};
// endregion


export { Builder };




























































































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { CollapsiblePane, SvgIcon } from '../index';
import BoolExItemRenderer from './part/ItemRenderer.vue';
import NewItemButton from './part/NewItemButton.vue';
import type * as Index from './index';
import * as index from './index';
import type * as Internal from './internal';
import * as internal from './internal';


interface State {
    readonly valid: boolean;
    readonly text: Internal.Localized;
    readonly color: string;
    readonly svgIconPath: string;
}


const eventNames = {
    change: 'change',
};

const translates = {
    export: {
        description: internal.localized(
            'In the field below - the contents of the condition in text form. To export, copy and save this text to some text file, for example, using applications "Notepad", "WordPad" or "Word". The condition saved in this way can then be restored using the "Import" button',
            'В поле ниже - содержимое условия в виде текста. Для экспорта скопируйте и сохраните этот текст в какой-нибудь текстовый файл, например, с помощью приложений "Блокнот", "WordPad" или "Word". Сохраненное таким образом условие потом можно будет восстановить с помощью кнопки "Импорт" (каз)', // TODO translate
            'В поле ниже - содержимое условия в виде текста. Для экспорта скопируйте и сохраните этот текст в какой-нибудь текстовый файл, например, с помощью приложений "Блокнот", "WordPad" или "Word". Сохраненное таким образом условие потом можно будет восстановить с помощью кнопки "Импорт"',
        ),
        title: internal.localized(
            'Export',
            'Экспорт (каз)', // TODO translate
            'Экспорт',
        ),
    },
    import: {
        cancel: internal.localized(
            'Cancel',
            'Отмена (каз)', // TODO translate
            'Отмена',
        ),
        description: {
            line1: internal.localized(
                'To import a condition, you need to insert the text of the condition in the field below, which was previously obtained using the "Export" button',
                'Для импорта условия надо в поле ниже вставить текст условия, который ранее был получен с помощью кнопки "Экспорт" (каз)', // TODO translate
                'Для импорта условия надо в поле ниже вставить текст условия, который ранее был получен с помощью кнопки "Экспорт"',
            ),
        },
        errorWhileParsingJson(e: unknown): { toString(): string; } {
            return internal.localized(
                `Error while reading text: ${String(e)}`,
                `Ошибка чтения текста: ${String(e)} (каз)`, // TODO translate
                `Ошибка чтения текста: ${String(e)}`,
            );
        },
        ok: internal.localized(
            'Import',
            'Импортировать (каз)', // TODO translate
            'Импортировать',
        ),
        parsedValueReady: internal.localized(
            'The condition was read from the imported data. Continue importing it?',
            'Условие прочитано из импортируемых данных. Продолжить его импорт? (каз)', // TODO translate
            'Условие прочитано из импортируемых данных. Продолжить его импорт?',
        ),
        title: internal.localized(
            'Import',
            'Импорт (каз)', // TODO translate
            'Импорт',
        ),
        wasErrors: internal.localized(
            'There are errors in the imported data. Some items may be changed or deleted, please check the condition after importing',
            'В импортируемых данных есть ошибки. Некоторые элементы могут быть изменены или удалены, после импорта проверьте условие (каз)', // TODO translate
            'В импортируемых данных есть ошибки. Некоторые элементы могут быть изменены или удалены, после импорта проверьте условие',
        ),
    },
    states: {
        noValue: internal.localized(
            'No condition is set',
            'Условие не установлено (каз)', // TODO translate
            'Условие не установлено',
        ),
        unexpectedValueTypeState(config: Index.Config | null | undefined, value: Internal.Expression): State {
            const getDataTypeNames = (): { booleanDataTypeName: string, valueDataTypeName: string } => {
                const booleanDataTypeName = index.getDataTypeName(config, index.dataTypes.boolean);
                const valueDataTypeName = index.getDataTypeName(config, value.dataType);
                return { booleanDataTypeName, valueDataTypeName };
            };

            return {
                valid: false,
                text: internal.localized(
                    (): string => {
                        const { booleanDataTypeName, valueDataTypeName } = getDataTypeNames();
                        return `Condition has data type "${valueDataTypeName}", but its data type must be "${booleanDataTypeName}"`;
                    },
                    (): string => {
                        const { booleanDataTypeName, valueDataTypeName } = getDataTypeNames();
                        return `У условия тип данных "${valueDataTypeName}", а должен быть "${booleanDataTypeName}" (каз)`; // TODO translate
                    },
                    (): string => {
                        const { booleanDataTypeName, valueDataTypeName } = getDataTypeNames();
                        return `У условия тип данных "${valueDataTypeName}", а должен быть "${booleanDataTypeName}"`;
                    },
                ),
                color: '#DC3545',
                svgIconPath: internal.icons['alert-decagram'],
            };
        },
        valueExists: internal.localized(
            'Condition is set',
            'Условие установлено (каз)', // TODO translate
            'Условие установлено',
        ),
        valueHasErrors: internal.localized(
            'Condition has error',
            'В условии есть ошибка (каз)', // TODO translate
            'В условии есть ошибка'
        ),
    },
};

const states = {
    noValue: {
        valid: true,
        text: translates.states.noValue,
        color: '#7C9DB3',
        svgIconPath: internal.icons.circle,
    },
    valueExists: Vue.observable<State>({
        valid: true,
        text: translates.states.valueExists,
        color: '#01AC50',
        svgIconPath: internal.icons['check-circle'],
    }),
    valueHasErrors: Vue.observable<State>({
        valid: false,
        text: translates.states.valueHasErrors,
        color: '#DC3545',
        svgIconPath: internal.icons['alert-decagram'],
    }),
};


@Component({
    components: {
        BoolExItemRenderer,
        CollapsiblePane,
        NewItemButton,
        SvgIcon,
    },
})
export default class Builder extends Vue {
    // region Модель, свойства
    @Model(eventNames.change, {
        type: Object,
        required: false,
        default: () => null,
    })
    public value!: Index.Expression<boolean> | null;

    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Boolean,
        required: false,
        default: () => false,
    })
    public readonly!: boolean;
    // endregion


    // region Lifecycle
    public created() {
        // region Модель, свойства (watch)
        this.$watch('value', (value: Index.Expression<boolean> | null) => {
            if (this.localValue !== value) {
                this.localValue = value;
            }
        });
        this.$watch('config', (config: Index.Config | null) => {
            this.internalValue?.changeConfig(config);
        });
        // endregion

        // region Значение (watch)
        this.$watch('localValue', (localValue: Index.Expression<boolean> | null) => {
            if (this.value !== localValue) {
                this.$emit(eventNames.change, localValue);
            }
            this.localToInternal();
        });
        this.$watch('internalValue', (/* internalValue: Internal.Expression<boolean> | null */) => {
            this.internalToLocal();
        });
        this.$watch('hashParts', (/* hashParts: Array<unknown> | null */) => {
            setTimeout(() => {
                this.internalToLocal();
            });
        });
        // endregion

        // region Импорт
        this.$watch('importTextValue', (/* importTextValue: string */) => {
            this.importTryParse();
        });
        // endregion
    }

    public mounted() {
        if (this.localValue !== this.value) {
            this.localValue = this.value;
        }
        this.localToInternal();
    }
    // endregion


    // region Утилиты
    public index = index;
    public internal = internal;
    public translates = translates;

    public noValueText = internal.localized(
        'No value',
        'Нет значения (каз)', // TODO translate
        'Нет значения',
    );

    public netItemButtonLabel = internal.localized(
        'No condition is set, click to create one',
        'Условие не установлено, кликните для создания (каз)', // TODO translate
        'Условие не установлено, кликните для создания',
    );

    public checkConfig() {
        const config = this.config;
        if (config !== null) {
            const customFields = config.customFields;
            if ((customFields !== null) && (customFields !== undefined)) {
                const indexByNameMap = new Map<string, number>();
                customFields.forEach((customField, customFieldIndex) => {
                    const key = customField.key;

                    const existentFieldIndex = (indexByNameMap.get(key) as (number | undefined));
                    if (existentFieldIndex !== undefined) {
                        const existentField = customFields[existentFieldIndex];
                        console.error(
                            `Found custom fields with same key "${key}"`,
                            `Field #${existentFieldIndex}:`, existentField,
                            `Field #${customFieldIndex}:`, customField,
                        );
                        throw new Error(`Custom fields with indices #${existentFieldIndex} and #${customFieldIndex} has same key "${key}"`);
                    }

                    indexByNameMap.set(key, customFieldIndex);
                });
            }
        }
    }
    // endregion


    // region Значение
    public localValue: Index.Expression<boolean> | null = null;

    public internalValue: Internal.Expression | null = null;

    public get hashParts(): Array<unknown> | null {
        const internalValue = this.internalValue;
        if (internalValue === null) {
            return null;
        }

        const result: Array<unknown> = [];
        internalValue.collectHashParts(result);
        return result;
    }

    public get state(): State {
        // Разные проверки
        this.checkConfig();


        const internalValue = this.internalValue;
        if (internalValue === null) {
            return states.noValue;
        }

        if (internalValue.dataType !== this.index.dataTypes.boolean) {
            return translates.states.unexpectedValueTypeState(this.config, internalValue);
        }

        if (internalValue.error !== null) {
            return states.valueHasErrors;
        }

        return states.valueExists;
    }

    public localToInternal(): void {
        const localValue = (this.localValue as (null | Index.Item));
        const internalValue = this.internalValue;
        if (localValue === null) {
            this.internalValue = null;
        } else {
            if ((internalValue === null) || (!internalValue.equalsTo(localValue))) {
                this.internalValue = internal.createInternalFromReady<Internal.Expression>(this.config, localValue);
            }
        }
    }

    public internalToLocal(): void {
        const localValue = (this.localValue as (null | Index.Item));
        const internalValue = this.internalValue;
        if (internalValue === null) {
            if (localValue !== null) {
                this.localValue = null;
            }
        } else if ((internalValue.error === null) && (internalValue.dataType === this.index.dataTypes.boolean)) {
            if ((localValue === null) || (!internalValue.equalsTo(localValue))) {
                this.localValue = internalValue.createReadyItem() as Index.Expression<boolean>;
            }
        }
    }

    public onItemCreated(item: unknown) {
        this.internalValue = (item as Internal.Expression);
    }

    public onDelete() {
        this.internalValue = null;
    }
    // endregion


    // region Экспорт
    public exportModalVisible = false;

    public get exportedText(): string | null {
        if (this.exportModalVisible) {
            const internalValue = this.internalValue;
            if (internalValue === null) {
                return null;
            } else {
                const serialized = internalValue.serialize();
                return JSON.stringify(serialized, undefined, 2);
            }
        } else {
            return null;
        }
    }
    // endregion


    // region Импорт
    public importModalVisible = false;

    public importTextValue = '';
    public importParsedValue: Internal.Expression | null = null;
    public importError: unknown = null;

    public importTryParse() {
        const text = this.importTextValue.trim();
        if (text.length === 0) {
            this.importError = null;
            this.importParsedValue = null;
        } else {
            let serialized: Record<string, unknown>;
            try {
                serialized = JSON.parse(text);
            } catch (e) {
                console.error('Error while parsing text as JSON', e);
                this.importError = translates.import.errorWhileParsingJson(e);
                this.importParsedValue = null;
                return;
            }

            const { wasErrors, value: deserialized } = internal.deserialize<Internal.Expression>(
                this.config,
                serialized,
            );

            if (wasErrors) {
                this.importError = translates.import.wasErrors;
            } else {
                this.importError = null;
            }
            this.importParsedValue = deserialized;
        }
    }

    public startImport() {
        this.importTextValue = '';
        this.importModalVisible = true;
    }

    public doImport() {
        const importParsedValue = this.importParsedValue;
        if (importParsedValue !== null) {
            this.internalValue = importParsedValue;
        }
    }
    // endregion
}

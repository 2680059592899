










































import {ComponentBase, Overlay} from "@/modules/budget/staffing-table/components";
import {Model, Prop} from "vue-property-decorator";
import {Comp, Dict, Report} from "@/modules/budget/staffing-table/types";
import i18n from "@/services/i18n";
import * as common from "@/modules/budget/staffing-table/common";
import DropdownItemDef = Comp.DropdownItemDef;
import Component from "vue-class-component";

const Translates = {
    optionFields: {
        title: {
            get title(): string {
                return i18n.choose(
                    'Выбор формы',
                    'Нысанды таңдау',
                    'Form Selection',
                );
            },
        },
    },
};

const modelChangeEvent = 'change';
interface ExtendedDropdownItemDef<T> extends DropdownItemDef<T> {
    disabled: boolean
}

@Component({
    components: {
        Overlay
    },
})
export default class MultiSelectionForm extends ComponentBase {
    constructor() {
        super('');
    }

    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Array,
            required: true,
        }
    )
    public selectedOptionsForms!: Report.FormKey[];


    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly selectedSpecificOptions!: Array<Dict.EbkEk>;


    @Prop({
        type: Number,
        required: true,
    })
    public readonly reportYear!: number;
    // region

    @Prop({
        type: Boolean,
        required: false,
        default: true
    })
    public readonly useSpecific!: boolean;


    public translates = Translates
    public loading = false;
    public specificityToReportFormKeyMap = common.specificityToReportFormKeyMap;

    public localSelectedOptionsForms: Report.FormKey[] = []

    protected created() {
        super.created();
        if (this.selectedOptionsForms.isNotEmpty) {
            this.localSelectedOptionsForms = this.selectedOptionsForms
        }

        this.$watch('localSelectedOptionsForms', () => {
            this.$emit(modelChangeEvent, this.localSelectedOptionsForms);
        })

        this.$watch('selectedSpecificOptions', () => {
            this.localSelectedOptionsForms = []
        })
    }

    protected mounted() {
        super.mounted();
    }

    public addFormTag(from: Report.FormKey) {
        if (!this.localSelectedOptionsForms.includes(from)) {
            this.localSelectedOptionsForms.push(from)
        }
    }

    public onClick(value: Report.FormKey) {
        this.addFormTag(value);
    }

    public unselect(form: Report.FormKey) {
        const index = this.localSelectedOptionsForms.indexOf(form);
        if (index >= 0) {
            this.localSelectedOptionsForms.splice(index, 1);
        }
    }

    public isSelected(form: string): boolean {
        return this.localSelectedOptionsForms.some(option => {
            return option === form;
        });
    }


    public get formOptions(): Array<ExtendedDropdownItemDef<Report.FormKey>> {
        const result: Array<ExtendedDropdownItemDef<Report.FormKey>> = [];
        const forms: Report.FormKey[] = []

        const selectedSpecificities = this.selectedSpecificOptions.map(item => (item.spf))

        if (this.useSpecific) {
            selectedSpecificities.forEach((specific) => {
                if (specific != null) {
                    const formKeys = this.specificityToReportFormKeyMap.get(specific);
                    if (formKeys) {
                        forms.push(...formKeys);
                    }
                }
            })

            // noinspection JSIncompatibleTypesComparison
            if (forms.isNotEmpty) {
                forms.forEach((form) => {
                    let code = String(form);
                    if (code.toUpperCase().startsWith('F_')) {
                        code = code.substring(2);
                    }
                    code = code.replaceAll('_', '-');

                    const disabled = this.isSelected(code)

                    result.push({
                        text: code,
                        value: form,
                        disabled: disabled
                    });
                });
            }
        } else {
            const allForms = Array.from(this.specificityToReportFormKeyMap.values()).flat();
            allForms.forEach((form) => {
                let code = String(form);
                if (code.toUpperCase().startsWith('F_')) {
                    code = code.substring(2);
                }
                code = code.replaceAll('_', '-');

                const disabled = this.isSelected(code)

                result.push({
                    text: code,
                    value: form,
                    disabled: disabled
                });
            });
        }
        return result;
    }

}

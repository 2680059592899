























import {Vue, Component, Prop, Model} from 'vue-property-decorator';
import I18n from "@/modules/budget/staffing-table/I18n";

const modelChangeEvent = 'change';
const modelClose = 'modal-close';
const confirmOk = 'confirm-ok';

@Component
export default class ConfirmModal extends Vue {

    @Model(
        modelChangeEvent,
        {
            type: Boolean,
            required: true,
            default: false,
        },
    )
    public value!: boolean;

    @Prop({
        type: String,
        required: true,
        default: '',
    })
    public message!: string;

    // region Утилиты
    private i18n = new I18n('modules.budget.staffing_table._common');

    private showModal = false;

    resetModal() {
        this.$emit(modelClose, this.showModal);
    }


    private created() {
        this.showModal = this.value

        this.$watch('value', () => {
           this.showModal = this.value
        });
    }

    handleOk(bvModalEvent: any) {
        bvModalEvent.preventDefault()
        this.$nextTick(() => {
            this.$emit(confirmOk);
        })

    }

}






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import type * as Index from '../index';
import * as index from '../index';
import type * as Internal from '../internal';
import * as internal from '../internal';
import { Child, ComboBox, ItemPane } from '../part';


const eventNames = {
    delete: 'delete',
};

const translates = {
    deleteButtonText: internal.localized(
        'Delete this comparison',
        'Удалить это сравнение (каз)', // TODO translate
        'Удалить это сравнение',
    ),
    first: {
        label: internal.localized(
            'First expression',
            'Первое выражение (каз)', // TODO translate
            'Первое выражение',
        ),
        newItemLabel: internal.localized(
            'Click to add first expression here',
            'Кликните для добавления первого выражения (каз)', // TODO translate
            'Кликните для добавления первого выражения',
        ),
    },
    head: internal.localized(
        'Two value comparison',
        'Сравнение двух значений (каз)', // TODO translate
        'Сравнение двух значений',
    ),
    operation: {
        label: internal.localized(
            'Operation',
            'Операция (каз)', // TODO translate
            'Операция',
        ),
        items: {
            equals: internal.localized(
                '[ = ] x1 equals x2',
                '[ = ] x1 равно x2 (каз)', /// TODO translate
                '[ = ] x1 равно x2',
            ),
            notEquals: internal.localized(
                '[ ≠ ] x1 not equals x2',
                '[ ≠ ] x1 не равно x2 (каз)', /// TODO translate
                '[ ≠ ] x1 не равно x2',
            ),
            greaterThan: internal.localized(
                '[ > ] x1 is greater than x2',
                '[ > ] x1 больше x2 (каз)', /// TODO translate
                '[ > ] x1 больше x2',
            ),
            greaterThanOrEquals: internal.localized(
                '[ ≥ ] x1 is greater than or equals x2',
                '[ ≥ ] x1 больше или равно x2 (каз)', /// TODO translate
                '[ ≥ ] x1 больше или равно x2',
            ),
            lesserThan: internal.localized(
                '[ < ] x1 is lesser than x2',
                '[ < ] x1 меньше x2 (каз)', /// TODO translate
                '[ < ] x1 меньше x2',
            ),
            lesserThanOrEquals: internal.localized(
                '[ ≤ ] x1 is lesser than or equals x2',
                '[ ≤ ] x1 меньше или равно x2 (каз)', /// TODO translate
                '[ ≤ ] x1 меньше или равно x2',
            ),
        },
    },
    second: {
        label: internal.localized(
            'Second expression',
            'Второе выражение (каз)', // TODO translate
            'Второе выражение',
        ),
        newItemLabel: internal.localized(
            'Click to add second expression here',
            'Кликните для добавления второго выражения (каз)', // TODO translate
            'Кликните для добавления второго выражения',
        ),
    },
};


@Component({
    components: {
        Child,
        ComboBox,
        ItemPane,
    },
})
export default class TwoValueComparison extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: false,
        default: () => null,
    })
    public config!: Index.Config | null;

    @Prop({
        type: Object,
        required: true,
    })
    public value!: Internal.TwoValueComparison;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly!: boolean;
    // endregion


    // region Разное
    public index = index;
    public translates = translates;

    public getOperationText(operation: Index.TwoValueComparison.Operation): string {
        switch (operation) {
            case "equals":
                return translates.operation.items.equals.toString();
            case "not-equals":
                return translates.operation.items.notEquals.toString();
            case "greater-than":
                return translates.operation.items.greaterThan.toString();
            case "greater-than-or-equals":
                return translates.operation.items.greaterThanOrEquals.toString();
            case "lesser-than":
                return translates.operation.items.lesserThan.toString();
            case "lesser-than-or-equals":
                return translates.operation.items.lesserThanOrEquals.toString();
            default:
                console.error(`Found unknown operation "${operation}"`);
                return `??? [ ${operation} ]`;
        }
    }

    public get dataTypes(): Array<string> {
        const first = this.value.first;
        const second = this.value.second;
        if (first === null) {
            if (second === null) {
                return [];
            } else {
                return [second.dataType];
            }
        } else {
            if (second === null) {
                return [first.dataType];
            } else {
                return [first.dataType, second.dataType];
            }
        }
    }

    public onDelete() {
        this.$emit(eventNames.delete);
    }
    // endregion
}
